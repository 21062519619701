import React from "react";

class SelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items || [],
      showItems: false,
      selectedItem: this.props.items.find(
        (item) => item.value === this.props.value
      ) || { value: this.props.value || "Select your position" },
      otherValue:
        this.props.value &&
        !this.props.items.some((item) => item.value === this.props.value)
          ? this.props.value
          : "",
      inputUsed:
        this.props.value &&
        !this.props.items.some((item) => item.value === this.props.value),
    };
    this.otherInputRef = React.createRef();
    this.selectBoxRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.selectBoxRef && !this.selectBoxRef.current.contains(event.target)) {
      this.setState({ showItems: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const selectedItem = this.props.items.find(
        (item) => item.value === this.props.value
      );
      this.setState({
        selectedItem: selectedItem || { value: this.props.value },
        otherValue: selectedItem ? "" : this.props.value,
        inputUsed: selectedItem ? false : true,
      });
    }
  }

  dropDown = () => {
    this.setState((prevState) => ({
      showItems: !prevState.showItems,
    }), () => {
      if (!this.state.showItems && this.state.inputUsed && this.state.otherValue.trim() === "") {
        this.setState({
          selectedItem: { value: "Select your position" },
          inputUsed: false,
        });
      }
    });
  };

  selectItem = (item) => {
    this.setState(
      {
        selectedItem: item,
        showItems: false,
        otherValue: "",
        inputUsed: false,
      },
      () => {
        if (item.value !== "Other") {
          this.props.onChange(item.value);
        } else {
          this.setState({ showItems: true, inputUsed: true }, () => {
            this.otherInputRef.current.focus();
          });
        }
      }
    );
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.setState({
        showItems: false,
      });
      if (this.state.otherValue.trim() === "") {
        this.setState({
          selectedItem: { value: "Select your position" },
          inputUsed: false,
        });
        this.props.onChange("");
      } else {
        this.props.onChange(this.state.otherValue);
      }
    }
  };

  handleOtherChange = (e) => {
    const { value } = e.target;
    this.setState(
      {
        otherValue: value,
        inputUsed: value.trim() !== "",
        selectedItem: value.trim() === "" ? { value: "Select your position" } : this.state.selectedItem,
      },
      () => {
        if (value.trim() === "") {
          this.props.onChange("");
        }
      }
    );
  };

  handleOtherBlur = () => {
    if (this.state.otherValue.trim() === "") {
      this.setState({
        selectedItem: { value: "Select your position" },
        otherValue: "",
        inputUsed: false,
        showItems: false,
      });
      this.props.onChange("");
    } else {
      this.setState({
        showItems: false,
      });
      this.props.onChange(this.state.otherValue);
    }
  };

  render() {
    return (
      <div className="select-box--box" ref={this.selectBoxRef}>
        <div className="select-box--container">
          <input
            type="hidden"
            name="position"
            value={
              this.state.selectedItem.value !== "Other"
                ? this.state.selectedItem.value
                : this.state.otherValue
            }
          />
          <div className="select-box--selected-item" onClick={this.dropDown}>
          {this.state.selectedItem.value !== "Other"
              ? this.state.selectedItem.value
              : this.state.otherValue || "Select your position"}
          </div>
          <div className="select-box--arrow" onClick={this.dropDown}>
            <span
              className={`${
                this.state.showItems
                  ? "select-box--arrow-up"
                  : "select-box--arrow-down"
              }`}
            />
          </div>

          <div
            style={{ display: this.state.showItems ? "block" : "none" }}
            className={"select-box--items"}
          >
            {this.state.items.map((item) => (
              <div
                key={item.id}
                onClick={() => this.selectItem(item)}
                className={this.state.selectedItem === item ? "selected" : ""}
              >
                {item.value}
              </div>
            ))}
            {this.state.inputUsed && (
              <div>
                <input
                  type="text"
                  className="other-input"
                  placeholder="Specify your position"
                  value={this.state.otherValue}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleOtherChange}
                  onBlur={this.handleOtherBlur}
                  ref={this.otherInputRef}
                  autoFocus
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectBox;
