// import axios from "axios";

// export const BaseURL = "http://192.168.29.183:8001";

// axios.defaults.baseURL = "https://galaxy-decoders.cloudparkinfotech.com";


// import axios from "axios";

// const serverType = "production"; 

// const config = {
//   development: "http://192.168.29.183:8001",
//   production: "https://galaxy-decoders.cloudparkinfotech.com"
// };

// let baseURL;

// switch (serverType) {
//   case "development":
//     baseURL = config.development;
//     break;
//   case "production":
//     baseURL = config.production;
//     break;
//   default:
//     baseURL = config.production;
//     break;
// }

// axios.defaults.baseURL = baseURL;

// export const BaseURL = baseURL;

import axios from "axios";

const Environment = Object.freeze({
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
});

const baseURL = Object.freeze({
  [Environment.DEVELOPMENT]: 'http://192.168.29.64:8080',
  [Environment.PRODUCTION]: 'https://galaxy-decoders.cloudparkinfotech.com',
});

const serverType = Environment.PRODUCTION;

axios.defaults.baseURL = baseURL[serverType];

export const BaseURL = baseURL[Environment.DEVELOPMENT];

