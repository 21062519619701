import G01 from "../component/imgComponent/servicesdetail/game_dev/G01";
import G02 from "../component/imgComponent/servicesdetail/game_dev/G02";
import G03 from "../component/imgComponent/servicesdetail/game_dev/G03";
import G04 from "../component/imgComponent/servicesdetail/game_dev/G04";
import G05 from "../component/imgComponent/servicesdetail/game_dev/G05";
import G06 from "../component/imgComponent/servicesdetail/game_dev/G06";
import G07 from "../component/imgComponent/servicesdetail/game_dev/G07";
const gameSteps = [
    {
        number: '01',
        title: 'Concept & Planning',
        description: "The game development process involves analyze ideas, defining the game's core concept, creating a design, setting a timeline, budget, & resources.",
        image: <G01 /> 
    },
    {
        number: '02',
        title: 'Design Creation',
        description: 'The design phase involves artists & designers creating visual assets, sound effects, & music for the game, using tools like Photoshop, after validating the prototype.',
        image: <G02 /> 
    },
    {
        number: '03',
        title: 'Development',
        description: 'The development phase includes coding game logic, mechanics, and features, along with integrating assets and AI in Unity using C#.',
        image: <G03 /> 
    },
    {
        number: '04',
        title: 'Testing & Iteration',
        description: 'Games are tested to fix bugs and balance gameplay, with QA ensuring standards and developers refining based on feedback.',
        image: <G04 /> 
    },
    {
        number: '05',
        title: 'Optimization',
        description: 'Optimization improves game performance by enhancing frame rates, reducing load times, and refining assets and code using Unity’s profiling tools.',
        image: <G05 /> 
    },
    {
        number: '06',
        title: 'Polishing',
        description: 'In the polishing phase, developers refine visuals, audio, and interactions to create a polished and immersive player experience.',
        image: <G06 /> 
    },
    {
        number: '07',
        title: 'Release & Support',
        description: 'As the game nears launch, developers finalize builds and marketing, then continue support with updates and content based on feedback.',
        image: <G07 /> 
    },
  ];

export default gameSteps;