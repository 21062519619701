import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import NoInternet from "../container/NoInternet";

const Layout = () => {
  const [online, setOnline] = useState(() => {
    // Retrieve the online state from sessionStorage if available, otherwise use navigator.onLine
    const storedOnlineState = sessionStorage.getItem("online");
    return storedOnlineState !== null
      ? JSON.parse(storedOnlineState)
      : navigator.onLine;
  });

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      sessionStorage.setItem("online", true);
    };

    const handleOffline = () => {
      setOnline(false);
      sessionStorage.setItem("online", false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Ensure the online state is set correctly on mount
    setOnline(navigator.onLine);
    sessionStorage.setItem("online", navigator.onLine);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <Header />
      <main>{online ? <Outlet /> : <NoInternet />}</main>
      <Footer />
    </>
  );
};

export default Layout;
