import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from "axios";

gsap.registerPlugin(ScrollTrigger);

const Blog = () => {
  const [blog, setBlog] = useState([]);
  const [blogCategory, setBlogCategory] = useState([]);
  const [recent, setRecent] = useState([]);
  const [archiveOne, setArchiveOne] = useState([]);
  const [archiveTwo, setArchiveTwo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const navigate = useNavigate();

  // Last Month Date Get
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const lastMonthMonthName = monthNames[lastMonth.getMonth()];
  const lastMonthYear = lastMonth.getFullYear();
  const month = lastMonth.getMonth() + 1;
  const lastToLastMonth = new Date(today);

  // Last to Last Month Date Get
  lastToLastMonth.setMonth(lastToLastMonth.getMonth() - 2);
  const lastToLastMonthName = monthNames[lastToLastMonth.getMonth()];
  const lastToLastMonthYear = lastToLastMonth.getFullYear();
  const month2 = lastToLastMonth.getMonth() + 1;

  // Get Blog Data
  useEffect(() => {
    axios
      .get(`/api/blogs`)
      .then((res) => {
        setBlog(res.data.data);
        setRecent(res.data.recent);
      })

      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/api/blog_categories")
      .then((res) => setBlogCategory(res.data.data))
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `/api/blogs?date=${lastMonthYear}-${month}`
      )
      .then((res) => setArchiveOne(res.data.data))
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `/api/blogs?date=${lastToLastMonthYear}-${month2}`
      )
      .then((res) => setArchiveTwo(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Line Animation
  const strokeRef = useRef(null);

  useEffect(() => {
    gsap.from(strokeRef.current, {
      "--dashOffset": -700,
      delay: 1,
      scrollTrigger: {
        trigger: "#blog_animation",
        start: "0% 0%",
        end: "-30% -30%",
        scrub: 2.5,
      },
    });
  }, []);

  const setCategoryWiseData = async (v) => {
    try {
      const response = await axios.get(
        `/api/blogs?cat_id=${v}`
      );
      setBlog(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Filtered blogs based on search query
  useEffect(() => {
    const filtered = blog.filter((v) =>
      v.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBlogs(filtered);

    if (searchPerformed && filtered.length === 0) {
      navigate("/no-resultfound");
    }
  }, [blog, searchQuery, searchPerformed, navigate]);

  const highlightSearchText = (text) => {
    if (searchQuery) {
      const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));
      return (
        <span>
          {parts.map((part, index) => (
            <span
              key={index}
              style={
                part.toLowerCase() === searchQuery.toLowerCase()
                  ? { fontWeight: "bold", backgroundColor: "#ffd93473" }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      );
    }
    return text;
  };

  //Render image or video based on api response
  const renderMedia = (thumbnail, video) => {
    if (video) {
      return (
        <video width="100%" muted autoPlay loop>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (thumbnail) {
      return (
        <img src={thumbnail} alt="post-1" className="w-100" draggable="false" />
      );
    }
    return null;
  };

  //Format blog date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {/* ======= Breadcrumb Hero section ======= */}
      <section className="breadcrumb_hero_section" id="blog_animation">
        <div className="container-fluid">
          <div className="text_area text-center">
            <h1>Blog</h1>
            <div className="breadcumb_area">
              <ul className="d-flex align-items-center justify-content-center">
                <li>
                  <Link to={"/"} className="me-2">
                    Home
                  </Link>
                </li>
                <li>
                  <h5> &gt; Blog</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="line_animation">
          <svg
            width="612"
            height="100"
            viewBox="0 0 612 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke"
              ref={strokeRef}
              d="M612 99L51 99C23.3858 99.0001 0.999987 76.6196 0.999984 49.0054C0.999983 33.8619 0.999981 16.7807 0.99998 -2.99018e-08"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Blog section ======= */}
      <div className="blog_section">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-6 col-md-7">
              <div className="top_search_area">
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setSearchPerformed(true);
                  }}
                />
                <img
                  src="assets/img/icons/search-icon.svg"
                  alt="search-icon"
                  className="img-fluid"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="left_side_area">
                <div className="row">
                  {filteredBlogs.length > 0
                    ? filteredBlogs.map((v, index) => {
                        const isSmallImageCard = index >= 1;
                        const colSize = isSmallImageCard
                          ? "col-xxl-6 col-sm-6"
                          : "col-xxl-12 col-12";

                        return (
                          <div className={colSize} key={index}>
                            <div
                              className={`image_box ${
                                isSmallImageCard ? "small_card" : ""
                              }`}
                            >
                              <Link to={`/blogdetail/${v.slug }`}>
                                {renderMedia(v.thumbnail, v.video)}
                              </Link>
                              <div className="text_container">
                                <div className="top_text">
                                  <h3>{highlightSearchText(v.title)}</h3>
                                  <p>{v.categories.join(", ")}</p>
                                  {/*<p>There are a lot of successful eCommerce websites. More and more internet users are using Google to...</p>*/}
                                </div>
                                <div className="bottom_area">
                                  <div className="flip-box">
                                    <div className="flip-box-inner">
                                      <div className="flip-box-front">
                                        <div className="left_side_text">
                                          <p className="me-3">
                                            {v.read_time} MIN READ{" "}
                                          </p>
                                          <p>{v.views} VIEWS</p>
                                        </div>
                                        <div className="right_side_text">
                                          <p>{formatDate(v.created_at)}</p>
                                        </div>
                                      </div>
                                      <div className="flip-box-back">
                                        <Link to={`/blogdetail/${v.slug}`}>
                                          Read More
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : searchPerformed && <p>No results found</p>}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="right_side_area">
                {recent.length > 0 && (
                  <div className="recent_post_area">
                    <h4>Recent Post</h4>
                    {recent.map((v, i) => {
                      return (
                        <Link
                          className="first"
                          key={i}
                          to={`/blogdetail/${v.slug}`}
                        >
                          {v.title}
                        </Link>
                      );
                    })}
                  </div>
                )}
                {(archiveOne.length > 0 || archiveTwo.length > 0) && (
                  <div className="archive_area">
                    <h4>Archive</h4>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      {archiveOne.length === 0 ? (
                        ""
                      ) : (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              {lastToLastMonthName} {lastToLastMonthYear}
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {archiveOne.map((item) => (
                                <Link key={item} to={`/blogdetail/${item.slug}`} className="first">
                                  {item.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {archiveTwo.length === 0 ? (
                        ""
                      ) : (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              {lastMonthMonthName} {lastMonthYear}
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {archiveTwo.map((item) => (
                                <Link key={item} to={`/blogdetail/${item.slug}`} className="second">
                                  {item.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {blogCategory.length > 0 && (
                  <div className="category_area">
                    <h4>Category</h4>
                    <ul>
                      {blogCategory.map((v, i) => {
                        return (
                          <li key={i} onClick={() => setCategoryWiseData(v.id)}>
                            <div className="d-flex align-items-center">
                              <div className="icon">
                                <img
                                  src={v.logo}
                                  alt={v.name}
                                  className="img-fluid"
                                  draggable="false"
                                />
                              </div>
                              <div className="text">
                                <p>{v.name}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
