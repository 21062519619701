import React from 'react'

const Gl02 = () => {
    return (
        <svg 
            width="50" 
            height="56" 
            viewBox="0 0 50 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <g clipPath="url(#clip0_2872_5218)">
                <path 
                    d="M47.6087 12.3904L27.0364 0.513187C25.8513 -0.171063 23.9119 -0.171063 22.7268 0.513187L2.1548 12.3904C0.969609 13.0747 0 14.7543 0 16.1228V39.8773C0.0922031 41.4711 0.906281 42.791 2.15469 43.6096L22.727 55.4867C23.912 56.1711 25.8514 56.1711 27.0365 55.4867L47.6087 43.6096C48.9484 42.7186 49.7109 41.3774 49.7635 39.8773V16.1228C49.7154 14.6828 48.8532 13.2305 47.6089 12.3904M8.29391 28.0001C8.19645 11.8028 29.8911 4.65073 39.2466 19.701L32.0679 23.855C27.7342 16.6873 16.974 19.2503 16.5878 28.0001C16.7038 36.0642 27.2797 39.8102 32.0683 32.1445L39.2471 36.2986C31.0021 50.6116 8.70953 45.462 8.29391 28.0001ZM41.4696 28.9215H39.6265V30.7647H37.7836V28.9215H35.9404V27.0785H37.7836V25.2355H39.6266V27.0785H41.4696V28.9215ZM48.3813 28.9215H46.538V30.7647H44.695V28.9215H42.852V27.0785H44.6952V25.2355H46.5381V27.0785H48.3813V28.9215Z" 
                    fill="#333333"
                />
            </g>
            <defs>
                <clipPath id="clip0_2872_5218">
                    <rect width="49.7656" height="56" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Gl02;