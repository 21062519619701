import React, { useState, useEffect, useRef } from "react";

const SelectBox = ({ items, name, isOpen, onDropdownClick, onChange }) => {
  const [showItems, setShowItems] = useState(isOpen);
  const [selectedItem, setSelectedItem] = useState(items && items[0]);
  const [inputValue, setInputValue] = useState(items && items[0].value);
  const dropdownRef = useRef(null);

  const dropDown = () => {
    const newShowItems = !showItems;
    setShowItems(newShowItems);
    onDropdownClick(name, newShowItems);
  };

  const selectItem = (item) => {
    if (!item.disabled) {
      setSelectedItem(item);
      setShowItems(false);
      setInputValue(item.value);
      onChange({ target: { name, value: item.value } });
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowItems(false);
      onDropdownClick(name, false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setShowItems(false);
    }
  }, [isOpen]);

  return (
    <div className="select-box--box" ref={dropdownRef}>
      <div className="select-box--container">
        <input type="hidden" name={name} value={inputValue} />
        <div className="select-box--selected-item" onClick={dropDown}>
          {inputValue}
        </div>
        <div className="select-box--arrow" onClick={dropDown}>
          <span
            className={`${showItems ? "select-box--arrow-up" : "select-box--arrow-down"}`}
          />
        </div>

        {showItems && (
          <div className="select-box--items">
            {items.map((item) => (
              <div
                key={item.id}
                onClick={() => selectItem(item)}
                className={selectedItem === item ? "selected" : ""}
                style={
                  item.disabled
                    ? { color: "#ccc", cursor: "not-allowed" }
                    : {}
                }
              >
                {item.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectBox;