import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import axios from "axios";
import "swiper/css";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BaseURL } from "../Helper/Axios";

gsap.registerPlugin(ScrollTrigger);

const Portfolio = () => {
  const [portfolioData, setportfolioData] = useState([]);
  const [clientImg, setClientImg] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  // Clients Images Get
  useEffect(() => {
    axios
      .get(`/api/client_logos`)
      .then((res) => setClientImg(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // All Projects Get
  useEffect(() => {
    axios
      .get(`/api/projects`)
      .then((res) => setportfolioData(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Line Animation
  const strokeRef = useRef(null);
  useEffect(() => {
    gsap.from(strokeRef.current, {
      "--dashOffset": -700,
      delay: 1,
      scrollTrigger: {
        trigger: "#portfolio_animation",
        start: "0% 0%",
        end: "40% 40%",
        scrub: 2.5,
      },
    });
  }, []);

  SwiperCore.use([Autoplay]);

  // Client Carousel
  const portfolioCarousel = {
    loop: true,
    slidesPerView: 4,
    centeredSlides: true,
    spaceBetween: 30,
    onSlideChange: (swiper) => handleSlideChange(swiper),
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      767: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
      1400: {
        slidesPerView: 6,
      },
    },
  };

  portfolioCarousel.allowTouchMove = false;

  return (
    <>
      {/* ======= Breadcrumb Hero section ======= */}
      <section className="breadcrumb_hero_section" id="portfolio_animation">
        <div className="container">
          <div className="text_area text-center">
            <h1>Portfolio</h1>
            <div className="breadcumb_area">
              <ul className="d-flex align-items-center justify-content-center">
                <li>
                  <Link to={"/"} className="me-2">
                    Home
                  </Link>
                </li>
                <li>
                  <h5> &gt; Portfolio</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="line_animation">
          <svg
            width="612"
            height="100"
            viewBox="0 0 612 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke"
              ref={strokeRef}
              d="M612 99L51 99C23.3858 99.0001 0.999987 76.6196 0.999984 49.0054C0.999983 33.8619 0.999981 16.7807 0.99998 -2.99018e-08"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Portfolio section ======= */}
      <section className="portfolio_section">
        <div className="container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="all-tab"
                data-bs-toggle="tab"
                data-bs-target="#all"
                type="button"
                role="tab"
                aria-controls="all"
                aria-selected="true"
              >
                All
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="mobile-tab"
                data-bs-toggle="tab"
                data-bs-target="#mobile"
                type="button"
                role="tab"
                aria-controls="mobile"
                aria-selected="false"
              >
                Mobile Apps
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="ios-tab"
                data-bs-toggle="tab"
                data-bs-target="#ios"
                type="button"
                role="tab"
                aria-controls="ios"
                aria-selected="false"
              >
                IOS Apps
              </button>
            </li> */}
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="web-tab"
                data-bs-toggle="tab"
                data-bs-target="#web"
                type="button"
                role="tab"
                aria-controls="web"
                aria-selected="false"
              >
                Web Development
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="game-tab"
                data-bs-toggle="tab"
                data-bs-target="#game"
                type="button"
                role="tab"
                aria-controls="game"
                aria-selected="false"
              >
                Game Apps
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="all"
              role="tabpanel"
              aria-labelledby="all-tab"
            >
              <div className="content">
                <div className="row">
                  {portfolioData.map((v, i) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={i}>
                        <div className="card_box">
                          <div className="image">
                            <Link to={`/portfolio-detail/${v.slug}`}>
                              <img
                                src={v.thumbnail}
                                alt="project"
                                className="img-fluid"
                                draggable="false"
                              />
                            </Link>
                          </div>
                          <div className="card_content">
                            <h5>{v.title}</h5>
                            <Link to={`/portfolio-detail/${v.slug}`}>
                              <span className="me-2"> Read more </span>
                              <svg
                                width={15}
                                height={10}
                                viewBox="0 0 15 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.1426 0.806641L14.332 4.99921L10.1416 9.19074"
                                  stroke="#0A1828"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M0.712983 5H14.332"
                                  stroke="#0A1828"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="mobile"
              role="tabpanel"
              aria-labelledby="mobile-tab"
            >
              <div className="content">
                <div className="row">
                  {portfolioData.map((v, i) => {
                    const mobileApps =
                      v.categories.includes("Mobile Apps") 

                    if (mobileApps) {
                      return (
                        <div className="col-lg-4 col-sm-6" key={i}>
                          <div className="card_box">
                            <div className="image">
                              <Link to={`/portfolio-detail/${v.slug}`}>
                                <img
                                  src={v.thumbnail}
                                  alt="project"
                                  className="img-fluid"
                                  draggable="false"
                                />
                              </Link>
                            </div>
                            <div className="card_content">
                              <h5>{v.title}</h5>
                              <Link to={`/portfolio-detail/${v.slug}`}>
                                <span className="me-2"> Read more </span>
                                <svg
                                  width={15}
                                  height={10}
                                  viewBox="0 0 15 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.1426 0.806641L14.332 4.99921L10.1416 9.19074"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M0.712983 5H14.332"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            {/* <div
              className="tab-pane fade"
              id="ios"
              role="tabpanel"
              aria-labelledby="ios-tab"
            >
              <div className="content">
                <div className="row">
                  {portfolioData.map((v, i) => {
                    const iosApps = v.categories.includes("IOS Apps");

                    if (iosApps) {
                      return (
                        <div className="col-lg-4 col-sm-6" key={i}>
                          <div className="card_box">
                            <div className="image">
                              <Link to={`/portfolio-detail/${v.id}`}>
                                <img
                                  src={v.thumbnail}
                                  alt="project"
                                  className="img-fluid"
                                  draggable="false"
                                />
                              </Link>
                            </div>
                            <div className="card_content">
                              <h5>{v.title}</h5>
                              <Link to={`/portfolio-detail/${v.id}`}>
                                <span className="me-2"> Read more </span>
                                <svg
                                  width={15}
                                  height={10}
                                  viewBox="0 0 15 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.1426 0.806641L14.332 4.99921L10.1416 9.19074"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M0.712983 5H14.332"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div> */}
            <div
              className="tab-pane fade"
              id="web"
              role="tabpanel"
              aria-labelledby="web-tab"
            >
              <div className="content">
                <div className="row">
                  {portfolioData.map((v, i) => {
                    const webApps = v.categories.includes("Web Development");

                    if (webApps) {
                      return (
                        <div className="col-lg-4 col-sm-6" key={i}>
                          <div className="card_box">
                            <div className="image">
                              <Link to={`/portfolio-detail/${v.slug}`}>
                                <img
                                  src={v.thumbnail}
                                  alt="project"
                                  className="img-fluid"
                                  draggable="false"
                                />
                              </Link>
                            </div>
                            <div className="card_content">
                              <h5>{v.title}</h5>
                              <Link to={`/portfolio-detail/${v.slug}`}>
                                <span className="me-2"> Read more </span>
                                <svg
                                  width={15}
                                  height={10}
                                  viewBox="0 0 15 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.1426 0.806641L14.332 4.99921L10.1416 9.19074"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M0.712983 5H14.332"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="game"
              role="tabpanel"
              aria-labelledby="game-tab"
            >
              <div className="content">
                <div className="row">
                  {portfolioData.map((v, i) => {
                    const gameApps = v.categories.includes("Game Apps");

                    if (gameApps) {
                      return (
                        <div className="col-lg-4 col-sm-6" key={i}>
                          <div className="card_box">
                            <div className="image">
                              <Link to={`/portfolio-detail/${v.slug}`}>
                                <img
                                  src={v.thumbnail}
                                  alt="project"
                                  className="img-fluid"
                                  draggable="false"
                                />
                              </Link>
                            </div>
                            <div className="card_content">
                              <h5>{v.title}</h5>
                              <Link to={`/portfolio-detail/${v.slug}`}>
                                <span className="me-2"> Read more </span>
                                <svg
                                  width={15}
                                  height={10}
                                  viewBox="0 0 15 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.1426 0.806641L14.332 4.99921L10.1416 9.19074"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M0.712983 5H14.332"
                                    stroke="#0A1828"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= Our Clients section ======= */}
      <section className="our_clients_section">
        <div className="container-fluid">
          <div className="top_text">
            <h2>Our Clients</h2>
            <p>
              We are proud to announce that CloudPark Infotech is starting out
              strong with the best clients and partners
            </p>
          </div>
          <Swiper className="swiper portfolio_carousel" {...portfolioCarousel}>
            <div className="swiper-wrapper">
              {clientImg.map((v, i) => {
                return (
                  <SwiperSlide className="swiper-slide" key={i}>
                    <div
                      className="image_area"
                      style={{ background: `${v.color_code}` }}
                    >
                      <img
                        src={i === activeIndex ? (v.active_image || v.non_active_image) : v.non_active_image}
                        alt="client-img"
                        className="img-fluid"
                        draggable="false"
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
