import U01 from "../component/imgComponent/servicesdetail/ui_dev/U01";
import U02 from "../component/imgComponent/servicesdetail/ui_dev/U02";
import U03 from "../component/imgComponent/servicesdetail/ui_dev/U03";
import U04 from "../component/imgComponent/servicesdetail/ui_dev/U04";
import U05 from "../component/imgComponent/servicesdetail/ui_dev/U05";
import U06 from "../component/imgComponent/servicesdetail/ui_dev/U06";
import U07 from "../component/imgComponent/servicesdetail/ui_dev/U07";

const uiSteps = [
    {
        number: '01',
        title: 'Reserch',
        description: 'The process begins by researching the target audience and market trends to identify user needs and pain points.',
        image: <U01 />
    },
    {
        number: '02',
        title: 'Wire-framing',
        description: 'Wireframes outline the basic layout. while prototypes offer interactive versions for testing, using tools like Figma, Sketch, or Adobe XD.',
        image: <U02 /> 
    },
    {
        number: '03',
        title: 'Prototyping ',
        description: 'Create interactive mockups to evaluate functionality, test design concepts, and refine user interactions.',
        image: <U03 /> 
    },
    {
        number: '04',
        title: 'UI/UX Design',
        description: 'Develop high-fidelity visuals, concentrating on aesthetics, branding elements, and creating a seamless user experience.',
        image: <U04 /> 
    },
    {
        number: '05',
        title: 'Handoff',
        description: 'Provide design assets, detailed specifications, and guidelines to developers for accurate implementation in the final product.',
        image: <U05 /> 
    },
    {
        number: '06',
        title: 'Testing',
        description: 'The application undergoes usability testing to identify issues, refine it based on feedback, and meet user experience standards.',
        image: <U06 /> 
    },
    {
        number: '07',
        title: 'Launch',
        description: 'After thorough testing, the application is finalized, deployed to production, and promoted to the target audience.',
        image: <U07 /> 
    },
  ];

export default uiSteps;