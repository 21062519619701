import React from 'react'
import benefitsContent from "../content/WhyCloudparkData";

const Benefits = () => {
    return (
        <section className="why_choose_cloudpark_section">
            <div className="container">
                <div className="benefits_to_choose_us">
                    <div className="top_text_area">
                        <h1>Why Choose <span style={{color:"#178582"}}>Cloudpark Infotech?</span></h1>
                    </div>
                    <div className="benefits_grid">
                        {benefitsContent.map((benefit, index) => (
                            <div className="benefit_item" key={index}>
                                <div className="icon">
                                    <img src={benefit.icon} alt={benefit.title} draggable="false" />
                                </div>
                                <h3>{benefit.title}</h3>
                                <p>{benefit.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section> 
    );
}

export default Benefits;