import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Texts from "../content/Text.json";
import AboutImg2 from '../component/imgComponent/about/AboutImg2';
import Website from "../component/imgComponent/icons/Website";
import Android from "../component/imgComponent/icons/Android";
import Ios from "../component/imgComponent/icons/Ios";
import Designing from "../component/imgComponent/icons/Designing";
import Provide1 from '../component/imgComponent/about/Provide1';
import Provide2 from '../component/imgComponent/about/Provide2';

gsap.registerPlugin(ScrollTrigger);

const Company = () => {

    // Line Animation
    const strokeRef = useRef(null);

    useEffect(() => {

        gsap.from(strokeRef.current, {
            '--dashOffset': -700,
            delay: 1,
            scrollTrigger: {
                trigger: '#company_animation',
                start: '0% 0%',
                end: '-30% -30%',
                scrub: 2.5,
            },
        });

    }, []);



    return (
        <>
            {/* ======= Breadcrumb Hero section ======= */}
            <section className="breadcrumb_hero_section" id='company_animation'>
                <div className="container">
                    <div className="text_area text-center">
                        <h1>About</h1>
                        <div className="breadcumb_area">
                            <ul className="d-flex align-items-center justify-content-center">
                                <li>
                                    <Link to={"/"} className="me-2">Home</Link>
                                </li>
                                <li>
                                    <h5> &gt; About</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="line_animation">
                    <svg width="612" height="100" viewBox="0 0 612 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="stroke" ref={strokeRef} d="M612 99L51 99C23.3858 99.0001 0.999987 76.6196 0.999984 49.0054C0.999983 33.8619 0.999981 16.7807 0.99998 -2.99018e-08" stroke="#178582" />
                    </svg>
                </div>
            </section>

            {/* ======= About CloudPark Infotech section ======= */}
            <section className="about_galaxy_decoders_section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1 order-2">
                            <div className="left_side_area">
                                <div className="top_text">
                                    <h3>About CloudPark Infotech</h3>
                                    <p>{Texts.company1}</p>
                                    <p>{Texts.company2}</p>
                                </div>
                                <ul>
                                    <li>
                                        <p>Best quality support</p>
                                    </li>
                                    <li>
                                        <p>Money back guarantee</p>
                                    </li>
                                    <li>
                                        <p>Serve the best</p>
                                    </li>
                                    <li>
                                        <p>Trusted Professionals</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-1">
                            <div className="right_side">
                                <div className="effect">
                                    <AboutImg2 />     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= What do we provide section ======= */}
            <section className="what_do_we_provide_section">
                <div className="container">
                    <div className="text_area">
                        <h2>What do we provide?</h2>
                        <p>{Texts.company3}</p>
                        <p>{Texts.company4}</p>
                    </div>
                    <div className="fields_area">
                        <div className="row justify-content-center">
                            <div className="col-xl-2 col-md-3 col-6">
                                <div className="field_box">
                                    <div className="image">
                                        <Website />
                                    </div>
                                    <div className="text">
                                        <p>Website</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-6">
                                <div className="field_box">
                                    <div className="image">
                                        <Android />
                                    </div>
                                    <div className="text">
                                        <p>Android</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-6">
                                <div className="field_box">
                                    <div className="image">
                                        <Ios />
                                    </div>
                                    <div className="text">
                                        <p>IOS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-6">
                                <div className="field_box">
                                    <div className="image">
                                        <Designing />
                                    </div>
                                    <div className="text">
                                        <p>Designing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom_area text-center">
                        <h2>Better IT Solutions &amp; Services at your Fingertips</h2>
                        <div className="dot_btn_area">
                            <div className="btn mx-auto" style={{ backgroundColor: "#DADADA" }}>
                                <span>Learn More</span>
                                <div className="dot"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= What do we offer section ======= */}
            <section className="what_do_we_offer">
                <div className="container">
                    <div className="first_area">
                        <div className="row align-items-center">
                            <div className="col-lg-7 order-lg-1 order-2">
                                <div className="left_side">
                                    <div className="text">
                                        <h3>What do we offer?</h3>
                                        <p>{Texts.company5}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 order-lg-2 order-1">
                                <div className="right_side">
                                    <div className="image">
                                        <div className="effect">
                                            <Provide1 />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="second_area">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="left_side">
                                    <div className="image">
                                        <div className="effect">
                                            <Provide2 />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="right_side">
                                    <div className="text">
                                        <h3>What makes us unique
                                            from others?</h3>
                                        <p>{Texts.company6}</p>
                                        <p>{Texts.company7}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= Why should hire us section ======= */}
            <section className="why_should_hire_us_section">
                <div className="container">
                    <div className="text">
                        <h5>Why should you hire us?</h5>
                    </div>
                </div>
            </section>

            {/* ======= About info section ======= */}
            <section className="about_info_section">
                <div className="container">
                    <div className="text_area">
                        <p>{Texts.company8}</p>
                        <p>{Texts.company9}</p>
                        <p>{Texts.company10}</p>
                        <p>{Texts.company11}</p>
                        <p>{Texts.company12}</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Company