import React from 'react'

const Wl05 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <g clip-path="url(#clip0_2872_3709)">
                <path 
                    d="M0 0H56V56H0V0Z" 
                    fill="#F7DF1E"
                />
                <path 
                    d="M14.7243 46.7978L19.0097 44.2043C19.8365 45.6702 20.5886 46.9105 22.3926 46.9105C24.1218 46.9105 25.2123 46.2341 25.2123 43.603V25.7097H30.4748V43.6774C30.4748 49.128 27.2797 51.609 22.6182 51.609C18.4083 51.609 15.9647 49.4288 14.7241 46.7974M33.3332 46.2339L37.6181 43.7531C38.7462 45.5952 40.2122 46.9483 42.8057 46.9483C44.9864 46.9483 46.3768 45.8581 46.3768 44.3544C46.3768 42.5502 44.9484 41.911 42.5426 40.8588L41.2272 40.2944C37.4304 38.6785 34.9119 36.6485 34.9119 32.3632C34.9119 28.4161 37.9191 25.4087 42.618 25.4087C45.9636 25.4087 48.3694 26.5742 50.0984 29.619L46.0008 32.2505C45.0984 30.6342 44.1215 29.9952 42.6178 29.9952C41.0765 29.9952 40.0991 30.9726 40.0991 32.2505C40.0991 33.8293 41.0765 34.4687 43.3318 35.446L44.6474 36.0098C49.1208 37.9271 51.6393 39.8816 51.6393 44.2794C51.6393 49.0162 47.9179 51.6097 42.9184 51.6097C38.0317 51.6097 34.8741 49.2791 33.333 46.2341" 
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_2872_3709">
                    <rect width="56" height="56" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Wl05;