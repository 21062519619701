import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Texts from "../content/Text.json";

gsap.registerPlugin(ScrollTrigger);

const Services = () => {

    // Line Animation
    const strokeRef = useRef(null);

    useEffect(() => {

        gsap.from(strokeRef.current, {
            '--dashOffset': -700,
            delay: 1,
            scrollTrigger: {
                trigger: '#service_animation',
                start: '80% 80%',
                end: 'bottom 20%',
                scrub: 1,
            },
        });

    }, []);



    return (
        <>
            {/* ======= Breadcrumb Hero section ======= */}
            <section className="breadcrumb_hero_section">
                <div className="container">
                    <div className="text_area text-center">
                        <h1>SERVICES WE PROVIDE</h1>
                        <div className="breadcumb_area">
                            <ul className="d-flex align-items-center justify-content-center">
                                <li>
                                    <Link to={"/"} className="me-2">Home</Link>
                                </li>
                                <li>
                                    <h5> &gt; Service</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= Services fields section ======= */}
            <section className="services_fiedls_section" id='service_animation'>
                <div className="container">
                    <div className="top_text_area">
                        <p>{Texts.service1}</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="development_area">
                                <div className="top_text">
                                    <h5><span>W</span>EB DESIGN &amp; DEVELOPMENT</h5>
                                </div>
                                <div className="bottom_text">
                                    <p>{Texts.service2}</p>
                                </div>
                                <div className="services_btn">
                                    <div className="dot_btn_area dot_btn_area_2">
                                        <Link to={"/services/web-design-&-development"} style={{ color: "#F3F3F3" }}>
                                            <div className="btn">
                                                <span>
                                                    Learn More     
                                                </span>
                                                <div className="dot"></div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="development_area">
                                <div className="top_text">
                                    <h5><span>U</span>I/UX DESIGN</h5>
                                </div>
                                <div className="bottom_text">
                                    <p>{Texts.service3}</p>
                                </div>
                                <div className="services_btn">
                                    <div className="dot_btn_area dot_btn_area_2">
                                        <Link to={"/services/ui-ux-design"} style={{ color: "#F3F3F3" }}>
                                            <div className="btn">
                                                <span>
                                                    Learn More
                                                </span>
                                                <div className="dot"></div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="development_area">
                                <div className="top_text">
                                    <h5><span>M</span>OBILE DEVELOPMENT</h5>
                                </div>
                                <div className="bottom_text">
                                    <p>{Texts.service4}</p>
                                </div>
                                <div className="services_btn">
                                    <div className="dot_btn_area dot_btn_area_2">
                                        <Link to={"/services/mobile-development"} style={{ color: "#F3F3F3" }}>
                                            <div className="btn">
                                                <span>
                                                        Learn More
                                                </span>
                                                <div className="dot"></div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="development_area">
                                <div className="top_text">
                                    <h5><span>U</span>nity Game Development</h5>
                                </div>
                                <div className="bottom_text">
                                    <p>{Texts.service5}</p>
                                </div>
                                <div className="services_btn">
                                    <div className="dot_btn_area dot_btn_area_2">
                                        <Link to={"/services/game-development"} style={{ color: "#F3F3F3" }}>
                                            <div className="btn">
                                                <span>
                                                        Learn More
                                                </span>
                                                <div className="dot"></div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="development_area">
                                <div className="top_text">
                                    <h5><span>E</span>-COMMERCE PLATFORM</h5>
                                </div>
                                <div className="bottom_text">
                                    <p>{Texts.service6}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="development_area" >
                                <div className="top_text">
                                    <h5><span>D</span>OMAIN &amp; HOSTING</h5>
                                </div>
                                <div className="bottom_text">
                                    <p>{Texts.service7}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="development_area">
                                <div className="top_text">
                                    <h5><span>R</span>EST API INTEGRATION</h5>
                                </div>
                                <div className="bottom_text">
                                    <p>{Texts.service8}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_line_animation">
                    <svg width="612" height="106" viewBox="0 0 612 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="stroke" ref={strokeRef} d="M612 105L51 105C23.3858 105 0.999987 82.6216 0.999984 55.0073C0.999983 38.2144 0.999981 18.927 0.999979 1.75223e-06" stroke="#178582" />
                    </svg>
                </div>
            </section>
        </>
    )
}

export default Services