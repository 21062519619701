import React from 'react'

const NoResultFound = () => {
    return (
        <>
            <section className="no_result_found_section">
                <div className="container">
                    <div className="image text-center">
                        <img src="assets/img/extra/no-result-found.png" alt="no-result-found-img" className="img-fluid" draggable="false" />
                    </div>
                    <div className="text text-center">
                        <h5>No Result Found</h5>
                        <p>Try adjusting your search to find what you are looking for</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NoResultFound