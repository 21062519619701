import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CountUp from "react-countup";
import Trigger from "react-scroll-trigger";
import Typed from "react-typed";
import gsap from "gsap";
import StarRating from "../component/StarRating";
import ScrollTrigger from "gsap/ScrollTrigger";
import DotButton from "../component/DotButton";
import Texts from "../content/Text.json";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  EffectFlip,
  Pagination,
  Navigation,
  Controller,
} from "swiper";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import AboutImg from "../component/imgComponent/about/AboutImg1";
import VisionImg from "../component/imgComponent/vison mission/VisionImg";
import MissionImg from "../component/imgComponent/vison mission/MissionImg";
import Website from "../component/imgComponent/icons/Website";
import Android from "../component/imgComponent/icons/Android";
import Ios from "../component/imgComponent/icons/Ios";
import Designing from "../component/imgComponent/icons/Designing";
import DeviceImg from "../component/imgComponent/DeviceImg";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [testimonial, setTestimonial] = useState([]);
  const [services, setServices] = useState([]);

  SwiperCore.use([
    Autoplay,
    EffectCoverflow,
    EffectFlip,
    Pagination,
    Navigation,
    Controller,
  ]);
  const swiperRef = useRef(null);

  // Hero Section Carousel
  const HeroCarousel = {
    centeredSlides: true,
    loop: true,
    navigation: true,
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };

  HeroCarousel.allowTouchMove = false;

  const [key, setKey] = useState(0);
  const [showBottomText, setShowBottomText] = useState(false);

  const SlideChange = () => {
    setShowBottomText(false);
    setKey((prevKey) => prevKey + 1);
  };

  const handleTypingComplete = () => {
    setShowBottomText(true);
  };

  // Project Section Carousel
  const ProjectCarousel = {
    effect: "coverflow",
    centeredSlides: true,
    loop: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 40,
      modifier: 2,
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1.6,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 2.4,
      },
      1400: {
        slidesPerView: 2.8,
      },
      1450: {
        slidesPerView: 2.8,
      },
      1550: {
        slidesPerView: 3.6,
      },
      2100: {
        slidesPerView: 4,
      },
      2350: {
        slidesPerView: 4.4,
      },
      2826: {
        slidesPerView: 5.3,
      },
      3000: {
        slidesPerView: 5.2,
      },
    },
  };

  ProjectCarousel.allowTouchMove = false;

  const ProjectText = {
    loop: true,
    effect: "flip",
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
    },
  };

  ProjectText.allowTouchMove = false;

  // Testimonial Section Carousel
  const TestimonialCarousel = {
    loop: true,
    slidesPerView: 1,
    speed: 800,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        let displayIndex = index < 9 ? "0" + (index + 1) : index + 1;
        return '<span class="' + className + '">' + displayIndex + "</span>";
      },
    },
  };

  // Line Animation
  const strokeRef4 = useRef(null);
  const strokeRef5 = useRef(null);
  const strokeRef6 = useRef(null);
  const strokeRef7 = useRef(null);
  const strokeRef8 = useRef(null);
  const strokeRef11 = useRef(null);
  const strokeRef12 = useRef(null);
  const strokeRef13 = useRef(null);
  const strokeRef14 = useRef(null);
  const strokeRef15 = useRef(null);
  const strokeRef16 = useRef(null);
  const strokeRef17 = useRef(null);
  const strokeRef18 = useRef(null);
  const strokeRef19 = useRef(null);
  const strokeRef20 = useRef(null);
  const strokeRef21 = useRef(null);
  const strokeRef22 = useRef(null);
  const strokeRef23 = useRef(null);
  const strokeRef24 = useRef(null);
  const strokeRef25 = useRef(null);
  const strokeRef26 = useRef(null);
  const strokeRef27 = useRef(null);
  const strokeRef28 = useRef(null);
  const strokeRef29 = useRef(null);
  const strokeRef30 = useRef(null);
  const strokeRef31 = useRef(null);
  const strokeRef32 = useRef(null);
  const strokeRef33 = useRef(null);
  const strokeRef34 = useRef(null);
  const strokeRef35 = useRef(null);
  const strokeRef36 = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);

  useEffect(() => {
    // About Us
    gsap.from(strokeRef4.current, {
      "--dashOffset": -1420,
      scrollTrigger: {
        trigger: "#about_us_section",
        start: "-12% 80%",
        end: "bottom 0%",
        duration: 10,
        scrub: 1,
      },
    });

    // New Brand ideas
    gsap.from(strokeRef5.current, {
      "--dashOffset": 1020,
      scrollTrigger: {
        trigger: "#new_brand_section",
        start: "50% 50%",
        end: "bottom 20%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef16.current, {
      "--dashOffset": 1440,
      scrollTrigger: {
        trigger: "#new_brand_section",
        start: "50% 50%",
        end: "bottom 20%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef18.current, {
      "--dashOffset": 1630,
      scrollTrigger: {
        trigger: "#new_brand_section",
        start: "50% 50%",
        end: "bottom 20%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef19.current, {
      "--dashOffset": 2030,
      scrollTrigger: {
        trigger: "#new_brand_section",
        start: "50% 50%",
        end: "bottom 20%",
        duration: 10,
        scrub: 1,
      },
    });

    // Development section
    gsap.from(strokeRef6.current, {
      "--dashOffset": -1050,
      scrollTrigger: {
        trigger: "#development_section",
        start: "60% 80%",
        end: "bottom 20%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef17.current, {
      "--dashOffset": -1370,
      scrollTrigger: {
        trigger: "#development_section",
        start: "60% 80%",
        end: "bottom 20%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef20.current, {
      "--dashOffset": -1530,
      scrollTrigger: {
        trigger: "#development_section",
        start: "100% 60%",
        end: "bottom 30%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef21.current, {
      "--dashOffset": -1970,
      scrollTrigger: {
        trigger: "#development_section",
        start: "60% 60%",
        end: "bottom 30%",
        duration: 10,
        scrub: 1,
      },
    });

    // Solution Provider section
    gsap.from(strokeRef7.current, {
      "--dashOffset": 1540,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "30% 100%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef8.current, {
      "--dashOffset": -610,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "top 10%",
        end: "50% 20%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef14.current, {
      "--dashOffset": 2080,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "30% 100%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef22.current, {
      "--dashOffset": 2080,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "30% 100%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef23.current, {
      "--dashOffset": 2380,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "30% 100%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef15.current, {
      "--dashOffset": -1000,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "top 10%",
        end: "50% 20%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef24.current, {
      "--dashOffset": 1230,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "30% 100%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef25.current, {
      "--dashOffset": 1700,
      scrollTrigger: {
        trigger: "#solution_provider_section",
        start: "30% 100%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    // Hire team section
    gsap.from(strokeRef11.current, {
      "--dashOffset": 720,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "0% 50%",
        end: "40% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef26.current, {
      "--dashOffset": 1080,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "0% 50%",
        end: "40% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef28.current, {
      "--dashOffset": 1630,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "0% 50%",
        end: "40% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef30.current, {
      "--dashOffset": 1630,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "0% 50%",
        end: "40% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef12.current, {
      "--dashOffset": -355,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "60% 50%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef27.current, {
      "--dashOffset": -645,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "60% 50%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef29.current, {
      "--dashOffset": -1630,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "60% 50%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef31.current, {
      "--dashOffset": -1630,
      scrollTrigger: {
        trigger: "#hire_team_section",
        start: "60% 50%",
        end: "50% 50%",
        duration: 10,
        scrub: 1,
      },
    });

    // Our Vision section
    gsap.from(strokeRef13.current, {
      "--dashOffset": -2760,
      scrollTrigger: {
        trigger: "#our_vision_section",
        start: "top 40%",
        end: "bottom 0%",
        duration: 10,
        scrub: 1,
      },
    });

    // Our completed projects

    gsap.from(strokeRef32.current, {
      "--dashOffset": 930,
      scrollTrigger: {
        trigger: "#our_completed_projects_section",
        start: "top 40%",
        end: "bottom 0%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef34.current, {
      "--dashOffset": 1530,
      scrollTrigger: {
        trigger: "#our_completed_projects_section",
        start: "-50% 0%",
        end: "bottom 60%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef35.current, {
      "--dashOffset": 2000,
      scrollTrigger: {
        trigger: "#our_completed_projects_section",
        start: "-50% 0%",
        end: "bottom 60%",
        duration: 10,
        scrub: 1,
      },
    });

    gsap.from(strokeRef36.current, {
      "--dashOffset": 3009,
      scrollTrigger: {
        trigger: "#our_completed_projects_section",
        start: "top 100%",
        end: "50% 0%",
        duration: 10,
        scrub: 1,
      },
    });

    // Testimonial

    gsap.from(strokeRef33.current, {
      "--dashOffset": -680,
      scrollTrigger: {
        trigger: "#testimonial_section",
        start: "70% 100%",
        end: "bottom 0%",
        duration: 10,
        scrub: 1,
      },
    });
  }, []);

  // TestiMonial Data Get
  useEffect(() => {
    axios
      .get("api/testimonials")
      .then((res) => setTestimonial(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Services Data Get
  useEffect(() => {
    axios
      .get("/api/services")
      .then((res) => setServices(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Form Data Post
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name."),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,"Please enter a valid email address.")
      .test(
        'no-test-or-demo',
        "Please enter a valid email address.",
        value => !/test|demo/i.test(value)
      )
      .required("Please enter your email address."),
    contact_number: Yup.string()
      .matches(/^\+?\d+\s\d{1,13}$/, "Please enter a valid contact number with country code & add space after country code.")
      .min(8, "Please enter a valid contact number with country code."),
    project: Yup.string()
      .required("Please tell us about of your query.")
      .min(20, "Please enter minimum 20 character")
  });

  const formik = useFormik({
    initialValues: {
      home_contact: "home",
      name: "",
      email: "",
      contact_number: "",
      project: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      Object.keys(values).forEach(key => formData.append(key, values[key]));
      try {
        const response = await axios.post(`/api/inquiry`, formData);
        if (response.data.success === 1) {
          resetForm();
          toast.success(response.data.msg);
        } else {
            toast.error(response.data.msg);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  });

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
  
    // Regular expression to allow + sign, digits, and one space
    const phoneRegex = /^\+?\d{0,17}(\s?\d{0,13})?$/;
  
    if (phoneRegex.test(value)) {
      // Limit the contact number to 17 characters (including + and space)
      const formattedValue = value.slice(0, 17);
      formik.setFieldValue('contact_number', formattedValue);
    }
  };

  // All Projects Get
  const [projects, setProjects] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const textSwiperRef = useRef(null);
  useEffect(() => {
    axios
      .get("/api/projects")
      .then((response) => {
        if (response.data && response.data.data) {
          const shuffledProjects = response.data.data.sort(
            () => 0.5 - Math.random()
          );
          setProjects(shuffledProjects.slice(0, 5));
          setDataLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching the projects:", error);
      });
  }, []);

  return (
    <>
      {/* ======= Hero Section ======= */}
      <section className="hero_section">
        <Swiper
          className="hero_section_carousel"
          {...HeroCarousel}
          modules={[Navigation]}
          onSlideChange={SlideChange}
        >
          <SwiperSlide>
            <div className="swiper_area">
              <img
                src="assets/img/hero/bg-1.png"
                alt="bg"
                className="w-100"
                draggable="false"
              />
              <div className="swiper_caption">
                <div className="text_area">
                  <div className="top_text">
                    <Typed
                      strings={[
                        "<big>One-Stop</big> <p>Solution <br/> provider</p>",
                      ]}
                      typeSpeed={100}
                      key={key}
                      cursorChar="|"
                      cursorClassName="small-cursor"
                      onComplete={handleTypingComplete}
                    />
                  </div>
                  <div className="bottom_text">
                    {showBottomText && (
                      <p
                        style={{ animationDelay: "0.8s", margin: "-16px" }}
                        className="animate__animated animate__fadeInUp"
                      >
                        grab endless possibilities for your business with
                        CloudPark Infotech
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper_area">
              <img
                src="assets/img/hero/bg-2.png"
                alt="bg"
                className="w-100"
                draggable="false"
              />
              <div className="swiper_caption">
                <div className="text_area">
                  <div className="top_text">
                    <Typed
                      strings={[
                        "<big>Award-Winning</big> <p>Company for <br/> your need</p>",
                      ]}
                      typeSpeed={80}
                      key={key}
                      cursorChar="|"
                      onComplete={handleTypingComplete}
                    />
                  </div>
                  {showBottomText && (
                    <div className="bottom_text">
                      <p
                        style={{ animationDelay: "1s", margin: "-16px" }}
                        className="animate__animated animate__fadeInUp"
                      >
                        Build your dream brand into an authentic brand with US.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper_area">
              <img
                src="assets/img/hero/bg-3.png"
                alt="bg"
                className="w-100"
                draggable="false"
              />
              <div className="swiper_caption">
                <div className="text_area">
                  <div className="top_text">
                    <Typed
                      strings={[
                        "<big>Where</big> <p>Creativity <br/> Lies</p>",
                      ]}
                      typeSpeed={100}
                      key={key}
                      cursorChar="|"
                      onComplete={handleTypingComplete}
                    />
                  </div>
                  {showBottomText && (
                    <div className="bottom_text">
                      <p
                        style={{ animationDelay: "0.5s", margin: "-16px" }}
                        className="animate__animated animate__fadeInUp"
                      >
                        Give a new life to your imagination with our creative
                        idea.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="swiper_button">
          <DotButton name={"Learn More"} />
        </div>
      </section>

      {/* ======= About Us ======= */}
      <section className="about_us_section" id="about_us_section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xxl-6 col-xl-7 col-lg-7 order-lg-1 order-md-2 order-2">
              <div className="about_us_left_side">
                <div className="text">
                  <h5>About Company</h5>
                  <h3>CloudPark Infotech</h3>
                  <p>{Texts.home1}</p>
                  <p> {Texts.home2}</p>
                  <p className="mb-4"> {Texts.home3}</p>
                  <div className="dot_btn_area dot_btn_area_2">
                    <Link to={"/about"} style={{ color: "#F3F3F3" }}>
                      <div className="btn">
                        <span>
                            About Us
                        </span>
                        <div className="dot"></div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="about_us_line_animation">
                  <svg
                    width="1153"
                    height="306"
                    viewBox="0 0 1153 306"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="stroke4"
                      ref={strokeRef4}
                      d="M1153 305L491 305C463.386 305 441 282.614 441 255L441 49C441 22.4903 419.51 0.999998 393 1L0 1.00003"
                      stroke="#178582"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 order-lg-2 order-md-1 order-1">
              <div className="about_us_right_side">
                <div className="image">
                  <div className="d-inline-block effect">
                    <AboutImg/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= We Provide section ======= */}
      <section className="we_provide_section">
        <div className="container">
          <div className="texts">
            <h4>What do we provide?</h4>
            <p>{Texts.whatWeProvide1}</p>
            <p>{Texts.whatWeProvide2}</p>
          </div>
        </div>
      </section>

      {/* ======= Fields section ======= */}
      <section className="fields_section">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="field_box">
                <div className="box">
                  <Website />
                </div>
                <div className="text">
                  <p>Website</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="field_box">
                <div className="box">
                  <Android />
                </div>
                <div className="text">
                  <p>Android</p>
                </div>
              </div>
            </div> 
            <div className="col-md-3 col-6">
              <div className="field_box">
                <div className="box">
                 <Ios />
                </div>
                <div className="text">
                  <p>IOS</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="field_box">
                <div className="box">
                  <Designing />
                </div>
                <div className="text">
                  <p>Designing</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= New Brand ideas ======= */}
      {/* <section className="new_brand_ideas_section" id="new_brand_section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 col-sm-6">
              <div className="box">
                <div className="image">
                  <img
                    src="assets/img/extra/img-1.png"
                    alt="brand-img"
                    className="w-100"
                    draggable="false"
                  />
                  <div className="text">
                    <p>Focusing on quality rather than quantity</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="box">
                <div className="image center_image">
                  <img
                    src="assets/img/extra/img-2.png"
                    alt="brand-img"
                    className="w-100"
                    draggable="false"
                  />
                  <div className="text">
                    <p>Innovative minds with magical hands</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="box">
                <div className="image">
                  <img
                    src="assets/img/extra/img-3.png"
                    alt="brand-img"
                    className="w-100"
                    draggable="false"
                  />
                  <div className="text">
                    <p>Making things easy with technology</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main_text text-center">
            <h3>Open door to your new Brand Ideas</h3>
            <p>{Texts.home4}</p>
          </div>
        </div>
        <div className="new_brand_ideas_line_animation">
          <svg
            width="984"
            height="59"
            viewBox="0 0 984 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke5"
              ref={strokeRef5}
              d="M0.5 1.1718C292.893 1.54643 635.269 1.34155 933.007 1.1958C960.63 1.18228 983 23.5718 983 51.1952L983 58.1719"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="new_brand_ideas_line_animation2">
          <svg
            width="1385"
            height="58"
            viewBox="0 0 1385 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke16"
              ref={strokeRef16}
              d="M0.5 0.999525L1334 0.999396C1361.61 0.999393 1384 23.3851 1384 50.9994L1384 57.9995"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="new_brand_ideas_line_animation3">
          <svg
            width="1584"
            height="58"
            viewBox="0 0 1584 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke18"
              ref={strokeRef18}
              d="M0 1.00006L1533 0.999982C1560.61 0.999981 1583 23.3857 1583 51L1583 58.0001"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="new_brand_ideas_line_animation4">
          <svg
            width="1994"
            height="58"
            viewBox="0 0 1994 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke19"
              ref={strokeRef19}
              d="M0.5 0.999985L1943 1.00021C1970.61 1.00022 1993 23.386 1993 51.0002L1993 58.0003"
              stroke="#178582"
            />
          </svg>
        </div>
      </section> */}

      {/* ======= Solution Provider section ======= */}
      {/* <section
        className="solution_provider_section"
        id="solution_provider_section"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-12">
              <div className="left_side">
                <div className="text">
                  <h3>We are near your doorstep for all your problems</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="center_side">
                <div className="image">
                  <div className="effect">
                    <img
                      src="assets/img/solution-provider/img1.png"
                      alt="img"
                      className="img-fluid"
                      draggable="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="right_side">
                <div className="top_area">
                  <ul>
                    <li>
                      <div className="area">
                        <div className="icon">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.87 4.99C3.09 6.79 2 9.27 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C11.31 2 10.64 2.07 9.98 2.2"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.7402 15.53L14.2602 12L10.7402 8.46997"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="text">
                          <p>Top-notch IT solution provider</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="area">
                        <div className="icon">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.87 4.99C3.09 6.79 2 9.27 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C11.31 2 10.64 2.07 9.98 2.2"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.7402 15.53L14.2602 12L10.7402 8.46997"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="text">
                          <p>Keeping up to date with the latest technologies</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="area">
                        <div className="icon">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.87 4.99C3.09 6.79 2 9.27 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C11.31 2 10.64 2.07 9.98 2.2"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.7402 15.53L14.2602 12L10.7402 8.46997"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="text">
                          <p>Complete customer support for all the queries</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="area">
                        <div className="icon">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.87 4.99C3.09 6.79 2 9.27 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C11.31 2 10.64 2.07 9.98 2.2"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.7402 15.53L14.2602 12L10.7402 8.46997"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="text">
                          <p>Able to handle all complex projects</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="area  mb-4">
                        <div className="icon">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.87 4.99C3.09 6.79 2 9.27 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C11.31 2 10.64 2.07 9.98 2.2"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.7402 15.53L14.2602 12L10.7402 8.46997"
                              stroke="#F3F3F3"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="text">
                          <p>On-time project completion</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="explore_btn">
                  <DotButton name={"Explore Us"} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="solution_provider_animation">
          <svg
            width="1377"
            height="101"
            viewBox="0 0 1377 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke7"
              ref={strokeRef7}
              d="M0 1L1325.4 0.999884C1353.01 0.999882 1375.4 23.3856 1375.4 50.9999L1375.4 101"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="solution_provider_animation3">
          <svg
            width="1656"
            height="102"
            viewBox="0 0 1656 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke14"
              ref={strokeRef14}
              d="M0.5 1.00229L1605 1.00212C1632.61 1.00212 1655 23.3879 1655 51.0021L1655 101.002"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="solution_provider_animation5">
          <svg
            width="1847"
            height="101"
            viewBox="0 0 1847 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke22"
              ref={strokeRef22}
              d="M0 0.999878L1796 1.0003C1823.61 1.00031 1846 23.3861 1846 51.0003L1846 101"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="solution_provider_animation6">
          <svg
            width="2295"
            height="101"
            viewBox="0 0 2295 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke23"
              ref={strokeRef23}
              d="M0.5 0.999878L2244 1.00009C2271.61 1.00009 2294 23.3859 2294 51.0001L2294 101"
              stroke="#178582"
            />
          </svg>
        </div>

        <div className="solution_provider_animation2">
          <svg
            width="547"
            height="83"
            viewBox="0 0 547 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke8"
              ref={strokeRef8}
              d="M547 81.002L51.3959 81.002C23.7816 81.002 1.39581 58.6162 1.39581 31.002L1.3958 0.00193129"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="solution_provider_animation4">
          <svg
            width="908"
            height="82"
            viewBox="0 0 908 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke15"
              ref={strokeRef15}
              d="M907.5 80.9995L51 80.9996C23.3858 80.9996 0.999998 58.6138 0.999996 30.9996L0.999993 -0.000679214"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="solution_provider_animation7">
          <svg
            width="1154"
            height="83"
            viewBox="0 0 1154 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke24"
              ref={strokeRef24}
              d="M1153.5 81.999L51 81.9989C23.3858 81.9989 0.999998 59.6132 0.999996 31.9989L0.999993 0.998802"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="solution_provider_animation8">
          <svg
            width="1556"
            height="83"
            viewBox="0 0 1556 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke25"
              ref={strokeRef25}
              d="M1556 81.9984L51 81.9985C23.3857 81.9985 0.999998 59.6127 0.999996 31.9985L0.999993 0.998328"
              stroke="#178582"
            />
          </svg>
        </div>
      </section> */}

      {/* ======= Sector Service Section ======= */}
      <section className="sector_service_section" id="sector_service_section">
        <div className="container">
          <div className="row">
            <div className="sector_service_area">
              <h4>Our Sectors Of Service</h4>
              <p>
                Here, we develop applications in almost every category. Anything
                you can dream of, we can construct it.
              </p>
              <div className="col-12">
                <ul className="services_listing">
                  {services.map((service, index) => (
                    <li key={index}>
                      <div className="services_badge_view">
                        <div className="services_circle-ico">
                          <img
                            src={service.icon}
                            alt={service.title}
                            style={{ paddingRight: service.point }}
                          />
                        </div>
                        <div className="services_title">{service.title}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= Development section ======= */}
      <section className="development_section" id="development_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="development_area">
                <div className="top_text">
                  <h5>
                    <span>W</span>
                    <text>EB DESIGN &amp; DEVELOPMENT</text>
                  </h5>
                </div>
                <div className="bottom_text">
                  <p>
                    We are a one-stop solution provider for all your website
                    design &amp; development needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="development_area">
                <div className="top_text">
                  <h5>
                    <span>E</span>
                    <text>-COMMERCE PLATFORM</text>
                  </h5>
                </div>
                <div className="bottom_text">
                  <p>
                    We create conversion boosters &amp; attractive eCommerce
                    websites.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="development_area">
                <div className="top_text">
                  <h5>
                    <span>M</span>
                    <text>OBILE DEVELOPMENT</text>
                  </h5>
                </div>
                <div className="bottom_text">
                  <p>
                    We develop both ios and android applications based on your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="development_area">
                <div className="top_text">
                  <h5>
                    <span>U</span>
                    <text>nity Game Development</text>
                  </h5>
                </div>
                <div className="bottom_text">
                  <p>
                    Elevate your game with our expert Unity development
                    solutions
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="development_area">
                <div className="top_text">
                  <h5>
                    <span>R</span>
                    <text>EST API INTEGRATION</text>
                  </h5>
                </div>
                <div className="bottom_text">
                  <p>
                    We create secured APIs that work smoothly with your
                    products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="development_section_line_animation">
          <svg
            width="901"
            height="171"
            viewBox="0 0 901 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke6"
              ref={strokeRef6}
              d="M901 170L51 170C23.3858 170 0.999976 147.614 0.999973 120L0.999963 1.3473e-05"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="development_section_line_animation2">
          <svg
            width="1166"
            height="171"
            viewBox="0 0 1166 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke17"
              ref={strokeRef17}
              d="M1165.5 170L51 170C23.3857 170 0.999998 147.614 0.999996 120L0.999985 4.83981e-05"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="development_section_line_animation3">
          <svg
            width="1387"
            height="171"
            viewBox="0 0 1387 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke20"
              ref={strokeRef20}
              d="M1387 170L51 170C23.3858 170 0.999998 147.615 0.999996 120L0.999985 7.53916e-05"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="development_section_line_animation4">
          <svg
            width="1816"
            height="171"
            viewBox="0 0 1816 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke21"
              ref={strokeRef21}
              d="M1815.5 170L51 170C23.3858 170 0.999998 147.614 0.999996 120L0.999985 -5.49944e-05"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Our Completed Projects section ======= */}
      {dataLoaded && (
        <section
          className="our_completed_projects"
          id="our_completed_projects_section"
        >
          <div className="container-fluid">
            <div className="top_text">
              <h5>Our Completed Projects</h5>
              <h4>Improve &amp; Enhance Your Tech Projects</h4>
            </div>
          </div>

          <Swiper
            className="swiper project-slider"
            ref={swiperRef}
            {...ProjectCarousel}
            controller={{ control: textSwiperRef.current }}
          >
            {projects.map((project, i) => (
              <div className="sticky_device_image">
                <Link key={i} to={`/portfolio-detail/${project.slug}`}>
                  <DeviceImg className="img-fluid"/>
                </Link>
              </div>
            ))}

            <div className="swiper-wrapper">
              {projects.map((project, i) => (
                <SwiperSlide key={i} className="swiper-slide project-slide">
                  <div className="project-slide-img">
                    <Link to={`/portfolio-detail/${project.slug}`}>
                      <img
                        src={project.thumbnail}
                        alt={project.title}
                        draggable="false"
                      />
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>

          <Swiper
            className="bottom_text text-center overflow-x-hidden"
            {...ProjectText}
            controller={{ control: swiperRef.current }}
          >
            <div className="swiper project-slide-text">
              <div className="swiper-wrapper">
                {projects.map((project, i) => (
                  <SwiperSlide key={i} className="swiper-slide">
                    <Link to={`/portfolio-detail/${project.slug}`}>
                      <p>{project.title}</p>
                    </Link>
                  </SwiperSlide>
                ))}
              </div>
            </div>
          </Swiper>

          <div className="our_completed_projects_animation">
            {/* <svg width="189" height="678" viewBox="0 0 189 678" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="stroke32" ref={strokeRef32} d="M0 1.00001L137 1C164.614 0.999998 187 23.3858 187 51V81M187.001 81V678" stroke="#178582" />
                      </svg> */}
            <svg
              width="198"
              height="616"
              viewBox="0 0 198 616"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="stroke32"
                ref={strokeRef32}
                d="M0 1.00005L147 1.00005C164.614 0.999998 197 23.3858 197 51L197 616"
                stroke="#178582"
              />
            </svg>
          </div>
          <div className="our_completed_projects_animation2">
            {/* <svg width="964" height="678" viewBox="0 0 964 678" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="stroke34" ref={strokeRef34} d="M0 1L912 1C939.614 0.999998 962 23.3858 962 51V81M962.001 81V678" stroke="#178582" />
                      </svg> */}
            <svg
              width="942"
              height="616"
              viewBox="0 0 942 616"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="stroke34"
                ref={strokeRef34}
                d="M0.5 1.00006L891 1.0001C918.614 1.0001 941 23.3859 941 51.0001L941 616"
                stroke="#178582"
              />
            </svg>
          </div>
          <div className="our_completed_projects_animation3">
            {/* <svg width="1325" height="678" viewBox="0 0 1325 678" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="stroke35" ref={strokeRef35} d="M0 1L1273 1C1300.61 0.999998 1323 23.3858 1323 51V81M1323 81V678" stroke="#178582" />
                      </svg> */}
            <svg
              width="1414"
              height="616"
              viewBox="0 0 1414 616"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="stroke35"
                ref={strokeRef35}
                d="M0.5 1.00014L1363 1.00007C1390.61 1.00007 1413 23.3858 1413 51.0001L1413 616"
                stroke="#178582"
              />
            </svg>
          </div>
          <div className="our_completed_projects_animation4">
            {/* <svg width="2061" height="678" viewBox="0 0 2061 678" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="stroke36" ref={strokeRef36} d="M0 1L2009 1C2036.61 0.999998 2059 23.3858 2059 51V81M2059 81V678" stroke="#178582" />
                      </svg> */}
            <svg
              width="2125"
              height="616"
              viewBox="0 0 2125 616"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="stroke36"
                ref={strokeRef36}
                d="M0.5 1L2074 1.00007C2101.61 1.00007 2124 23.3858 2124 51.0001L2124 616"
                stroke="#178582"
              />
            </svg>
          </div>
        </section>
      )}

      {/* ======= Testimonial section ======= */}
      <section className="testimonial_section" id="testimonial_section">
        <div className="container">
          <div className="top_text">
            <h5>What They’re Talking?</h5>
          </div>
          <div className="testimonial">
            <Swiper
              className="swiper testimonial-slider"
              {...TestimonialCarousel}
            >
              <div className="swiper-wrapper">
                {testimonial.map((v, i) => {
                  return (
                    <SwiperSlide className="swiper-slide" key={i}>
                      <div className="row align-items-sm-start justify-content-between">
                        <div className="col-xxl-5 col-xl-6 col-lg-6">
                          <div className="left_side">
                            <div className="card_box">
                              <div className="image">
                                <img
                                  src={v.image}
                                  className="img-fluid"
                                  draggable="false"
                                  alt="testimonial-img"
                                />
                              </div>
                              <div className="text">
                                <h5
                                  className={
                                    !v.name ? "testimonial-h5-text" : ""
                                  }
                                >
                                  {v.name}
                                </h5>
                                <p>{v.designation}</p>
                                {v.rating != 0.0 && (
                                  <StarRating rating={v.rating} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                          <div className="right_side">
                            <div className="quate_img text-start">
                              <img
                                src="assets/img/icons/quotes.svg"
                                alt="quate_img"
                                className="img-fluid"
                                draggable="false"
                              />
                            </div>
                            <div className="main_text">
                              <h5
                                className={
                                  !v.title ? "testimonial-h5-text" : ""
                                }
                              >
                                {v.title}
                              </h5>
                              <p>{v.description}</p>
                            </div>
                            <div className="quate_img text-end">
                              <img
                                src="assets/img/icons/quotes.svg"
                                alt="quate_img"
                                className="img-fluid"
                                draggable="false"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
              <div className="swiper-pagination" />
            </Swiper>
          </div>
        </div>
        <div className="testimonial_animation">
          <svg
            width="578"
            height="113"
            viewBox="0 0 578 113"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke33"
              ref={strokeRef33}
              d="M577.5 112L51 112C23.3858 112 0.999998 89.6144 0.999996 62.0002L0.999991 0.000175317"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Hire team section ======= */}
      <section className="hire_team_section" id="hire_team_section">
        <div className="container">
          <div className="main_text">
            <h4>Hire our professional team</h4>
            <p>{Texts.home5}</p>
            <p>{Texts.home6}</p>
          </div>
          <div className="counter_area">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="counter_box counter_box_right">
                  <Trigger onEnter={() => setCounterOn(true)}>
                    <div className="top_text">
                      <h2>
                        {counterOn && (
                          <CountUp start={0} end={15} duration={4} delay={0} />
                        )}
                        +
                      </h2>
                    </div>
                  </Trigger>
                  <div className="bottm_text">
                    <p>Happy Client</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="counter_box counter_box_left">
                  <Trigger onEnter={() => setCounterOn(true)}>
                    <div className="top_text">
                      <h2>
                        {counterOn && (
                          <CountUp start={0} end={27} duration={4} delay={0} />
                        )}
                        +
                      </h2>
                    </div>
                  </Trigger>
                  <div className="bottm_text">
                    <p>Project Completed</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="counter_box counter_box_right">
                  <Trigger onEnter={() => setCounterOn(true)}>
                    <div className="top_text">
                      <h2>
                        {counterOn && (
                          <CountUp start={0} end={10} duration={4} delay={0} />
                        )}
                        +
                      </h2>
                    </div>
                  </Trigger>
                  <div className="bottm_text">
                    <p>Our Team Member</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="counter_box counter_box_left">
                  <Trigger onEnter={() => setCounterOn(true)}>
                    <div className="top_text">
                      <h2>
                        {counterOn && (
                          <CountUp start={0} end={1} duration={4} delay={0} />
                        )}
                        +
                      </h2>
                    </div>
                  </Trigger>
                  <div className="bottm_text">
                    <p>Award</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hire_team_line_animation">
          <svg
            width="422"
            height="388"
            viewBox="0 0 364 404"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke11"
              ref={strokeRef11}
              d="M0 1L87.0003 1.00057C114.614 1.00075 137 23.3865 137 51.0006L137 352.426C137 380.263 159.735 402.74 187.57 402.423H364"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="hire_team_line_animation3">
          <svg
            width="656"
            height="404"
            viewBox="0 0 656 404"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke26"
              ref={strokeRef26}
              d="M0 1L50 1C77.6142 0.999998 100 23.3858 100 51L100 353C100 380.614 122.386 403 150 403L656 403"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="hire_team_line_animation5">
          <svg
            width="1280"
            height="404"
            viewBox="0 0 1280 404"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke28"
              ref={strokeRef28}
              d="M0 1L50 1C77.6142 0.999998 100 23.3858 100 51L100 353C100 380.614 122.386 403 150 403H1279.5"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="hire_team_line_animation7">
          <svg
            width="902"
            height="404"
            viewBox="0 0 902 404"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke30"
              ref={strokeRef30}
              d="M0 1L50 1C77.6142 0.999998 100 23.3858 100 51L100 353C100 380.614 122.386 403 150 403H901.5"
              stroke="#178582"
            />
          </svg>
        </div>

        <div className="hire_team_line_animation2">
          <svg
            width="343"
            height="2"
            viewBox="0 0 354 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke12"
              ref={strokeRef12}
              d="M354 1L-1.19209e-05 0.999969"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="hire_team_line_animation4">
          <svg
            width="637"
            height="2"
            viewBox="0 0 637 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke27"
              ref={strokeRef27}
              d="M636.5 1L0.5 1"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="hire_team_line_animation6">
          <svg
            width="1240"
            height="2"
            viewBox="0 0 1240 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke29"
              ref={strokeRef29}
              d="M1240 1.00006L0.5 1.00006"
              stroke="#178582"
            />
          </svg>
        </div>
        <div className="hire_team_line_animation8">
          <svg
            width="931"
            height="2"
            viewBox="0 0 931 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke31"
              ref={strokeRef31}
              d="M931 1.00006L0 1.00006"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Our Vision section ======= */}
      <section className="our_vision_section" id="our_vision_section">
        <div className="container">
          <div className="our_vision_area">
            <div className="row justify-content-end">
              <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-8 order-md-1 order-2">
                <div className="left_side">
                  <h5>Our Vision</h5>
                  <p>{Texts.ourVision}</p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-3 col-lg-4 col-md-4 order-md-2 order-1">
                <div className="right_side">
                  <div className="image">
                    <div className="effect">
                      <VisionImg />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our_mission_area">
            <div className="row justify-content-sm-start align-items-end">
              <div className="col-xxl-4 col-xl-3 col-lg-4 col-md-4">
                <div className="left_side">
                  <div className="image">
                    <div className="effect">
                      <MissionImg />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl--6 col-xl-7 col-lg-8 col-md-8">
                <div className="right_side">
                  <h5>Our Mission</h5>
                  <p>{Texts.ourMission}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our_vision_line_animation">
          <svg
            width="1830"
            height="730"
            viewBox="0 0 2001 804"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke13"
              ref={strokeRef13}
              d="M2000.5 803L445 803C417.386 803 395 780.615 395 753L395 51.0004C395 23.3861 372.614 1.00038 345 1.00038L0 1"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Contact us section ======= */}
      <section className="contact_section">
        <div className="container">
          <div className="main_text">
            <h4>Contact With Us</h4>
          </div>
          <form className="contact_form" onSubmit={formik.handleSubmit}>
            <div className="row justify-content-between">
              <input type="hidden" name="home_contact" value="home" />
              <div className="col-lg-4 col-md-6">
                <div className="input_box">
                  <label htmlFor="text" className="form-label">
                    What’s your full name?<span> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    ref={nameRef}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && <p className="form_error">{formik.errors.name}</p>}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="input_box">
                  <label htmlFor="number" className="form-label">
                    What’s your Contact Number?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    // pattern="[0-9]{7,15}"
                    placeholder="Enter your contact number"
                    name="contact_number"
                    onChange={handlePhoneNumberChange}
                    onBlur={formik.handleBlur}
                    ref={phoneNumberRef}
                    value={formik.values.contact_number}
                  />
                  {formik.touched.contact_number && formik.errors.contact_number && <p className="form_error">{formik.errors.contact_number}</p>}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="input_box">
                  <label htmlFor="email" className="form-label">
                    What’s your email address?<span> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    placeholder="Enter your email address"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    ref={emailRef}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && <p className="form_error">{formik.errors.email}</p>}
                </div>
              </div>
              <div className="col-lg-12 col-md-6">
                <div className="input_box">
                  <label htmlFor="message" className="form-label">
                    Tell us about your qeury.<span> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="message"
                    placeholder="Describe here"
                    name="project"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.project}
                  />
                  <div id="InputProject" className="form-text">
                          Min. 20 characters
                  </div>
                  {formik.touched.project && formik.errors.project && <p className="form_error">{formik.errors.project}</p>}
                </div>
              </div>
              <div className="col-12">
                <div className="input_button">
                  <div className="dot_btn_area dot_btn_area_2">
                    <div className="btn mx-auto">
                      <button type="submit">Submit</button>
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Home;
