import React from 'react'

const Empty = () => {
    return (
        <>
            <section className="empty_section">
                <div className="container">
                    <div className="image text-center">
                        <img src="assets/img/extra/empty.png" alt="empty-img" className="img-fluid" draggable="false" />
                    </div>
                    <div className="text text-center">
                        <h5>Empty Page</h5>
                        <p>Looks like haven’t added anything to page yet</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Empty