import React, { useState } from "react";
import SelectBox from "./Select3";

const SelectBox3 = ({ onChange }) => {
  const [isOpen, setIsOpen] = useState({});

  const handleDropdownClick = (name, open) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [name]: open,
    }));
  };

  return (
    <div style={{ padding: "0 0 40px 0" }} id="positonfield">
      <div style={{ margin: "16px", position: "relative" }} />
      <SelectBox
        name="service"
        items={[
          { value: "Select Your Desired Service", id: 0, disabled: true },
          { value: "Web development", id: 1 },
          { value: "App development", id: 2 },
          { value: "Web Design", id: 3 },
          { value: "App Design", id: 4 },
          { value: "Game development", id: 5 },
          { value: "API development", id: 6 },
        ]}
        isOpen={isOpen["service"]}
        onDropdownClick={handleDropdownClick}
        onChange={onChange}
      />
    </div>
  );
};

export default SelectBox3;
