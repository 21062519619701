import React from 'react'

const Wl04 = () => {
    return (
        <svg 
            width="56" 
            height="29" 
            viewBox="0 0 56 29" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <g clip-path="url(#clip0_2872_3684)">
                <path 
                    d="M12.7365 5.91894C15.1741 5.93939 16.9404 6.64213 18.0356 8.02616C19.1307 9.41012 19.4922 11.3004 19.1202 13.6971C18.9757 14.7922 18.6554 15.8665 18.1595 16.92C17.6842 17.9736 17.0235 18.9237 16.1762 19.771C15.1432 20.845 14.0379 21.5268 12.8604 21.8163C11.6828 22.1056 10.4639 22.2502 9.20369 22.2502H5.48516L4.30762 28.138H0L4.43144 5.91894H12.7365ZM49.4748 5.91894C51.9124 5.93939 53.6788 6.64213 54.774 8.02616C55.869 9.41012 56.2305 11.3004 55.8586 13.6971C55.7141 14.7922 55.3938 15.8665 54.8979 16.92C54.4225 17.9736 53.7619 18.9237 52.9146 19.771C51.8816 20.845 50.7763 21.5268 49.5988 21.8163C48.4211 22.1056 47.2022 22.2502 45.9421 22.2502H42.2233L41.0458 28.138H36.7383L41.1698 5.91894H49.4748ZM28.3035 0L27.0949 5.91894H30.9376C33.0447 5.9605 34.6148 6.39435 35.6478 7.2205C36.7015 8.04661 37.0114 9.61713 36.5775 11.9307L34.5013 22.2502H30.1628L32.1461 12.3956C32.3524 11.3625 32.2905 10.6291 31.9601 10.1954C31.6298 9.76154 30.9171 9.54461 29.8219 9.54461L26.3821 9.51366L23.841 22.2502H19.5646L24.0271 0H28.3035ZM9.38962 9.45164C9.15259 9.47176 8.91482 9.48209 8.67694 9.48259H8.05087L6.19784 18.7483C6.3218 18.7689 6.44576 18.7793 6.56972 18.7794H7.0035C8.9868 18.7998 10.6398 18.6039 11.9618 18.1906C13.2838 17.7567 14.1725 16.2487 14.6268 13.6662C14.9987 11.4968 14.6268 10.2469 13.5112 9.91648C12.416 9.58617 11.0426 9.43119 9.38962 9.45164ZM46.128 9.45164C45.891 9.47177 45.6532 9.48209 45.4152 9.48259H44.7892L42.9361 18.7483C43.0601 18.7689 43.1841 18.7793 43.308 18.7794H43.7419C45.7252 18.7998 47.3781 18.6039 48.7001 18.1906C50.0221 17.7567 50.9109 16.2487 51.3651 13.6662C51.737 11.4968 51.3651 10.2469 50.2495 9.91648C49.1544 9.58617 47.7809 9.43119 46.128 9.45164Z" 
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_2872_3684">
                    <rect width="56" height="28.2188" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Wl04;