const webSteps = [
    {
        number: '01',
        title: 'Discovery',
        description: 'Understand the client\'s business, goals, and target audience to define project scope, deliverables, and budget.',
        image: "/assets/img/servicesdetail/web_dev/w_01.png" 
    },
    {
        number: '02',
        title: 'Planning',
        description: 'Create a plan with a sitemap, content strategy, and wireframes to ensure smooth website navigation and user flow.',
        image: "/assets/img/servicesdetail/web_dev/w_02.png" 
    },
    {
        number: '03',
        title: 'Design',
        description: 'Design visually appealing layouts aligned with the brand, iterating based on feedback for user-friendly sites.',
        image: "/assets/img/servicesdetail/web_dev/w_03.png" 
    },
    {
        number: '04',
        title: 'Development',
        description: 'Convert designs into a functional website with the latest tech, integrating CMS and e-commerce as needed.',
        image: "/assets/img/servicesdetail/web_dev/w_04.png" 
    },
    {
        number: '05',
        title: 'Testing',
        description: 'Test comprehensively to ensure functionality, performance across devices/browsers, and user acceptance.',
        image: "/assets/img/servicesdetail/web_dev/w_05.png" 
    },
    {
        number: '06',
        title: 'Launch',
        description: 'Deploy the site after final checks, configuring domain and hosting for a smooth transition to the live server.',
        image: "/assets/img/servicesdetail/web_dev/w_06.png" 
    },
    {
        number: '07',
        title: 'Maintenance',
        description: 'Provide ongoing support with updates, security, performance optimization, and client training for the website.',
        image: "/assets/img/servicesdetail/web_dev/w_07.png" 
    },
  ];

export default webSteps;