import gsap from "gsap";
import axios from "axios";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from "react";

gsap.registerPlugin(ScrollTrigger);

const Team = () => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);

  // Team Data Get
  useEffect(() => {
    axios
      .get("/api/teams")
      .then((res) => {
        const fetchedTeam = res.data.data.map((member) => {
          let links = [];
          try {
            links = JSON.parse(member.link);
          } catch (e) {
            console.error("Error parsing links:", e);
          }
          return { ...member, link: links };
        });
        setTeam(fetchedTeam);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  // Line Animation
  const strokeRef = useRef(null);

  useEffect(() => {
    gsap.from(strokeRef.current, {
      "--dashOffset": -700,
      delay: 1,
      scrollTrigger: {
        trigger: "#team_animation",
        start: "0% 0%",
        end: "-30% -30%",
        scrub: 2.5,
      },
    });
  }, []);

  //Get Icon according links
  const getIcon = (link) => {
    if (link.includes("facebook")) {
      return <i className="fa-brands fa-facebook-f" />;
    } else if (link.includes("instagram")) {
      return <i className="fa-brands fa-instagram" />;
    } else if (link.includes("linkedin")) {
      return <i className="fa-brands fa-linkedin" />;
    }
    return null;
  };

  return (
    <>
      {/* ======= Breadcrumb Hero section ======= */}
      <section className="breadcrumb_hero_section" id="team_animation">
        <div className="container">
          <div className="text_area text-center">
            <h1>Team</h1>
            <div className="breadcumb_area">
              <ul className="d-flex align-items-center justify-content-center">
                <li>
                  <Link to={"/"} className="me-2">
                    Home
                  </Link>
                </li>
                <li>
                  <h5> &gt; Team</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="line_animation">
          <svg
            width="612"
            height="100"
            viewBox="0 0 612 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke"
              ref={strokeRef}
              d="M612 99L51 99C23.3858 99.0001 0.999987 76.6196 0.999984 49.0054C0.999983 33.8619 0.999981 16.7807 0.99998 -2.99018e-08"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Team section ======= */}
      <section className="team_section">
        <div className="container">
          {loading ? (
            <div className="loader">Loading...</div>
          ) : (
            <div className="row">
              {team.map((member, index) => {
                const isSmallImageCard = index >= 2;
                const colSize = index < 2 ? "6" : "4";
                {
                  /* const mdcolSize = index < 2 ? "3" : "4"; */
                }

                {
                  /* const colSize = "4";
                const mdcolSize = "4"; */
                }

                return (
                  <div
                    key={index}
                    className={`col-xxl-${colSize} col-md-${colSize} col-sm-6`}
                  >
                    <div
                      className={`image_box ${
                        isSmallImageCard ? "small_images_card" : ""
                      }`}
                    >
                      {/* <div className="image_box"> */}
                      <div className="effect w-100">
                        <img
                          src={member.image}
                          alt="member-1"
                          className="w-100"
                          draggable="false"
                        />
                      </div>
                      <div className="text_container d-flex align-items-center justify-content-between">
                        <div className="left_side">
                          <h4>{member.name}</h4>
                          <p>{member.designation}</p>
                        </div>
                        <div className="right_side d-flex align-i">
                          {Array.isArray(member.link) &&
                            member.link.map((link, i) => (
                              <Link key={i} to={link}>
                                <div className="icon me-2">{getIcon(link)}</div>
                              </Link>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Team;
