import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Texts from "../content/Text.json";
import UiUxTech from "../component/UiUxTech";
import UiUxImg from "../component/imgComponent/servicesdetail/ui_dev/UiUxImg";
import uiSteps from "../content/UiDevelopData";
import SearchIcon from "../component/imgComponent/servicesdetail/SearchIcon";
import Benefits from "../component/Benefits";
import ServicesContact from "../component/ServicesContact";

gsap.registerPlugin(ScrollTrigger);

const UiUxDesign = () =>{

    const stepsContainerRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    const checkScrollPosition = () => {
        if (stepsContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = stepsContainerRef.current;
            setIsAtStart(scrollLeft === 0);
            setIsAtEnd(scrollLeft + clientWidth === scrollWidth);
        }
    }

    const scrollLeft = () => {
        if (stepsContainerRef.current) {
            stepsContainerRef.current.scrollBy({
                left: -stepsContainerRef.current.offsetWidth,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (stepsContainerRef.current) {
            stepsContainerRef.current.scrollBy({
                left: stepsContainerRef.current.offsetWidth,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const container = stepsContainerRef.current;
        checkScrollPosition();
        if (container) {
            container.addEventListener('scroll', checkScrollPosition);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    // Line Animation
    const strokeRef = useRef(null);

    useEffect(() => {
        gsap.from(strokeRef.current, {
        "--dashOffset": -700,
        delay: 1,
        scrollTrigger: {
            trigger: "#services_detail_breadcrumb",
            start: "0% 0%",
            end: "-30% -30%",
            scrub: 2.5,
        },
        });
    }, []);

    return (
        <>
            {/* ======= Breadcrumb Hero section ======= */}
            <section className="breadcrumb_hero_section" id="services_detail_breadcrumb">
                <div className="container">
                    <div className="text_area text-center">
                        <h1>UI/UX DESIGN</h1>
                        <div className="breadcumb_area">
                            <ul className="d-flex align-items-center justify-content-center">
                                <li>
                                    <Link to={"/"} className="me-2">Home</Link>
                                </li>
                                <li>
                                    <Link to={"/services"} className="me-2"> &gt; Service</Link>
                                </li>
                                <li>
                                    <h5> &gt; UI/UX Design</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="line_animation">
                    <svg
                        width="612"
                        height="100"
                        viewBox="0 0 612 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            id="stroke"
                            ref={strokeRef}
                            d="M612 99L51 99C23.3858 99.0001 0.999987 76.6196 0.999984 49.0054C0.999983 33.8619 0.999981 16.7807 0.99998 -2.99018e-08"
                            stroke="#178582"
                        />
                    </svg>
                </div>
            </section>

            {/* ======= Service Details section ======= */}
            <section className="services_details_section" id="service_animation">
                <div className="container">
                    <div className="top_text_area">
                        <p>{Texts.servicedetail10}</p>
                    </div>
                </div>
            </section>

            {/* ======= Service What_Do_We_Offer section ======= */}
            <section className="services_what_do_we_offer">
                <div className="container">     
                    <div className="detail_area">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="left_side">
                                    <div className="image">
                                        <div className="effect">
                                            <UiUxImg />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="right_side">
                                    <div className="text">
                                        <p>{Texts.servicedetail11}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= Development Process section ======= */}
            <section className="services_development_process">
                <div className="container">
                    <div className="development_process">
                        <div className="top_text_area">
                            <h1>Development <span style={{color:"#178582"}}>Process</span></h1>
                        </div>
                        <div className="process_steps">
                            <div className="steps ui_ux" ref={stepsContainerRef}>
                                {uiSteps.map((step, index) => (
                                <div className="step_card ui_ux" key={index}>
                                    <div className="step_number ui_ux">
                                        {step.number}
                                    </div>
                                    <div className="step_content_wrapper ui_ux">
                                        <div className="step_icon ui_ux">
                                            <SearchIcon />
                                        </div>
                                        <div className="step_content ui_ux">
                                            <h2>{step.title}</h2>
                                            <p>{step.description}</p>
                                            {step.image}
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                            {!isAtStart && 
                                <div className="scroll_arrow_prev" onClick={scrollLeft}></div>
                            }

                            {!isAtEnd && 
                                <div className="scroll_arrow_next" onClick={scrollRight}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= Technology Expertise section ======= */}
            <UiUxTech />

            {/* ======= Why choose cloudpark section ======= */}
            <Benefits />

            {/* ======= Contact us section ======= */}
            <ServicesContact />                      
        </>
    )
}

export default UiUxDesign;