import M01 from "../component/imgComponent/servicesdetail/mobile_dev/M01";
import M02 from "../component/imgComponent/servicesdetail/mobile_dev/M02";
import M03 from "../component/imgComponent/servicesdetail/mobile_dev/M03";
import M04 from "../component/imgComponent/servicesdetail/mobile_dev/M04";
import M05 from "../component/imgComponent/servicesdetail/mobile_dev/M05";
import M06 from "../component/imgComponent/servicesdetail/mobile_dev/M06";
import M07 from "../component/imgComponent/servicesdetail/mobile_dev/M07";

const mobileSteps = [
    {
        number: '01',
        title: 'Conceptualization',
        description: "Define the app's purpose and target audience, conduct market research.",
        image: <M01 />
    },
    {
        number: '02',
        title: 'Planning',
        description: 'Outline features and create a development roadmap.',
        image: <M02 /> 
    },
    {
        number: '03',
        title: 'Design',
        description: 'Develop wireframes and design the user interface (UI) and user experience (UX).',
        image: <M03 />
    },
    {
        number: '04',
        title: 'Development',
        description: 'Choose between native, hybrid, or cross-platform approaches. Code the app using selected languages and frameworks.',
        image: <M04 />
    },
    {
        number: '05',
        title: 'Testing',
        description: 'Perform various tests to ensure functionality and performance.',
        image: <M05 /> 
    },
    {
        number: '06',
        title: 'Launch',
        description: 'Submit the app to app stores (Google Play, Apple App Store). Promote the app to reach the target audience.',
        image: <M06 /> 
    },
    {
        number: '07',
        title: 'Maintenance',
        description: 'Fix bugs and release updates based on user feedback.Use analytics to gather insights and improve the app.',
        image: <M07 /> 
    },
  ];

export default mobileSteps;