import React from 'react'

const Ios = () => {
    return (
        <svg 
            width="65" 
            height="65" 
            viewBox="0 0 65 65" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid"
        >
            <path 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                d="M41.7084 5.41669C42.2501 8.39585 40.8959 11.375 39.2709 13.5417C37.6459 15.7084 34.6667 17.6042 31.6876 17.3334C31.1459 14.3542 32.5001 11.6459 34.1251 9.47919C36.0209 7.31252 39.0001 5.68752 41.7084 5.41669ZM50.3751 52.5417C51.7292 50.375 52.5417 49.0209 53.6251 46.5834C45.2292 43.3334 43.8751 31.4167 52.2709 27.0834C49.8334 23.8334 46.3126 22.2084 42.7917 22.2084C40.3542 22.2084 38.7292 22.75 37.1042 23.2917C35.7501 23.8334 34.6667 24.1042 33.3126 24.1042C31.6876 24.1042 30.6042 23.5625 28.9792 23.0209C27.3542 22.4792 25.7292 21.9375 23.8334 21.9375C20.0417 21.9375 15.9792 24.1042 13.5417 28.1667C10.0209 33.5834 10.5626 44.1459 16.2501 52.8125C18.6876 56.0625 21.3959 59.5834 24.9167 59.5834C26.5417 59.5834 27.3542 59.0417 28.4376 58.7709C29.7917 58.2292 31.1459 57.6875 33.3126 57.6875C35.7501 57.6875 36.8334 58.2292 38.1876 58.7709C39.2709 59.3125 40.0834 59.5834 41.7084 59.5834C45.5001 59.5834 48.2084 55.5209 50.3751 52.5417Z" 
                stroke="#0A1828" 
                stroke-width="4" 
                stroke-miterlimit="10" 
                stroke-linecap="round" 
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default Ios