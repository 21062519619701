import React, { useState } from "react";
import SelectBox from "./Select2";

const SelectBox2 = ({ onChange }) => {
  const [isOpen, setIsOpen] = useState({});

  const handleDropdownClick = (name, open) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [name]: open,
    }));
  };

  return (
    <div style={{ padding: "0 0 40px 0" }}>
      <div style={{ margin: "16px", position: "relative" }} />
      <SelectBox
        name="priority"
        items={[
          { value: "Urgent", id: 1 },
          { value: "In Planning", id: 2 },
          { value: "In Discussion", id: 3 },
          { value: "Thinking about it", id: 4 },
        ]}
        isOpen={isOpen["priority"]}
        onDropdownClick={handleDropdownClick}
        onChange={onChange}
      />
    </div>
  );
};

export default SelectBox2;
