import React from 'react'

const Wr04 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid"
        >
            <g clipPath="url(#clip0_2872_3668)">
                <path 
                    d="M29.2941 0.361149C28.8922 0.130901 28.4371 0.00976562 27.9739 0.00976562C27.5107 0.00976562 27.0556 0.130901 26.6537 0.361149L4.8575 13.0202C4.02844 13.4892 3.5625 14.3743 3.5625 15.3127V40.6825C3.5625 41.62 4.08006 42.5055 4.85663 42.975L26.6529 55.6336C27.0548 55.8639 27.51 55.9851 27.9732 55.9851C28.4365 55.9851 28.8917 55.8639 29.2936 55.6336L51.0899 42.9745C51.9185 42.5055 52.384 41.62 52.384 40.682V15.3127C52.384 14.3747 51.8664 13.4896 51.0899 13.0202L29.2941 0.361149Z" 
                    fill="url(#paint0_linear_2872_3668)"
                />
                <path 
                    d="M51.1424 13.02L29.2429 0.360898C29.028 0.251152 28.8024 0.163918 28.5695 0.100586L4.0293 42.3491C4.23259 42.5983 4.47794 42.8101 4.75423 42.9747L26.6542 55.6333C27.2754 55.9978 27.9999 56.1023 28.6732 55.8937L51.712 13.489C51.5607 13.2909 51.3658 13.1304 51.1424 13.02Z" 
                    fill="url(#paint1_linear_2872_3668)"
                />
                <path 
                    d="M51.1428 42.9735C51.764 42.6091 52.23 41.9839 52.4369 41.3067L28.4663 0.0473632C27.845 -0.0567618 27.1717 -0.00469929 26.6025 0.360176L4.85791 12.9672L28.3105 55.9975C28.6212 55.945 28.9843 55.8409 29.2949 55.6847L51.1428 42.9735Z" 
                    fill="url(#paint2_linear_2872_3668)"
                />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2872_3668" x1="36.8501" y1="9.7882" x2="16.9444" y2="50.1433" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3F873F"/>
                    <stop offset="0.33" stopColor="#3F8B3D"/>
                    <stop offset="0.637" stopColor="#3E9638"/>
                    <stop offset="0.934" stopColor="#3DA92E"/>
                    <stop offset="1" stopColor="#3DAE2B"/>
                </linearGradient>

                <linearGradient id="paint1_linear_2872_3668" x1="24.662" y1="30.96" x2="80.2008" y2="-9.8227" gradientUnits="userSpaceOnUse">
                    <stop offset="0.138" stopColor="#3F873F"/>
                    <stop offset="0.402" stopColor="#52A044"/>
                    <stop offset="0.713" stopColor="#64B749"/>
                    <stop offset="0.908" stopColor="#6ABF4B"/>
                </linearGradient>

                <linearGradient id="paint2_linear_2872_3668" x1="2.76477" y1="27.9889" x2="53.1999" y2="27.9889" gradientUnits="userSpaceOnUse">
                    <stop offset="0.092" stopColor="#6ABF4B"/>
                    <stop offset="0.287" stopColor="#64B749"/>
                    <stop offset="0.598" stopColor="#52A044"/>
                    <stop offset="0.862" stopColor="#3F873F"/>
                </linearGradient>

                <clipPath id="clip0_2872_3668">
                    <rect width="56" height="56" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Wr04;