import React from 'react'

const Wr05 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid"
        >
            <path 
                d="M11.3735 4.10938L21.4369 4.11331L28.0152 15.0324L34.5702 4.11419L44.6248 4.11156L28.0655 32.8356L11.3735 4.10938Z" 
                fill="#35495E"
            />
            <path 
                d="M0.397949 4.18658L11.3648 4.11133L28.0554 32.8371L44.6161 4.11308L55.6017 4.12445L28.0541 51.4007L0.397949 4.18658Z" 
                fill="#41B883"
            />
        </svg>
    );
}

export default Wr05;