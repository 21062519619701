import React from 'react'

const Designing = () => {
    return (
        <svg 
            width="60" 
            height="60" 
            viewBox="0 0 60 60" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg" 
            className="img-fluid"
        >
            <path 
                d="M7.5 24.375C3.7 24.375 0.625 21.3 0.625 17.5C0.625 13.7 3.7 10.625 7.5 10.625C11.3 10.625 14.375 13.7 14.375 17.5C14.375 21.3 11.3 24.375 7.5 24.375ZM7.5 14.375C5.775 14.375 4.375 15.775 4.375 17.5C4.375 19.225 5.775 20.625 7.5 20.625C9.225 20.625 10.625 19.225 10.625 17.5C10.625 15.775 9.225 14.375 7.5 14.375Z" 
                fill="#0A1828"
            />
            <path 
                d="M52.5 24.375C48.7 24.375 45.625 21.3 45.625 17.5C45.625 13.7 48.7 10.625 52.5 10.625C56.3 10.625 59.375 13.7 59.375 17.5C59.375 21.3 56.3 24.375 52.5 24.375ZM52.5 14.375C50.775 14.375 49.375 15.775 49.375 17.5C49.375 19.225 50.775 20.625 52.5 20.625C54.225 20.625 55.625 19.225 55.625 17.5C55.625 15.775 54.225 14.375 52.5 14.375Z" 
                fill="#0A1828"
            />
            <path 
                d="M47.5 19.375H37.5C36.475 19.375 35.625 18.525 35.625 17.5C35.625 16.475 36.475 15.625 37.5 15.625H47.5C48.525 15.625 49.375 16.475 49.375 17.5C49.375 18.525 48.525 19.375 47.5 19.375Z" 
                fill="#0A1828"
            />
            <path 
                d="M22.5 19.375H12.5C11.475 19.375 10.625 18.525 10.625 17.5C10.625 16.475 11.475 15.625 12.5 15.625H22.5C23.525 15.625 24.375 16.475 24.375 17.5C24.375 18.525 23.525 19.375 22.5 19.375Z" 
                fill="#0A1828"
            />
            <path 
                d="M15 51.875H10C6.9 51.875 4.375 49.35 4.375 46.25V41.25C4.375 38.15 6.9 35.625 10 35.625H15C18.1 35.625 20.625 38.15 20.625 41.25V46.25C20.625 48.5 19.3 50.5001 17.25 51.3751C16.575 51.7001 15.8 51.875 15 51.875ZM10 39.375C8.975 39.375 8.125 40.225 8.125 41.25V46.25C8.125 47.275 8.975 48.125 10 48.125H15C15.25 48.125 15.475 48.0751 15.725 47.9501C16.45 47.6501 16.875 46.975 16.875 46.25V41.25C16.875 40.225 16.025 39.375 15 39.375H10Z" 
                fill="#0A1828"
            />
            <path 
                d="M50 51.875H45C44.2 51.875 43.425 51.7001 42.675 51.3501C40.7 50.5251 39.375 48.5 39.375 46.25V41.25C39.375 38.15 41.9 35.625 45 35.625H50C53.1 35.625 55.625 38.15 55.625 41.25V46.25C55.625 49.35 53.1 51.875 50 51.875ZM45 39.375C43.975 39.375 43.125 40.225 43.125 41.25V46.25C43.125 46.975 43.55 47.6501 44.2 47.9251C44.525 48.0751 44.75 48.125 45 48.125H50C51.025 48.125 51.875 47.275 51.875 46.25V41.25C51.875 40.225 51.025 39.375 50 39.375H45Z" 
                fill="#0A1828"
            />
            <path 
                d="M33.75 26.875H26.25C23.15 26.875 20.625 24.35 20.625 21.25V13.75C20.625 10.65 23.15 8.125 26.25 8.125H33.75C36.85 8.125 39.375 10.65 39.375 13.75V21.25C39.375 24.35 36.85 26.875 33.75 26.875ZM26.25 11.875C25.225 11.875 24.375 12.725 24.375 13.75V21.25C24.375 22.275 25.225 23.125 26.25 23.125H33.75C34.775 23.125 35.625 22.275 35.625 21.25V13.75C35.625 12.725 34.775 11.875 33.75 11.875H26.25Z" 
                fill="#0A1828"
            />
            <path 
                d="M47.4244 39.375C47.3494 39.375 47.2744 39.375 47.1994 39.375C46.1744 39.25 45.4244 38.3251 45.5494 37.3001C45.5994 36.9501 45.5994 36.625 45.5994 36.275C45.5994 29.775 42.0744 23.7749 36.6244 21.0249C35.6994 20.5499 35.3244 19.4251 35.7994 18.5001C36.2744 17.5751 37.3994 17.2 38.3244 17.675C45.0244 21.075 49.3494 28.375 49.3494 36.275C49.3494 36.775 49.3244 37.2499 49.2494 37.7499C49.1744 38.6749 48.3744 39.375 47.4244 39.375Z" 
                fill="#0A1828"
            />
            <path 
                d="M12.575 39.375C11.625 39.375 10.825 38.6749 10.725 37.7249C10.675 37.2249 10.625 36.75 10.625 36.25C10.625 28.35 14.95 21.05 21.65 17.65C22.575 17.175 23.7 17.55 24.175 18.475C24.65 19.4 24.275 20.5251 23.35 21.0001C17.9 23.7501 14.375 29.75 14.375 36.25C14.375 36.6 14.4 36.9251 14.425 37.2751C14.55 38.3001 13.8 39.225 12.775 39.35C12.725 39.375 12.65 39.375 12.575 39.375Z" 
                fill="#0A1828"
            />
        </svg>
    )
}

export default Designing