import React from "react";
// import noInternetImage from "/assets/img/extra/no-internet.png";

const NoInternet = () => {
  return (
    <>
      <section className="no_internet_connection_section">
        <div className="container">
          <div className="image text-center">
            <img
              src="/assets/img/extra/no-internet.png"
              alt="no-internet-img"
              className="img-fluid"
              draggable="false"
            />
          </div>
          <div className="text text-center">
            <h5>No Internet Connection</h5>
            <p>Please check your internet</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoInternet;
