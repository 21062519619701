import React from 'react'

const Ur02 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <path 
                d="M42.875 0H13.125C5.87626 0 0 5.87626 0 13.125V42.875C0 50.1237 5.87626 56 13.125 56H42.875C50.1237 56 56 50.1237 56 42.875V13.125C56 5.87626 50.1237 0 42.875 0Z" 
                fill="#330000"
            />
            <path 
                d="M27.0665 33.3227H18.3865L16.6131 38.8061C16.5665 39.0161 16.3799 39.1561 16.1699 39.1327H11.7831C11.5265 39.1327 11.4565 38.9927 11.5265 38.7127L19.0399 17.0827C19.1099 16.8495 19.1799 16.5927 19.2731 16.3127C19.3665 15.8227 19.4131 15.3095 19.4131 14.7961C19.3899 14.6795 19.4831 14.5627 19.5999 14.5395H25.6431C25.8299 14.5395 25.9231 14.6095 25.9465 14.7261L34.4631 38.7595C34.5331 39.0161 34.4631 39.1327 34.2299 39.1327H29.3531C29.1899 39.1561 29.0265 39.0395 28.9799 38.8761L27.0665 33.3227ZM19.7399 28.5861H25.6665C25.5265 28.0961 25.3399 27.5127 25.1299 26.9061C24.9199 26.2761 24.7099 25.5995 24.4999 24.8995C24.2665 24.1761 24.0565 23.4761 23.8231 22.7527C23.5899 22.0295 23.3799 21.3527 23.1931 20.6761C23.0065 20.0229 22.8431 19.4161 22.6799 18.8561H22.6331C22.4231 19.8595 22.1665 20.8629 21.8399 21.8661C21.4899 22.9861 21.1399 24.1527 20.7665 25.3195C20.4653 26.4208 20.1228 27.5104 19.7399 28.5861ZM39.6665 18.6227C38.8965 18.6461 38.1499 18.3427 37.5899 17.8061C37.0534 17.2227 36.7734 16.4527 36.7965 15.6595C36.7734 14.8661 37.0765 14.1195 37.6365 13.5827C38.1965 13.0459 38.9434 12.7661 39.7134 12.7661C40.6234 12.7661 41.3234 13.0461 41.8365 13.5827C42.3537 14.1484 42.63 14.8934 42.6065 15.6595C42.6299 16.4527 42.3499 17.2227 41.7899 17.8061C41.2534 18.3661 40.4599 18.6695 39.6665 18.6227ZM37.0534 38.8761V20.9095C37.0534 20.6761 37.1465 20.5827 37.3565 20.5827H41.9765C42.1865 20.5827 42.2799 20.6995 42.2799 20.9095V38.8761C42.2799 39.1327 42.1865 39.2495 41.9765 39.2495H37.4034C37.1699 39.2495 37.0534 39.1095 37.0534 38.8761Z" 
                fill="#FF9A00"
            />
        </svg>
    )
}

export default Ur02;