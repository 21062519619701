import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import axios from "axios";
import DOMPurify from "dompurify";
import SelectBox1 from "../component/SelectBox1";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from 'yup';

const Career = () => {
  const [career, setCareer] = useState([]);
  const [selectedCareer, setSelectedCareer] = useState("");
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);

  const realFileBtnRef = useRef(null);
  const customTxtRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const resumeRef = useRef(null);

  const handleButtonClick = () => {
    realFileBtnRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("resume", file);
      customTxtRef.current.textContent = file.name;
      setResumeUploaded(true);
    } else {
      customTxtRef.current.textContent = "No File Chosen";
    }
  };

  // Get Career Data
  useEffect(() => {
    axios
      .get("/api/careers")
      .then((res) => setCareer(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Form Data Post
  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name."),
    position: Yup.mixed().required(
      "To apply for your role, kindly pick your ideal position."
    ),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,"Please enter a valid email address.")
      .test(
        'no-test-or-demo',  
        "Please enter a valid email address.",
        value => !/test|demo/i.test(value)
      )
      .required("Please enter your email address."),
    contact_number: Yup.string()
      .required("Please enter your contact number.")
      .min(10, "Please enter a valid contact number."),
    resume: Yup.mixed().required("Please attach your updated resume/CV."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact_number: "",
      message: "",
      position: selectedCareer,
      resume: null,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("contact_number", values.contact_number.replace(/\D/g, ""));
      formData.append("message", values.message);
      formData.append("position", values.position);
      formData.append("resume", values.resume);
      try {
        const response = await axios.post(`/api/vacancy/apply`, formData);
        if (response.data.success === 1) {
          resetForm();
          realFileBtnRef.current.value = null;
          setResumeUploaded(false);
          customTxtRef.current.textContent = "No File Chosen";
          toast.success(response.data.msg);
        } else {
            toast.error(response.data.msg);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  });

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;

    const formattedValue = value.replace(/\D/g, "").slice(0, 13);
    formik.setFieldValue('contact_number', formattedValue);
  };

  const handleApplyClick = (position, e) => {
    e.stopPropagation();
    setSelectedCareer(position);
    formik.setFieldValue("position", position);
  };

  return (
    <>
      {/* ======= Breadcrumb Hero section ======= */}
      <section className="breadcrumb_hero_section">
        <div className="container">
          <div className="text_area text-center">
            <h1>Career</h1>
            <div className="breadcumb_area">
              <ul className="d-flex align-items-center justify-content-center">
                <li>
                  <Link to={"/"} className="me-2">
                    Home
                  </Link>
                </li>
                <li>
                  <h5> &gt; Career</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* ======= Career section ======= */}
      <section className="career_section">
        <div className="container">
          {career.length > 0 ? (
            <>
              <div className="main_text">
                <h5>Open Positions</h5>
                <p>
                  We’re constantly seeking for great people to join our team.
                  Browse the open roles below to learn how you can contribute to
                  our success.
                </p>
              </div>
              <div className="accordian_area">
                <Accordion className="accordion" allowZeroExpanded={true}>
                  {career.map((v, i) => {
                    return (
                      <AccordionItem className="accordion-item" key={i}>
                        <AccordionItemButton>
                          <div className="main_box d-flex">
                            <div className="left_side_area w-100">
                              <h4>{v.title}</h4>
                              <ul className="d-flex ">
                                <li>{v.experience}</li>
                                <li>
                                  <span className="mx-2">
                                    <svg
                                      width={6}
                                      height={6}
                                      viewBox="0 0 6 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        opacity="0.5"
                                        cx={3}
                                        cy={3}
                                        r={3}
                                        fill="#778194"
                                      />
                                    </svg>
                                  </span>{" "}
                                  {v.positions} Positions
                                </li>
                                <li>
                                  <span className="mx-2">
                                    <svg
                                      width={6}
                                      height={6}
                                      viewBox="0 0 6 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/formAreasvg"
                                    >
                                      <circle
                                        opacity="0.5"
                                        cx={3}
                                        cy={3}
                                        r={3}
                                        fill="#778194"
                                      />
                                    </svg>
                                  </span>{" "}
                                  {v.job_type}
                                </li>
                              </ul>
                            </div>
                            <div className="right_side_area">
                              <a
                                href="#formArea"
                                onClick={(e) => handleApplyClick(v.title, e)}
                              >
                                <button>Apply</button>
                              </a>
                            </div>
                          </div>
                        </AccordionItemButton>
                        <AccordionItemPanel>
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="left_side_body_area">
                                  <div className="area">
                                    <h5>Job Summary</h5>
                                    <p>{v.summary}</p>
                                  </div>
                                  <div className="area">
                                    <h5>Responsibilities and Duties</h5>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          v.responsibilities
                                        ),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="right_side_body_area">
                                  <div className="area">
                                    <h5>What we offer</h5>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          v.what_we_offer
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div className="area_button">
                                    <h5>Familiar with Software</h5>
                                    <div className="button_area">
                                      {JSON.parse(v.familiar_softwares).map(
                                        (software, index) => (
                                          <button key={index}>
                                            {software}
                                          </button>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
            </>
          ) : (
            <section className="empty_section" style={{ padding: 0 }}>
              <div className="container">
                <div className="image text-center">
                  <img
                    src="assets/img/extra/empty.png"
                    alt="empty-img"
                    className="img-fluid"
                    draggable="false"
                  />
                </div>
                <div className="text text-center">
                  <h5>Empty Open Positions</h5>
                  <p>
                    There are currently no positions, but you may apply for
                    future chances using the form below.
                  </p>
                </div>
              </div>
            </section>
          )}
        </div>
      </section>

      {/* ======= Apply form section ======= */}
      <section className="apply_form_section" id="formArea">
        <div className="container">
          <div className="top_text">
            <h4>Apply Here</h4>
            <p>
              Your email address will not be published. Required fields are
              marked *
            </p>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label ms-2" htmlFor="exampleCheck1">
                Save my name, email, and website in this browser for the next
                time I comment.
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="input_box">
                      <input
                        type="text"
                        className="input_field"
                        placeholder="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        ref={nameRef}
                      />
                      {formik.touched.name && formik.errors.name && <p className="form_error">{formik.errors.name}</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box">
                      <div style={{ position: "relative" }}>
                        <SelectBox1
                          onChange={formik.handleChange}
                          selectedCareer={selectedCareer}
                          onBlur={formik.handleBlur}
                        />
                      {formik.touched.position && formik.errors.position && <p className="form_error form_error_dropdown">{formik.errors.position}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box">
                      <input
                        type="email"
                        className="input_field"
                        placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        ref={emailRef}
                      />
                      {formik.touched.email && formik.errors.email && <p className="form_error">{formik.errors.email}</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box carrer_contact">
                      <label
                        type="text"
                        className="input_field_91"
                        placeholder="+91"
                        name="contact_number"
                      >+91</label>
                      <input
                        type="text"
                        className="input_field"
                        inputMode="numeric"
                        placeholder="XXXXX XXXXX"
                        name="contact_number"
                        value={formik.values.contact_number}
                        onChange={handlePhoneNumberChange}
                        onBlur={formik.handleBlur}
                        ref={phoneNumberRef}
                      />
                    </div>
                    {formik.touched.contact_number && formik.errors.contact_number && <p className="form_error_carrer_num">{formik.errors.contact_number}</p>}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="file"
                      id="read_file"
                      style={{ display: "none" }}
                      name="resume"
                      ref={realFileBtnRef}
                      accept=".pdf,.doc, .docx"
                      onChange={handleFileChange}
                    />
                    <button
                      type="button"
                      id="custom_button"
                      className="inputFile"
                      onClick={handleButtonClick}
                    >
                      {resumeUploaded ? "Update Resume" : "Add Resume"}
                    </button>
                    <span id="custom_text" ref={customTxtRef}>
                      No File Chosen
                    </span>
                    {formik.touched.resume && formik.errors.resume && <p className="form_error">{formik.errors.resume}</p>}

                  </div>
                  <div className="col-12">
                    <div className="input_box">
                      <textarea
                        id="exampleFormControlTextarea1"
                        rows={4}
                        placeholder="Message"
                        className="input_field"
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="apply_btn" type="submit">
                      Apply
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
