import React from 'react';
import { Link } from 'react-router-dom';
import Texts from "../content/Text.json";
import ContactImg from "../component/imgComponent/servicesdetail/ContactImg";
import ArrowImg from "../component/imgComponent/servicesdetail/ArrowImg";

const ServicesContact = () => {
    return (
        <section className="services_contact_us_section">
            <div className="container">
                <div className="content_area">
                    <div className="left_side">
                        <ContactImg />
                    </div>
                    <div className="right_side">
                        <h1>
                            Have a project in mind?<br />
                            <span style={{ color:"#178582", fontWeight: 700 }}>Let’s get start</span>
                        </h1>
                        <p>
                            {Texts.servicedetail5}
                        </p>
                        <div className="dot_btn_area dot_btn_area_2">
                            <ArrowImg />
                            <Link to={"/contact"} style={{ color: "#F3F3F3" }}>
                                <div className="btn">
                                    <span>
                                        Contact Us
                                    </span>
                                    <div className="dot"></div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    );
}

export default ServicesContact;