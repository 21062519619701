import React from 'react'

const Wr01 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid"
        >
            <path 
                d="M36.7547 6.53462C36.7547 6.53462 36.1325 6.72128 35.0747 7.0635C34.8881 6.50351 34.6392 5.78797 34.2659 5.10355C33.0837 2.83249 31.3415 1.6503 29.226 1.61919C29.0704 1.61919 28.946 1.61919 28.7904 1.6503C28.7282 1.58808 28.666 1.49475 28.6038 1.43253C27.7016 0.437002 26.5194 -0.029653 25.1194 0.00145735C22.4128 0.0947882 19.7062 2.05474 17.4974 5.53909C15.9419 7.99681 14.7908 11.0767 14.4486 13.4411C11.3376 14.4055 9.15984 15.09 9.09762 15.09C7.51099 15.5877 7.47988 15.6499 7.26211 17.1121C7.10656 18.2321 3 50.089 3 50.089L37.1591 56V6.4724C37.0036 6.4724 36.848 6.50351 36.7547 6.53462ZM28.8527 8.99233C27.0483 9.55232 25.0572 10.1745 23.0973 10.7656C23.6572 8.61901 24.715 6.50351 26.0216 5.10355C26.5194 4.57467 27.1727 4.01469 27.9816 3.67247C28.7282 5.2591 28.8838 7.46793 28.8527 8.99233ZM25.1505 1.80585C25.7727 1.80585 26.3327 1.9303 26.7994 2.2414C26.0527 2.61472 25.3683 3.17471 24.6839 3.89025C22.9728 5.72575 21.6351 8.61901 21.1062 11.3878C19.4573 11.8856 17.8707 12.3834 16.3774 12.85C17.3107 8.46346 20.9818 1.9303 25.1505 1.80585ZM19.8618 26.663C20.0484 29.5562 27.7016 30.2096 28.1371 37.0227C28.4793 42.3737 25.3061 46.0447 20.7018 46.3247C15.2263 46.6669 12.1775 43.4314 12.1775 43.4314L13.3286 38.4849C13.3286 38.4849 16.3774 40.7871 18.804 40.6315C20.3907 40.5382 20.9506 39.2315 20.9195 38.3293C20.6707 34.5339 14.4486 34.7517 14.0753 28.5296C13.7331 23.272 17.1863 17.9832 24.7772 17.4854C27.7327 17.2988 29.226 18.0454 29.226 18.0454L27.4838 24.5475C27.4838 24.5475 25.555 23.6764 23.2528 23.8008C19.8929 24.0186 19.8307 26.1341 19.8618 26.663ZM30.6571 8.43235C30.6259 7.0635 30.4704 5.13466 29.8171 3.48581C31.8704 3.89024 32.897 6.19241 33.3325 7.59237C32.5548 7.84125 31.6526 8.12125 30.6571 8.43235ZM38.2791 55.8756L52.4654 52.3601C52.4654 52.3601 46.3678 11.1078 46.3366 10.8278C46.3055 10.5478 46.0567 10.3923 45.8389 10.3612C45.6211 10.3301 41.639 10.2679 41.639 10.2679C41.639 10.2679 39.2124 7.90347 38.2791 7.00128V55.8756Z" 
                fill="black"
            />
        </svg>
    );
}

export default Wr01;