import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";

gsap.registerPlugin(ScrollTrigger);

const BlogDetail = () => {
  const { slug } = useParams();

  const [blogDetail, setBlogDetail] = useState([]);
  const [comments, setComments] = useState([]);
  const [blogCategory, setBlogCategory] = useState([]);
  const [recent, setRecent] = useState([]);
  const [archivesOne, setArchivesOne] = useState([]);
  const [archivesTwo, setArchivesTwo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContent, setFilteredContent] = useState(blogDetail.content);
  const navigate = useNavigate();

  // Last Month Date Get
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const lastMonthMonthName = monthNames[lastMonth.getMonth()];
  const lastMonthYear = lastMonth.getFullYear();
  const month = lastMonth.getMonth() + 1;
  const lastToLastMonth = new Date(today);

  // Last to Last Month Date Get
  lastToLastMonth.setMonth(lastToLastMonth.getMonth() - 2);
  const lastToLastMonthName = monthNames[lastToLastMonth.getMonth()];
  const lastToLastMonthYear = lastToLastMonth.getFullYear();
  const month2 = lastToLastMonth.getMonth() + 1;

  // Get BlogDetail Data
  useEffect(() => {
    axios
      .get(`/api/blogs/${slug}`)
      .then((res) => {
        const data = res.data.data;
        setBlogDetail(data);
        setFilteredContent(data.content);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [slug]);

  useEffect(() => {
    axios
      .get("/api/blog_categories")
      .then((res) => setBlogCategory(res.data.data))
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/api/blogs")
      .then((res) => setRecent(res.data.recent))
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `/api/blogs?date=${lastMonthYear}-${month}`
      )
      .then((res) => setArchivesOne(res.data.data))
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `/api/blogs?date=${lastToLastMonthYear}-${month2}`
      )
      .then((res) => setArchivesTwo(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Line Animation
  const strokeRef = useRef(null);

  useEffect(() => {
    gsap.from(strokeRef.current, {
      "--dashOffset": -700,
      delay: 1,
      scrollTrigger: {
        trigger: "#blogdetail_animation",
        start: "0% 0%",
        end: "-30% -30%",
        scrub: 2.5,
      },
    });
  }, []);

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      const regex = new RegExp(searchQuery, "i");
      const sentences = blogDetail.content.split(/(?<=[.?!])\s+/); // Split content into sentences
      const searchResults = sentences
        .map((sentence) =>
          regex.test(sentence)
            ? sentence.replace(regex, `<mark>${searchQuery}</mark>`)
            : null
        )
        .filter(Boolean)
        .join(" ");

      if (searchResults === "") {
        navigate("/no-resultfound");
      } else {
        setFilteredContent(searchResults);
      }
    } else {
      setFilteredContent(blogDetail.content);
    }
  }, [searchQuery, blogDetail.content, navigate]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  //Render image or video based on api response
  const renderMedia = (landscape_image, video) => {
    if (video) {
      return (
        <video width="100%" muted autoPlay loop>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (landscape_image) {
      return (
        <img
          src={landscape_image}
          alt="post-6"
          className="w-100"
          draggable="false"
        />
      );
    }
    return null;
  };

  return (
    <>
      {/* ======= Breadcrumb Hero section ======= */}
      <section className="breadcrumb_hero_section" id="blogdetail_animation">
        <div className="container-fluid">
          <div className="text_area text-center">
            <h1>{blogDetail.title}</h1>
            <div className="breadcumb_area">
              <ul className="d-flex align-items-center justify-content-center">
                <li>
                  <Link to={"/"} className="me-2">
                    Home &gt;{" "}
                  </Link>
                </li>
                <li>
                  <Link to={"/blog"} className="me-2"> 
                    Blog
                  </Link>
                </li>
                <li>
                  <h5> &gt; {blogDetail.title}</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="line_animation">
          <svg
            width="612"
            height="100"
            viewBox="0 0 612 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke"
              ref={strokeRef}
              d="M612 99L51 99C23.3858 99.0001 0.999987 76.6196 0.999984 49.0054C0.999983 33.8619 0.999981 16.7807 0.99998 -2.99018e-08"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>

      {/* ======= Blog Details section ======= */}
      <section className="blog_section">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-6 col-md-7">
              <div className="top_search_area">
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img
                  src="/assets/img/icons/search-icon.svg"
                  alt="search-icon"
                  className="img-fluid"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="left_side_area">
                <div className="row">
                  <div className="col-xxl-12 col-12">
                    <div className="image_box">
                      <div>
                        <div className="image">
                          {renderMedia(
                            blogDetail.landscape_image,
                            blogDetail.video
                          )}
                        </div>
                        <div className="blog_info">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(filteredContent),
                            }}
                            className="info_area"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog_tags_area">
                      {blogDetail.categories?.map((v, i) => (
                        <button key={i}>{v}</button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="right_side_area">
                {recent.length > 0 && (
                  <div className="recent_post_area">
                    <h4>Recent Post</h4>
                    {recent.map((v, i) => {
                      return (
                        <Link
                          className="first"
                          key={i}
                          to={`/blogdetail/${v.slug}`}
                        >
                          {v.title}
                        </Link>
                      );
                    })}
                  </div>
                )}
                {(archivesOne.length > 0 || archivesTwo.length > 0) && (
                  <div className="archive_area">
                    <h4>Archive</h4>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      {archivesOne.length === 0 ? (
                        ""
                      ) : (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              {lastToLastMonthName} {lastToLastMonthYear}
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {archivesOne.map((item) => (
                                <Link key={item} to={`/blogdetail/${item.slug}`} className="first">
                                  {item.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {archivesTwo.length === 0 ? (
                        ""
                      ) : (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              {lastMonthMonthName} {lastMonthYear}
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {archivesTwo.map((item) => (
                                <Link key={item} to={`/blogdetail/${item.slug}`} className="second">
                                  {item.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {blogCategory.length > 0 && (
                  <div className="category_area">
                    <h4>Category</h4>
                    <ul>
                      {blogCategory.map((v, i) => {
                        return (
                          <li key={i}>
                            <div className="d-flex align-items-center">
                              <div className="icon">
                                <img
                                  src={v.logo}
                                  alt={v.name}
                                  className="img-fluid"
                                  draggable="false"
                                />
                              </div>
                              <div className="text">
                                <p>{v.name}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {Object.keys(comments).length > 0 && (
                  <div className="recent_comments">
                    <h4>Recent Comments</h4>
                    <div className="blog_entry">
                      <div className="comments">
                        {Object.keys(comments).map((v, i) => {
                          return (
                            <div className="comment_box" key={i}>
                              <div className="left_side">
                                <img
                                  src="/assets/img/blog/comment-1.png"
                                  alt="comment"
                                  draggable="false"
                                />
                              </div>
                              <div className="right_side">
                                <h6>{v}</h6>
                                <p>{comments[v]}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
