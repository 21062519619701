import React from 'react'

const Android = () => {
    return (
        <svg 
            width="52" 
            height="64" 
            viewBox="0 0 52 64" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid"
        >
            <path 
                d="M50 17V47C50 59 47 62 35 62H17C5 62 2 59 2 47V17C2 5 5 2 17 2H35C47 2 50 5 50 17Z" 
                stroke="#0A1828" 
                stroke-width="4" 
                stroke-linecap="round" 
                stroke-linejoin="round"
                />
            <path 
                d="M32 12.5H20" 
                stroke="#0A1828" 
                stroke-width="4" 
                stroke-linecap="round" 
                stroke-linejoin="round"
                />
            <path 
                d="M26.0001 53.3C28.5682 53.3 30.6501 51.2181 30.6501 48.65C30.6501 46.0819 28.5682 44 26.0001 44C23.432 44 21.3501 46.0819 21.3501 48.65C21.3501 51.2181 23.432 53.3 26.0001 53.3Z" 
                stroke="#0A1828" 
                stroke-width="4" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
        </svg>
    )
}

export default Android