import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./component/Layout";
import Home from "./container/Home";
import Error from "./container/Error";
import BlogDetail from "./container/BlogDetail";
import Blog from "./container/Blog";
import Career from "./container/Career";
import Company from "./container/Company";
import Contact from "./container/Contact";
import Empty from "./container/Empty";
import Maintenance from "./container/Maintenance";
import NoInternet from "./container/NoInternet";
import PortfolioDetail from "./container/PortfolioDetail";
import Portfolio from "./container/Portfolio";
import Services from "./container/Services";
import Team from "./container/Team";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import ScrollToTop from "./component/Scrolltop";
import NoResultFound from "./container/NoResultFound";
import DisableInspectMode from "./component/DisableInspectMode";
import WebDevelopment from "./container/WebDevelopment";
import UiUxDesign from "./container/UiUxDesign";
import MobileDevelopment from "./container/MobileDevelopment";
import GameDevelopment from "./container/GameDevelopment";

function App() {
  // Bottom To Top Navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DisableInspectMode>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<Company />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/web-design-&-development" element={<WebDevelopment />} />
              <Route path="/services/ui-ux-design" element={<UiUxDesign />} />
              <Route path="/services/mobile-development" element={<MobileDevelopment />} />
              <Route path="/services/game-development" element={<GameDevelopment />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blogdetail/:slug" element={<BlogDetail />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolio-detail/:slug" element={<PortfolioDetail />} />
              <Route path="/career" element={<Career />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/team" element={<Team />} />
              <Route path="/empty" element={<Empty />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/no-internet" element={<NoInternet />} />
              <Route path="/no-resultfound" element={<NoResultFound />} />
              <Route path="/error" element={<Error />} />
              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
          <Toaster position="bottom-right" />
        </Router>
      </DisableInspectMode>
    </>
  );
}

export default App;
