import React from "react";
import DeviceImg from "./imgComponent/DeviceImg";
import Wl01 from "./imgComponent/servicesdetail/web_dev/Wl01";
import Wl02 from "./imgComponent/servicesdetail/web_dev/Wl02";
import Wl03 from "./imgComponent/servicesdetail/web_dev/Wl03";
import Wl04 from "./imgComponent/servicesdetail/web_dev/Wl04";
import Wl05 from "./imgComponent/servicesdetail/web_dev/Wl05";
import Wr01 from "./imgComponent/servicesdetail/web_dev/Wr01";
import Wr02 from "./imgComponent/servicesdetail/web_dev/Wr02";
import Wr03 from "./imgComponent/servicesdetail/web_dev/Wr03";
import Wr04 from "./imgComponent/servicesdetail/web_dev/Wr04";
import Wr05 from "./imgComponent/servicesdetail/web_dev/Wr05";

const WebTech = () => {
    return (
        <section className="services_our_technology_expertise">
            <div className="container">
                <div className="our_technology_expertise">
                    <div className="top_text_area">
                        <h1>Our Technology <span style={{color:"#178582"}}>Expertise</span></h1>
                    </div>
                    <div className="technology_wrapper">
                        <div className="sticky_device_image">
                            <DeviceImg className="device_image" />
                            <img 
                                src="/assets/img/servicesdetail/ipad_device.png" 
                                alt="device" 
                                className="device_image_2"
                                draggable="false"  
                            />
                            <img 
                                src="/assets/img/servicesdetail/web_dev/w_inner-img.png" 
                                alt="device_inner_image" 
                                className="inner_image img-fluid"
                                draggable="false"                   
                            />
                        </div>

                        <div className="tech_logos">
                            <div className="logo_wrapper">
                                <div className="logo_container l_01">
                                    <Wl01 />
                                    <span className="logo_name">LARAVEL</span>
                                </div>
                                <div className="dashed_line l_01_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container r_01">
                                    <Wr01 />
                                    <span className="logo_name">SHOPIFY</span>
                                </div>
                                <div className="dashed_line r_01_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container l_02">
                                    <Wl02 />
                                    <span className="logo_name">HTML</span>
                                </div>
                                <div className="dashed_line l_02_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container r_02">
                                    <Wr02 />
                                    <span className="logo_name">REACT JS</span>
                                </div>
                                <div className="dashed_line r_02_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container l_03">
                                    <Wl03 />
                                    <span className="logo_name">WORDPRESS</span>
                                </div>
                                <div className="dashed_line l_03_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container r_03">
                                    <Wr03 />
                                    <span className="logo_name">CODEIGNITER</span>
                                </div>
                                <div className="dashed_line r_03_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container l_04">
                                    <Wl04 />
                                    <div className="dashed_line l_04_line"></div>
                                </div>
                            </div> 

                            <div className="logo_wrapper">
                                <div className="logo_container r_04">
                                    <Wr04 />
                                    <span className="logo_name">NODE JS</span>
                                    <div className="dashed_line r_04_line"></div>
                                </div>
                            </div>    

                            <div className="logo_wrapper">
                                <div className="logo_container l_05">
                                    <Wl05 />
                                    <span className="logo_name">JAVASCRIPT</span>
                                </div>
                                <div className="dashed_line l_05_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container r_05">
                                    <Wr05 />
                                    <span className="logo_name">VUE JS</span>
                                </div>
                                <div className="dashed_line r_05_line"></div>
                            </div>
                        </div>         
                    </div>

                    {/* Only For Mobile View Section */}
                    <div className="technology_wrapper_2">
                        <div className="col-12">
                            <ul className="services_listing">
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wl01 />
                                        </div>
                                        <div className="services_title">LARAVEL</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wr01 />
                                        </div>
                                        <div className="services_title">SHOPIFY</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wl02 />
                                        </div>
                                        <div className="services_title">HTML</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wr02 />
                                        </div>
                                        <div className="services_title">REACT JS</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wl03 />
                                        </div>
                                        <div className="services_title">WORDPRESS</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wr03 />
                                        </div>
                                        <div className="services_title">CODEIGNITER</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wl04 />
                                        </div>
                                        <div className="services_title">PHP</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <img 
                                                src="/assets/img/servicesdetail/web_dev/w_r-04.png" 
                                                alt="node_logo" 
                                                className="img-fluid" 
                                                draggable="false" 
                                            />
                                        </div>
                                        <div className="services_title">NODE JS</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wl05 />
                                        </div>
                                        <div className="services_title">JAVASCRIPT</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Wr05 />
                                        </div>
                                        <div className="services_title">VUE JS</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WebTech;