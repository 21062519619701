import { Link, NavLink, useLocation } from "react-router-dom";
import { SiUpwork } from "react-icons/si";

const Header = () => {
  const location = useLocation();
  // const companyPaths = ["/about", "/team"];
  const servicesPaths = [
    "/services",
    "/services/mobile-development",
    "/services/game-development",
    "/services/ui-ux-design",
    "/services/web-design-&-development"
  ];
  
  // const isCompanyActive = companyPaths.includes(location.pathname);
  const isServicesActive = servicesPaths.includes(location.pathname);

  const isBlogActive = location.pathname.startsWith("/blogdetail");
  const isPortfolioActive = location.pathname.startsWith("/portfolio-detail");
  return (
    <>
      <header className="header sticky-header">
        {/* Top Bar */}
        <div className="header_top_bar">
          <div className="container-fluid">
            <div className="top_bar_area">
              <div className="text">
                <p>“ Innovating Digital Dreams ”</p>
              </div>
              <div className="icons">
                <Link
                  to={"https://www.facebook.com/cloudpark.infotech"}
                  target={"_blank"}
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link
                  to={"https://linkedin.com/company/cloudpark-infotech"}
                  target={"_blank"}
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </Link>
                <Link
                  to={"https://twitter.com/CloudParkInfo"}
                  target={"_blank"}
                >
                  <i className="fa-brands fa-twitter"></i>
                </Link>
                <Link
                  to={"https://www.instagram.com/cloudpark.infotech"}
                  target={"_blank"}
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
                <Link>
                  <SiUpwork
                    style={{ position: "relative", zIndex: "9" }}
                    className="social_icon"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Nav Bar */}
        <div className="header_area" id="navbar_sticky">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <Link className="navbar-brand" to={"/"}>
                <img
                  src="/assets/img/header_logo.svg"
                  alt="logo"
                  className="img-fluid"
                  draggable="false"
                />
              </Link>
              <button
                className="navbar-toggler"
                href="#offcanvasExample"
                data-bs-toggle="offcanvas"
                aria-controls="navbarSupportedContent"
              >
                <img
                  src="/assets/img/icons/toggler-icon.svg"
                  alt="toggler-icon"
                  draggable="false"
                />
              </button>
              <div
                className="offcanvas offcanvas-start"
                tabIndex={-1}
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasExample">
                    <img
                      src="/assets/img/header_logo.svg"
                      alt="logo"
                      className="img-fluid"
                      draggable="false"
                    />
                  </h5>
                  <button
                    type="button"
                    className="close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark" />
                  </button>
                </div>
                <div className="offcanvas-body">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    
                    <Link to={"/"}>
                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <NavLink className="nav-link" to={"/"}>
                          Home
                        </NavLink>
                      </li>
                    </Link>

                    {/* <Link>
                      <li
                        className={`nav-item dropdown ${
                          isCompanyActive ? "active" : ""
                        }`}
                      >
                        <NavLink
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Company
                        </NavLink>
                        <ul className="dropdown-menu">
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link className="dropdown-item" to={"/about"}>
                              About Us
                            </Link>
                          </li>
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link className="dropdown-item" to={"/team"}>
                              Team
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </Link> */}

                    <Link to={"/about"} >
                      <li
                        className="nav-item "
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <NavLink className="nav-link" to={"/about"}>
                          About Us
                        </NavLink>
                      </li>
                    </Link>

                    <Link>
                      <li
                        className={`nav-item dropdown ${
                          isServicesActive ? "active" : ""
                        }`}
                      >
                        <NavLink 
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Services
                        </NavLink>
                        <ul className="dropdown-menu">
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link className="dropdown-item" to={"/services"}>
                              Our Services
                            </Link>
                          </li>
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link className="dropdown-item" to={"/services/mobile-development"}>
                              Mobile Development
                            </Link>
                          </li>
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link className="dropdown-item" to={"/services/web-design-&-development"}>
                              Web Development
                            </Link>
                          </li>
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link className="dropdown-item" to={"/services/ui-ux-design"}>
                              UI/UX Design
                            </Link>
                          </li>
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link className="dropdown-item" to={"/services/game-development"}>
                              Game Development
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </Link>

                    <Link to={"/blog"} >
                      <li
                        className={`nav-item ${isBlogActive ? "active" : ""}`}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <NavLink className="nav-link" to={"/blog"}>
                          Blog
                        </NavLink>
                      </li>
                    </Link>

                    <Link to={"/portfolio"} >
                        <li
                          className={`nav-item ${
                            isPortfolioActive ? "active" : ""
                          }`}
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <NavLink className="nav-link" to={"/portfolio"}>
                            Portfolio
                          </NavLink>
                      </li>
                    </Link>

                    <Link to={"/career"} >
                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <NavLink className="nav-link" to={"/career"}>
                          Career
                        </NavLink>
                      </li>
                    </Link>

                    <Link to={"/contact"} className="last-child">
                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <NavLink className="nav-link" to={"/contact"}>
                          Contact
                        </NavLink>
                      </li>
                    </Link>

                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div className="shape"></div>
        </div>
      </header>
    </>
  );
};

export default Header;
