import React from 'react'

const Wl02 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <path 
                d="M8.32885 49.8206L3.95166 0.726562H52.0487L47.6667 49.8132L27.9708 55.2732L8.32885 49.8206Z" 
                fill="#E44D26"
            />
            <path 
                d="M28 51.1L43.9154 46.6873L47.6599 4.74072H28V51.1Z" 
                fill="#F16529"
            />
            <path 
                d="M27.9999 22.9492H20.0322L19.4818 16.7831H27.9999V10.7622H12.9014L13.0457 12.3775L14.5254 28.9705H27.9999V22.9492ZM27.9999 38.5868L27.9732 38.5942L21.2677 36.783L20.8394 31.9814H14.7944L15.6379 41.4353L27.9724 44.8592L27.9999 44.8518V38.5868Z" 
                fill="#EBEBEB"
            />
            <path 
                d="M27.979 22.9492V28.9705H35.3933L34.6946 36.7795L27.979 38.592V44.8566L40.3231 41.4353L40.4136 40.4181L41.8285 24.5662L41.9751 22.9492H40.3528H27.979ZM27.979 10.7622V16.7831H42.5233L42.644 15.4303L42.9188 12.3775L43.0627 10.7622H27.979Z" 
                fill="white"
            />
        </svg>
    );
}

export default Wl02;