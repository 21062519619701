import React from "react";
import DeviceImg from "./imgComponent/DeviceImg";
import Ml01 from "./imgComponent/servicesdetail/mobile_dev/Ml01";
import Ml02 from "./imgComponent/servicesdetail/mobile_dev/Ml02";
import Mr01 from "./imgComponent/servicesdetail/mobile_dev/Mr01";
import Mr02 from "./imgComponent/servicesdetail/mobile_dev/Mr02";

const MobileTech = () => {
    return (
        <section className="services_our_technology_expertise">
            <div className="container">
                <div className="our_technology_expertise">
                    <div className="top_text_area">
                        <h1>Our Technology <span style={{color:"#178582"}}>Expertise</span></h1>
                    </div>
                    <div className="technology_wrapper">
                        <div className="sticky_device_image">
                            <DeviceImg className="device_image" />
                            <img 
                                src="/assets/img/servicesdetail/ipad_device.png" 
                                alt="device" 
                                className="device_image_2"
                                draggable="false"  
                            />
                            <img 
                                src="/assets/img/servicesdetail/mobile_dev/m_inner-img.png" 
                                alt="device_inner_image" 
                                className="inner_image img-fluid"
                                draggable="false"                   
                            />
                        </div>

                        <div className="tech_logos">                          
                            <div className="logo_wrapper">
                                <div className="logo_container l_02">
                                    <Ml01 />
                                    <span className="logo_name">iOS</span>
                                </div>
                                <div className="dashed_line l_02_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container r_02">
                                    <Mr01 />
                                    <span className="logo_name">REACT JS</span>
                                </div>
                                <div className="dashed_line r_02_line"></div>
                            </div>
                            
                            <div className="logo_wrapper">
                                <div className="logo_container l_05">
                                    <Ml02 />
                                    <span className="logo_name">ANDROID</span>
                                </div>
                                <div className="dashed_line l_05_line"></div>
                            </div>

                            <div className="logo_wrapper">
                                <div className="logo_container r_05">
                                    <Mr02 />
                                    <span className="logo_name">FLUTTER</span>
                                </div>
                                <div className="dashed_line r_05_line"></div>
                            </div>
                        </div>         
                    </div>

                    {/* Only For Mobile View Section */}
                    <div className="technology_wrapper_2">
                        <div className="col-12">
                            <ul className="services_listing">
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Ml01 />
                                        </div>
                                        <div className="services_title">iOS</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Mr01 />
                                        </div>
                                        <div className="services_title">REACT JS</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Ml02 />
                                        </div>
                                        <div className="services_title">ANDROID</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="services_badge_view">
                                        <div className="services_circle-ico">
                                            <Mr02 />
                                        </div>
                                        <div className="services_title">FLUTTER</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MobileTech;