import React from 'react'

const Gr02 = () => {
    return (
        <svg 
            width="60" 
            height="68" 
            viewBox="0 0 60 68" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <path 
                d="M13.1244 32.9983L25.9691 6.81152L10.5809 26.673L13.1244 32.9983Z" 
                fill="url(#paint0_linear_2872_5198)"
            />
            <path 
                d="M27.7497 50.2027L9.31095 41.3473L21.2637 53.9979L27.7497 50.2027Z" 
                fill="url(#paint1_linear_2872_5198)"
            />
            <g filter="url(#filter0_d_2872_5198)">
                <path 
                    d="M21.2637 53.9982L50.2596 36.2873L35.3802 53.9982L21.2637 53.9982Z" 
                    fill="url(#paint2_linear_2872_5198)"
                />
            </g>
            <path 
                d="M37.4149 44.2578L43.3921 16.0469L47.3345 38.059L37.4149 44.2578Z" 
                fill="url(#paint3_linear_2872_5198)"
            />
            <path 
                d="M40.5943 29.7097L24.0616 0.866235L42.6291 20.3482L40.5943 29.7097Z" 
                fill="url(#paint4_linear_2872_5198)"
            />
            <path 
                d="M18.2115 45.6492L9.05489 21.9925L9.30925 41.348L18.2115 45.6492Z" 
                fill="url(#paint5_linear_2872_5198)"
            />
            <defs>
                <filter 
                    id="filter0_d_2872_5198" 
                    x="2.26379" 
                    y="22.2871" 
                    width="56.9958" 
                    height="45.7109" 
                    filterUnits="userSpaceOnUse" 
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2872_5198"/>
                    <feOffset dx="-5"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2872_5198"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2872_5198" result="shape"/>
                </filter>

                <linearGradient id="paint0_linear_2872_5198" x1="25.9437" y1="6.8312" x2="11.9348" y2="29.6683" gradientUnits="userSpaceOnUse">
                    <stop offset="0.642702" stopColor="#9CA2AB"/>
                    <stop offset="0.860603" stopColor="#80868F"/>
                </linearGradient>

                <linearGradient id="paint1_linear_2872_5198" x1="9.41101" y1="41.4934" x2="24.2379" y2="52.0673" gradientUnits="userSpaceOnUse">
                    <stop offset="0.64" stopColor="#9CA2AB"/>
                    <stop offset="0.86" stopColor="#80868F"/>
                </linearGradient>

                <linearGradient id="paint2_linear_2872_5198" x1="49.9999" y1="36.3078" x2="29.0937" y2="54.322" gradientUnits="userSpaceOnUse">
                    <stop offset="0.65" stopColor="#9CA2AB"/>
                    <stop offset="0.864004" stopColor="#80868F"/>
                </linearGradient>

                <linearGradient id="paint3_linear_2872_5198" x1="43.3667" y1="16.5726" x2="42.9891" y2="40.6089" gradientUnits="userSpaceOnUse">
                    <stop offset="0.64" stopColor="#9CA2AB"/>
                    <stop offset="0.86" stopColor="#80868F"/>
                </linearGradient>

                <linearGradient id="paint4_linear_2872_5198" x1="24.4176" y1="1.26543" x2="41.7244" y2="24.1245" gradientUnits="userSpaceOnUse">
                    <stop offset="0.64" stopColor="#9CA2AB"/>
                    <stop offset="0.86" stopColor="#80868F"/>
                </linearGradient>

                <linearGradient id="paint5_linear_2872_5198" x1="9.02943" y1="22.0122" x2="13.3098" y2="43.274" gradientUnits="userSpaceOnUse">
                    <stop offset="0.638535" stopColor="#9CA2AB"/>
                    <stop offset="0.859645" stopColor="#80868F"/>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default Gr02;