import React from 'react'

const SearchIcon = () => {
    return (
        <svg 
            width="18" 
            height="19" 
            viewBox="0 0 18 19" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M11.9454 2.87305C9.23952 0.232746 4.86391 0.232746 2.15805 2.87305C-0.547802 5.51336 -0.547802 9.78298 2.15805 12.4233C4.4272 14.6458 7.88659 14.9884 10.5411 13.4677L14.7968 17.6203C15.4818 18.2888 16.5864 18.2888 17.2715 17.6203C17.9565 16.9519 17.9565 15.8741 17.2715 15.2056L13.0157 11.053C14.5656 8.46281 14.2145 5.09559 11.9454 2.87305ZM9.76185 10.2926C8.26335 11.7548 5.84007 11.7548 4.34158 10.2926C2.84308 8.83045 2.84308 6.46588 4.34158 5.00368C5.84007 3.54149 8.26335 3.54149 9.76185 5.00368C11.2603 6.46588 11.2603 8.83045 9.76185 10.2926Z" 
                fill="white"
            />
        </svg>
    );
}

export default SearchIcon;