import React from 'react'

const Wr03 = () => {
    return (
        <svg 
            width="46" 
            height="56" 
            viewBox="0 0 46 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid"
        >
            <path 
                d="M14.5377 55.979C10.9451 54.3917 8.50401 50.9723 8.1837 47.056C8.28755 45.0708 8.85966 43.1384 9.85328 41.4167C10.8469 39.695 12.2338 38.2328 13.9007 37.1496C13.3461 38.5023 13.4807 40.0422 14.2507 41.2795C14.6786 41.8785 15.2717 42.3399 15.9576 42.6074C16.6434 42.8749 17.3923 42.937 18.1128 42.786C20.2162 42.1946 21.4482 40.0107 20.8568 37.9055C20.6463 37.1671 20.2285 36.5045 19.6528 35.9963C18.4825 35.044 17.5777 33.8059 17.0258 32.4016C16.474 30.9972 16.2939 29.4743 16.5029 27.98C16.9125 26.3643 17.8043 24.9116 19.0597 23.8152C18.1146 26.3351 20.7799 28.8217 22.5683 30.045C25.7422 31.9496 28.7996 34.0422 31.7239 36.3115C34.921 38.8315 36.664 42.7758 36.3911 46.8112C35.6718 51.1055 32.6338 54.6509 28.4935 55.9808C36.7761 54.1382 45.3158 47.5584 45.4785 38.226C45.3158 30.759 40.8587 24.0584 34.0461 21.0065H33.7434C33.8948 21.3728 33.9664 21.7671 33.9534 22.1632C33.9761 21.907 33.9761 21.6494 33.9534 21.3933C33.9902 21.6954 33.9902 22.001 33.9534 22.3032C33.4354 24.4259 31.2934 25.7331 29.1655 25.2151C28.3151 25.0051 27.5556 24.5275 27.0095 23.8379C24.2796 20.3382 27.0095 16.3553 27.4663 12.5052C27.6048 10.0879 27.1315 7.67473 26.09 5.48891C25.0485 3.30309 23.4726 1.41523 21.5081 0C23.5047 3.32847 20.8447 7.69974 18.911 10.1865C16.9739 12.6731 14.1739 14.5263 11.892 16.6962C9.4333 18.9834 7.17944 21.4981 5.15653 24.1859C0.783301 29.5286 -0.93334 36.5757 0.489288 43.3303C2.44061 49.8401 7.85132 54.7312 14.5239 56H14.5607L14.5377 55.979Z" 
                fill="#DD4814"
            />
        </svg>
    );
}

export default Wr03;