import React from 'react'

const Gl01 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <path 
                d="M50.4875 13.4312L29.3562 1.26865C29.0062 1.0499 28.525 0.962402 28 0.962402C27.475 0.962402 26.9938 1.09365 26.6438 1.26865L5.64375 13.4749C4.9 13.9124 4.375 15.0062 4.375 15.8374V40.2062C4.375 40.6874 4.4625 41.2562 4.8125 41.7374L51.5375 14.6124C51.275 14.0874 50.8813 13.6937 50.4875 13.4312Z" 
                fill="#9B4F96"
            />
            <path 
                d="M4.68115 41.6938C4.8999 42.0438 5.20615 42.3501 5.5124 42.5251L26.5999 54.7313C26.9499 54.9501 27.4312 55.0376 27.9562 55.0376C28.4812 55.0376 28.9624 54.9063 29.3124 54.7313L50.3124 42.5251C51.0562 42.0876 51.5812 40.9938 51.5812 40.1626V15.7938C51.5812 15.4001 51.5374 14.9626 51.3187 14.5688L4.68115 41.6938Z" 
                fill="#68217A"
            />
            <path 
                d="M37.3188 33.2937C35.4813 36.5312 31.9813 38.7188 28 38.7188C22.0938 38.7188 17.2812 33.9062 17.2812 28C17.2812 22.0938 22.0938 17.2812 28 17.2812C31.9813 17.2812 35.4813 19.4688 37.3188 22.75L43.0063 19.4688C40.0313 14.2625 34.4312 10.7188 28 10.7188C18.4625 10.7188 10.7188 18.4625 10.7188 28C10.7188 37.5375 18.4625 45.2812 28 45.2812C34.3875 45.2812 39.9875 41.7812 42.9625 36.6187L37.3188 33.2937ZM42.4375 28.9625L42.8312 27.0813H40.9937V25.025H43.225L43.75 22.3125H45.8937L45.3687 24.9812H47.0312L47.5562 22.3125H49.6562L49.1313 24.9812H50.1812V27.0375H48.7375L48.3438 28.9187H50.1812V30.975H47.95L47.425 33.6H45.2812L45.8062 30.975H44.1437L43.6187 33.6H41.5188L42.0437 30.975H40.9937V28.9187H42.4375V28.9625ZM44.5375 28.9625H46.2L46.5938 27.0813H44.9312L44.5375 28.9625Z" 
                fill="white"
            />
        </svg>
    );
}

export default Gl01;