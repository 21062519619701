import React from 'react'

const Mr02 = () => {
    return (
        <svg 
            width="45" 
            height="56" 
            viewBox="0 0 45 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid"
        >
            <g clip-path="url(#clip0_2872_4194)">
                <path d="M27.7146 0L0 27.8525L8.57812 36.4733L44.8712 0H27.7146ZM27.5214 25.6851L12.6823 40.5981L21.2927 49.3807L29.855 40.7758L44.8712 25.6851H27.5214Z" fill="#47C5FB"/>
                <path d="M21.293 49.3808L27.8113 55.9316H44.8712L29.8551 40.7759L21.293 49.3808Z" fill="#00569E"/>
                <path d="M12.5859 40.6952L21.1642 32.0742L29.855 40.7759L21.2929 49.3809L12.5859 40.6952Z" fill="#00B5F8"/>
                <path d="M21.293 49.381L28.4227 47.0034L29.1309 41.5039L21.293 49.381Z" fill="url(#paint0_linear_2872_4194)" fillOpacity="0.8"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_2872_4194" x1="52.2684" y1="254.13" x2="616.811" y2="456.556" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1" stopOpacity="0"/>
                </linearGradient>
                <clipPath id="clip0_2872_4194">
                    <rect width="45" height="56" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Mr02