const benefitsContent = [
    {
        icon: '/assets/img/servicesdetail/on-time.svg',
        title: 'Arrives as scheduled',
        description: 'Efficient software delivery in a company means delivering software products on schedule, ensuring they are released and put into use as planned, on time, meeting the expectations of clients, and facilitating smooth project execution and customer contentment.'
    },
    {
        icon: '/assets/img/servicesdetail/engineering.svg',
        title: 'Transparency & Integrity',
        description: 'Openness and honesty within a software firm foster an environment of transparency, sincerity, and moral behavior, guaranteeing straightforward dialogue, authentic claims, and reliable connections with customers, staff, and partners.'
    },
    {
        icon: '/assets/img/servicesdetail/public-service.svg',
        title: 'Cost Efficiency',
        description: 'In order to provide high-quality software solutions while minimizing costs, offering clients cost-effective solutions, and optimizing value for their investment, a cost-efficient software firm concentrates on streamlining resources and procedures.'
    },
    {
        icon: '/assets/img/servicesdetail/setting.svg',
        title: 'Testing & Quality Control',
        description: 'To guarantee the dependability, functionality, and performance of their software products, our organization places a strong emphasis on quality assurance and testing. We provide high-quality solutions that meet or surpass customer requirements and conform to industry standards.'
    },
    {
        icon: '/assets/img/servicesdetail/network-admin.svg',
        title: 'Extensive Technical Expertise',
        description: 'Software companies have vast technical expertise, offering a range of services from development to maintenance, focusing on comprehensive and ongoing support. This expertise ensures the software solutions they provide meet the diverse needs and cultures of their clientele.'
    },
    {
        icon: '/assets/img/servicesdetail/customer-service.svg',
        title: '24 Hour Availability',
        description: 'Cloudpark Infotech is available around-the-clock, guaranteeing its clients receive round-the-clock support and assistance, addressing problems and delivering prompt solutions and services to meet their demands regardless of their location or time zone.'
    }
];  

export default benefitsContent;