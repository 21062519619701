import React from 'react'

const Ml01 = () => {
    return (
        <svg 
            width="56" 
            height="56" 
            viewBox="0 0 56 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <path 
                d="M43.16 53.7532C40.1231 56.7084 36.8074 56.2418 33.6156 54.842C30.2379 53.411 27.1391 53.3488 23.5755 54.842C19.1132 56.7706 16.7581 56.2107 14.0931 53.7532C-1.02914 38.1063 1.20201 14.2782 18.3695 13.4072C22.5529 13.6249 25.4657 15.7091 27.9138 15.8958C31.5704 15.1492 35.0721 13.0028 38.9766 13.2828C43.6558 13.6561 47.1885 15.5225 49.5126 18.8821C39.8443 24.6991 42.1374 37.4842 51 41.0615C49.2337 45.7276 46.9406 50.3625 43.129 53.7843L43.16 53.7532ZM27.6039 13.2206C27.1391 6.28365 32.748 0.559929 39.1935 0C40.0922 8.02565 31.9423 13.9982 27.6039 13.2206Z" 
                fill="black"
            />
        </svg>
    )
}

export default Ml01