import React from 'react'

const ArrowImg = () => {
    return (
        <svg 
            width="294" 
            height="150" 
            viewBox="0 0 294 150" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg" 
            className="img-fluid"
        >
            <g clipPath="url(#clip0_2872_5484)">
                <path d="M20.6772 0.68777L25.0141 7.45532C25.0795 7.55595 25.0512 7.69611 24.9483 7.77011C24.8453 7.84411 24.7075 7.80718 24.6335 7.70424L20.3839 0.88623C20.3334 0.798832 20.3548 0.684513 20.4509 0.636363C20.5296 0.583594 20.6416 0.613605 20.6944 0.692388L20.6772 0.68777Z" fill="#494949"/>
                <path d="M24.8838 7.35592C25.0044 7.38824 25.0738 7.50841 25.0415 7.62904C25.0092 7.74966 24.889 7.81904 24.7684 7.78672C24.6478 7.7544 24.5784 7.63423 24.6107 7.5136C24.643 7.39298 24.7632 7.32359 24.8838 7.35592Z" fill="#494949"/>
                <path d="M24.8838 7.35592C25.0044 7.38824 25.0738 7.50841 25.0415 7.62904C25.0092 7.74966 24.889 7.81904 24.7684 7.78672C24.6478 7.7544 24.5784 7.63423 24.6107 7.5136C24.643 7.39298 24.7632 7.32359 24.8838 7.35592Z" fill="#494949"/>
                <path d="M28.9093 14.2522C29.0568 14.2917 29.1443 14.4434 29.1048 14.5909C29.0653 14.7384 28.9136 14.8259 28.7661 14.7864C28.6186 14.7469 28.5311 14.5953 28.5706 14.4477C28.6101 14.3002 28.7617 14.2127 28.9093 14.2522Z" fill="#494949"/>
                <path d="M28.9093 14.2522C29.0568 14.2917 29.1443 14.4434 29.1048 14.5909C29.0653 14.7384 28.9136 14.8259 28.7661 14.7864C28.6186 14.7469 28.5311 14.5953 28.5706 14.4477C28.6101 14.3002 28.7617 14.2127 28.9093 14.2522Z" fill="#494949"/>
                <path d="M29.0755 14.3896L32.9536 21.422C33.0419 21.575 32.9802 21.7708 32.8359 21.8614C32.6829 21.9497 32.4871 21.888 32.3965 21.7437L28.6034 14.6694C28.5301 14.5297 28.5825 14.3683 28.7136 14.2926C28.8447 14.2169 29.0061 14.2694 29.0818 14.4005L29.0755 14.3896Z" fill="#494949"/>
                <path d="M32.7574 21.2587C32.9287 21.3046 33.0304 21.4806 32.9845 21.6519C32.9386 21.8233 32.7625 21.9249 32.5912 21.879C32.4199 21.8331 32.3182 21.657 32.3641 21.4857C32.41 21.3144 32.5861 21.2127 32.7574 21.2587Z" fill="#494949"/>
                <path d="M32.7574 21.2587C32.9287 21.3046 33.0304 21.4806 32.9845 21.6519C32.9386 21.8233 32.7625 21.9249 32.5912 21.879C32.4199 21.8331 32.3182 21.657 32.3641 21.4857C32.41 21.3144 32.5861 21.2127 32.7574 21.2587Z" fill="#494949"/>
                <path d="M36.5379 28.311C36.733 28.3633 36.8488 28.5638 36.7965 28.7589C36.7443 28.954 36.5437 29.0698 36.3486 29.0175C36.1535 28.9652 36.0377 28.7647 36.09 28.5696C36.1423 28.3745 36.3428 28.2587 36.5379 28.311Z" fill="#494949"/>
                <path d="M36.5379 28.311C36.733 28.3633 36.8488 28.5638 36.7965 28.7589C36.7443 28.954 36.5437 29.0698 36.3486 29.0175C36.1535 28.9652 36.0377 28.7647 36.09 28.5696C36.1423 28.3745 36.3428 28.2587 36.5379 28.311Z" fill="#494949"/>
                <path d="M36.7664 28.4924L40.6031 35.5415C40.7144 35.7467 40.6365 36.0029 40.4312 36.1141C40.226 36.2254 39.9784 36.1498 39.8672 35.9445L36.1092 28.8427C36.0106 28.6593 36.0793 28.4376 36.2604 28.3476C36.4415 28.2576 36.6632 28.3262 36.7555 28.4987L36.7664 28.4924Z" fill="#494949"/>
                <path d="M40.3446 35.3333C40.5683 35.3932 40.701 35.6231 40.6411 35.8467C40.5812 36.0704 40.3513 36.2031 40.1276 36.1432C39.904 36.0832 39.7712 35.8534 39.8312 35.6297C39.8911 35.4061 40.121 35.2733 40.3446 35.3333Z" fill="#494949"/>
                <path d="M40.3446 35.3333C40.5683 35.3932 40.701 35.6231 40.6411 35.8467C40.5812 36.0704 40.3513 36.2031 40.1276 36.1432C39.904 36.0832 39.7712 35.8534 39.8312 35.6297C39.8911 35.4061 40.121 35.2733 40.3446 35.3333Z" fill="#494949"/>
                <path d="M44.3 42.285C44.5498 42.352 44.6949 42.6032 44.6279 42.8531C44.561 43.103 44.3097 43.248 44.0599 43.1811C43.81 43.1141 43.6649 42.8629 43.7319 42.613C43.7988 42.3631 44.0501 42.2181 44.3 42.285Z" fill="#494949"/>
                <path d="M44.3 42.285C44.5498 42.352 44.6949 42.6032 44.6279 42.8531C44.561 43.103 44.3097 43.248 44.0599 43.1811C43.81 43.1141 43.6649 42.8629 43.7319 42.613C43.7988 42.3631 44.0501 42.2181 44.3 42.285Z" fill="#494949"/>
                <path d="M44.5754 42.4976L48.7796 49.3127L48.7922 49.3345C48.9395 49.5772 48.8651 49.8897 48.6224 50.0371C48.3797 50.1844 48.0673 50.1099 47.9199 49.8673L43.7756 42.9667C43.6408 42.7459 43.717 42.4615 43.9378 42.3268C44.1586 42.192 44.4343 42.2659 44.5714 42.4781L44.5886 42.4827L44.5754 42.4976Z" fill="#494949"/>
                <path d="M48.4867 49.0957C48.7624 49.1695 48.9178 49.4513 48.8463 49.7184C48.7747 49.9855 48.4906 50.1495 48.2235 50.0779C47.9564 50.0063 47.7924 49.7223 47.864 49.4552C47.9356 49.1881 48.2196 49.0241 48.4867 49.0957Z" fill="#494949"/>
                <path d="M48.4867 49.0957C48.7624 49.1695 48.9178 49.4513 48.8463 49.7184C48.7747 49.9855 48.4906 50.1495 48.2235 50.0779C47.9564 50.0063 47.7924 49.7223 47.864 49.4552C47.9356 49.1881 48.2196 49.0241 48.4867 49.0957Z" fill="#494949"/>
                <path d="M53.0061 55.6995C53.3076 55.7803 53.4842 56.0862 53.4034 56.3877C53.3226 56.6893 53.0167 56.8659 52.7152 56.7851C52.4136 56.7043 52.237 56.3984 52.3178 56.0968C52.3986 55.7953 52.7045 55.6187 53.0061 55.6995Z" fill="#494949"/>
                <path d="M53.0061 55.6995C53.3076 55.7803 53.4842 56.0862 53.4034 56.3877C53.3226 56.6893 53.0167 56.8659 52.7152 56.7851C52.4136 56.7043 52.237 56.3984 52.3178 56.0968C52.3986 55.7953 52.7045 55.6187 53.0061 55.6995Z" fill="#494949"/>
                <path d="M53.3099 55.9103L58.2369 62.1988L58.2645 62.2339C58.4699 62.5013 58.4246 62.877 58.1658 63.0847C57.8983 63.2901 57.5226 63.2448 57.3149 62.986L52.4116 56.5746C52.2274 56.3313 52.2658 55.9815 52.52 55.791C52.7632 55.6068 53.1108 55.6538 53.2973 55.8885L53.3162 55.9212L53.3099 55.9103Z" fill="#494949"/>
                <path d="M57.9309 61.9964C58.2583 62.0842 58.4475 62.4119 58.3598 62.7393C58.2721 63.0667 57.9443 63.256 57.6169 63.1682C57.2895 63.0805 57.1003 62.7527 57.188 62.4253C57.2757 62.0979 57.6035 61.9087 57.9309 61.9964Z" fill="#494949"/>
                <path d="M57.9309 61.9964C58.2583 62.0842 58.4475 62.4119 58.3598 62.7393C58.2721 63.0667 57.9443 63.256 57.6169 63.1682C57.2895 63.0805 57.1003 62.7527 57.188 62.4253C57.2757 62.0979 57.6035 61.9087 57.9309 61.9964Z" fill="#494949"/>
                <path d="M63.3404 67.8974C63.6937 67.9921 63.8932 68.3503 63.8009 68.6949C63.7085 69.0396 63.348 69.2477 63.0034 69.1553C62.6587 69.063 62.4506 68.7025 62.5429 68.3578C62.6353 68.0132 62.9958 67.8051 63.3404 67.8974Z" fill="#494949"/>
                <path d="M63.3404 67.8974C63.6937 67.9921 63.8932 68.3503 63.8009 68.6949C63.7085 69.0396 63.348 69.2477 63.0034 69.1553C62.6587 69.063 62.4506 68.7025 62.5429 68.3578C62.6353 68.0132 62.9958 67.8051 63.3404 67.8974Z" fill="#494949"/>
                <path d="M63.6362 68.0688C65.5175 69.9396 67.4845 71.7318 69.5487 73.4023C69.8518 73.6497 69.9007 74.0876 69.6532 74.3907C69.4058 74.6938 68.9679 74.7427 68.6648 74.4953C66.5886 72.7661 64.6073 70.9239 62.7203 69.0054C62.4673 68.7437 62.4764 68.3305 62.7295 68.0752C62.9826 67.8198 63.3957 67.8289 63.6511 68.082L63.6362 68.0688Z" fill="#494949"/>
                <path d="M69.2804 73.2659C69.6509 73.3651 69.8803 73.7498 69.7787 74.1289C69.6771 74.508 69.2948 74.7288 68.9157 74.6272C68.5365 74.5256 68.3158 74.1433 68.4174 73.7642C68.519 73.385 68.9013 73.1643 69.2804 73.2659Z" fill="#494949"/>
                <path d="M69.2804 73.2659C69.6509 73.3651 69.8803 73.7498 69.7787 74.1289C69.6771 74.508 69.2948 74.7288 68.9157 74.6272C68.5365 74.5256 68.3158 74.1433 68.4174 73.7642C68.519 73.385 68.9013 73.1643 69.2804 73.2659Z" fill="#494949"/>
                <path d="M75.7752 77.9427C76.1715 78.0489 76.4112 78.4641 76.305 78.8604C76.1988 79.2567 75.7837 79.4964 75.3873 79.3902C74.991 79.284 74.7513 78.8689 74.8575 78.4725C74.9637 78.0762 75.3788 77.8365 75.7752 77.9427Z" fill="#494949"/>
                <path d="M75.7752 77.9427C76.1715 78.0489 76.4112 78.4641 76.305 78.8604C76.1988 79.2567 75.7837 79.4964 75.3873 79.3902C74.991 79.284 74.7513 78.8689 74.8575 78.4725C74.9637 78.0762 75.3788 77.8365 75.7752 77.9427Z" fill="#494949"/>
                <path d="M75.99 78.0373C78.2275 79.4402 80.5583 80.7019 82.9587 81.8069C83.3624 81.9889 83.5406 82.4615 83.3499 82.8629C83.1592 83.2643 82.6953 83.4447 82.2939 83.254C82.2939 83.254 82.2939 83.254 82.2853 83.2517C79.8338 82.0962 77.4652 80.7689 75.1789 79.3067C74.8321 79.0845 74.7275 78.6132 74.9583 78.2687C75.1806 77.922 75.6409 77.8237 75.99 78.0373Z" fill="#494949"/>
                <path d="M82.8255 81.7529C83.2477 81.866 83.5 82.303 83.3868 82.7252C83.2737 83.1474 82.8367 83.3997 82.4145 83.2865C81.9923 83.1734 81.7401 82.7364 81.8532 82.3142C81.9663 81.892 82.4033 81.6397 82.8255 81.7529Z" fill="#494949"/>
                <path d="M82.8255 81.7529C83.2477 81.866 83.5 82.303 83.3868 82.7252C83.2737 83.1474 82.8367 83.3997 82.4145 83.2865C81.9923 83.1734 81.7401 82.7364 81.8532 82.3142C81.9663 81.892 82.4033 81.6397 82.8255 81.7529Z" fill="#494949"/>
                <path d="M90.3314 84.5857C90.7794 84.7057 91.053 85.1669 90.9306 85.6235C90.8082 86.0802 90.3494 86.3451 89.8927 86.2227C89.4361 86.1004 89.1712 85.6415 89.2935 85.1849C89.4159 84.7282 89.8747 84.4633 90.3314 84.5857Z" fill="#494949"/>
                <path d="M90.3314 84.5857C90.7794 84.7057 91.053 85.1669 90.9306 85.6235C90.8082 86.0802 90.3494 86.3451 89.8927 86.2227C89.4361 86.1004 89.1712 85.6415 89.2935 85.1849C89.4159 84.7282 89.8747 84.4633 90.3314 84.5857Z" fill="#494949"/>
                <path d="M90.3572 84.5928C92.8805 85.3428 95.4609 85.9142 98.0645 86.2609C98.5545 86.3275 98.8953 86.779 98.8286 87.269C98.762 87.7591 98.3105 88.0999 97.8205 88.0332C97.8205 88.0332 97.8205 88.0332 97.8119 88.0309C95.1118 87.6306 92.4608 87.0126 89.8606 86.2051C89.4171 86.0678 89.1655 85.594 89.3027 85.1506C89.44 84.7072 89.9115 84.4641 90.3486 84.5905L90.3572 84.5928Z" fill="#494949"/>
                <path d="M98.1703 86.2796C98.6461 86.4071 98.9285 86.8962 98.801 87.3721C98.6735 87.848 98.1844 88.1303 97.7085 88.0028C97.2327 87.8753 96.9503 87.3862 97.0778 86.9104C97.2053 86.4345 97.6944 86.1521 98.1703 86.2796Z" fill="#494949"/>
                <path d="M98.1703 86.2796C98.6461 86.4071 98.9285 86.8962 98.801 87.3721C98.6735 87.848 98.1844 88.1303 97.7085 88.0028C97.2327 87.8753 96.9503 87.3862 97.0778 86.9104C97.2053 86.4345 97.6944 86.1521 98.1703 86.2796Z" fill="#494949"/>
                <path d="M106.186 86.6916C106.681 86.8242 106.975 87.3329 106.842 87.8278C106.71 88.3227 106.201 88.6164 105.706 88.4838C105.211 88.3511 104.918 87.8425 105.05 87.3476C105.183 86.8527 105.692 86.559 106.186 86.6916Z" fill="#494949"/>
                <path d="M106.186 86.6916C106.681 86.8242 106.975 87.3329 106.842 87.8278C106.71 88.3227 106.201 88.6164 105.706 88.4838C105.211 88.3511 104.918 87.8425 105.05 87.3476C105.183 86.8527 105.692 86.559 106.186 86.6916Z" fill="#494949"/>
                <path d="M105.919 86.6569C108.532 86.5538 111.137 86.2081 113.68 85.6061C114.202 85.4872 114.718 85.801 114.837 86.3223C114.956 86.8436 114.642 87.3598 114.121 87.4786C111.445 88.0914 108.713 88.4306 105.985 88.5124C105.472 88.5319 105.046 88.1222 105.026 87.6091C105.016 87.0983 105.406 86.6764 105.919 86.6569Z" fill="#494949"/>
                <path d="M114.15 85.6119C114.667 85.7504 114.97 86.2748 114.831 86.7918C114.692 87.3087 114.168 87.6115 113.651 87.473C113.134 87.3344 112.831 86.8101 112.97 86.2931C113.108 85.7761 113.633 85.4734 114.15 85.6119Z" fill="#494949"/>
                <path d="M114.15 85.6119C114.667 85.7504 114.97 86.2748 114.831 86.7918C114.692 87.3087 114.168 87.6115 113.651 87.473C113.134 87.3344 112.831 86.8101 112.97 86.2931C113.108 85.7761 113.633 85.4734 114.15 85.6119Z" fill="#494949"/>
                <path d="M121.71 82.9003C122.244 83.0435 122.56 83.5897 122.416 84.1239C122.273 84.6581 121.727 84.9735 121.193 84.8303C120.659 84.6872 120.343 84.141 120.486 83.6068C120.63 83.0726 121.176 82.7572 121.71 82.9003Z" fill="#494949"/>
                <path d="M121.71 82.9003C122.244 83.0435 122.56 83.5897 122.416 84.1239C122.273 84.6581 121.727 84.9735 121.193 84.8303C120.659 84.6872 120.343 84.141 120.486 83.6068C120.63 83.0726 121.176 82.7572 121.71 82.9003Z" fill="#494949"/>
                <path d="M121.01 82.9618C123.343 81.8045 125.541 80.3896 127.577 78.7556C128.024 78.3953 128.67 78.4668 129.03 78.9142C129.39 79.3617 129.319 80.0075 128.871 80.3678C126.714 82.0798 124.369 83.5571 121.895 84.7596C121.396 85.0044 120.798 84.7888 120.559 84.3001C120.323 83.8028 120.528 83.2112 121.019 82.9641L121.01 82.9618Z" fill="#494949"/>
                <path d="M128.484 78.574C129.036 78.722 129.364 79.2893 129.216 79.8413C129.068 80.3933 128.5 80.7209 127.948 80.573C127.396 80.4251 127.069 79.8577 127.217 79.3057C127.365 78.7537 127.932 78.4261 128.484 78.574Z" fill="#494949"/>
                <path d="M128.484 78.574C129.036 78.722 129.364 79.2893 129.216 79.8413C129.068 80.3933 128.5 80.7209 127.948 80.573C127.396 80.4251 127.069 79.8577 127.217 79.3057C127.365 78.7537 127.932 78.4261 128.484 78.574Z" fill="#494949"/>
                <path d="M134.19 72.8908C134.759 73.0432 135.099 73.6331 134.947 74.2018C134.795 74.7705 134.205 75.1111 133.636 74.9587C133.067 74.8063 132.727 74.2164 132.879 73.6477C133.031 73.0791 133.621 72.7384 134.19 72.8908Z" fill="#494949"/>
                <path d="M134.19 72.8908C134.759 73.0432 135.099 73.6331 134.947 74.2018C134.795 74.7705 134.205 75.1111 133.636 74.9587C133.067 74.8063 132.727 74.2164 132.879 73.6477C133.031 73.0791 133.621 72.7384 134.19 72.8908Z" fill="#494949"/>
                <path d="M133.078 73.2487C134.701 71.227 136.105 69.0176 137.211 66.6821C137.469 66.1325 138.133 65.8949 138.683 66.1529C139.232 66.411 139.47 67.0749 139.212 67.6245C138.006 70.1271 136.495 72.4559 134.75 74.5924C134.379 75.047 133.705 75.1158 133.242 74.7423C132.787 74.3711 132.718 73.6971 133.081 73.2401L133.078 73.2487Z" fill="#494949"/>
                <path d="M138.496 66.0933C139.09 66.2526 139.435 66.8621 139.278 67.448C139.121 68.0339 138.509 68.3871 137.923 68.2301C137.337 68.0731 136.984 67.4613 137.141 66.8755C137.298 66.2896 137.91 65.9363 138.496 66.0933Z" fill="#494949"/>
                <path d="M138.496 66.0933C139.09 66.2526 139.435 66.8621 139.278 67.448C139.121 68.0339 138.509 68.3871 137.923 68.2301C137.337 68.0731 136.984 67.4613 137.141 66.8755C137.298 66.2896 137.91 65.9363 138.496 66.0933Z" fill="#494949"/>
                <path d="M140.996 58.4521C141.605 58.6153 141.966 59.2414 141.803 59.8505C141.64 60.4596 141.014 60.821 140.405 60.6578C139.796 60.4946 139.434 59.8685 139.597 59.2594C139.76 58.6503 140.387 58.2889 140.996 58.4521Z" fill="#494949"/>
                <path d="M140.996 58.4521C141.605 58.6153 141.966 59.2414 141.803 59.8505C141.64 60.4596 141.014 60.821 140.405 60.6578C139.796 60.4946 139.434 59.8685 139.597 59.2594C139.76 58.6503 140.387 58.2889 140.996 58.4521Z" fill="#494949"/>
                <path d="M139.572 59.3539C140.017 56.832 140.095 54.2303 139.774 51.7064C139.692 51.0472 140.154 50.4599 140.794 50.382C141.444 50.2977 142.032 50.7598 142.11 51.3994C142.439 54.2027 142.322 57.0157 141.82 59.7531C141.708 60.3787 141.11 60.7819 140.484 60.6697C139.867 60.5598 139.456 59.96 139.566 59.343L139.572 59.3539Z" fill="#494949"/>
                <path d="M141.245 50.4202C141.873 50.5885 142.246 51.2342 142.078 51.8623C141.91 52.4904 141.264 52.8632 140.636 52.6949C140.008 52.5266 139.635 51.8809 139.803 51.2528C139.972 50.6247 140.617 50.2519 141.245 50.4202Z" fill="#494949"/>
                <path d="M141.245 50.4202C141.873 50.5885 142.246 51.2342 142.078 51.8623C141.91 52.4904 141.264 52.8632 140.636 52.6949C140.008 52.5266 139.635 51.8809 139.803 51.2528C139.972 50.6247 140.617 50.2519 141.245 50.4202Z" fill="#494949"/>
                <path d="M138.886 42.7512C139.532 42.9243 139.917 43.5907 139.744 44.2369C139.571 44.8831 138.904 45.2679 138.258 45.0947C137.612 44.9216 137.227 44.2552 137.4 43.609C137.573 42.9628 138.24 42.578 138.886 42.7512Z" fill="#494949"/>
                <path d="M138.886 42.7512C139.532 42.9243 139.917 43.5907 139.744 44.2369C139.571 44.8831 138.904 45.2679 138.258 45.0947C137.612 44.9216 137.227 44.2552 137.4 43.609C137.573 42.9628 138.24 42.578 138.886 42.7512Z" fill="#494949"/>
                <path d="M137.492 44.4737C136.338 42.1975 134.822 40.1013 133.044 38.2582C132.568 37.7612 132.585 36.9717 133.082 36.4954C133.579 36.0191 134.369 36.0367 134.845 36.5337L134.854 36.536C136.765 38.5718 138.401 40.8755 139.656 43.3914C139.958 43.9894 139.716 44.7188 139.11 45.018C138.512 45.3195 137.785 45.0693 137.49 44.4823L137.492 44.4737Z" fill="#494949"/>
                <path d="M134.271 36.1954C134.937 36.3739 135.332 37.0586 135.154 37.7248C134.975 38.391 134.291 38.7864 133.624 38.6079C132.958 38.4294 132.563 37.7446 132.741 37.0784C132.92 36.4122 133.605 36.0169 134.271 36.1954Z" fill="#494949"/>
                <path d="M134.271 36.1954C134.937 36.3739 135.332 37.0586 135.154 37.7248C134.975 38.391 134.291 38.7864 133.624 38.6079C132.958 38.4294 132.563 37.7446 132.741 37.0784C132.92 36.4122 133.605 36.0169 134.271 36.1954Z" fill="#494949"/>
                <path d="M128.013 31.1845C128.698 31.3681 129.105 32.0724 128.921 32.7577C128.738 33.4429 128.033 33.8496 127.348 33.6659C126.663 33.4823 126.256 32.778 126.44 32.0928C126.624 31.4075 127.328 31.0009 128.013 31.1845Z" fill="#494949"/>
                <path d="M128.013 31.1845C128.698 31.3681 129.105 32.0724 128.921 32.7577C128.738 33.4429 128.033 33.8496 127.348 33.6659C126.663 33.4823 126.256 32.778 126.44 32.0928C126.624 31.4075 127.328 31.0009 128.013 31.1845Z" fill="#494949"/>
                <path d="M127.032 33.5355C124.825 32.2607 122.429 31.3138 119.956 30.7897C119.24 30.6348 118.78 29.939 118.935 29.2233C119.09 28.5075 119.785 28.0475 120.501 28.2024L120.51 28.2047C123.273 28.8159 125.912 29.8924 128.32 31.3134C128.935 31.6721 129.138 32.4653 128.773 33.0693C128.417 33.6756 127.632 33.881 127.024 33.5332L127.032 33.5355Z" fill="#494949"/>
                <path d="M120.579 28.223C121.285 28.4123 121.701 29.1333 121.512 29.8398C121.323 30.5464 120.602 30.9626 119.895 30.7733C119.189 30.584 118.772 29.863 118.962 29.1565C119.151 28.4499 119.872 28.0337 120.579 28.223Z" fill="#494949"/>
                <path d="M120.579 28.223C121.285 28.4123 121.701 29.1333 121.512 29.8398C121.323 30.5464 120.602 30.9626 119.895 30.7733C119.189 30.584 118.772 29.863 118.962 29.1565C119.151 28.4499 119.872 28.0337 120.579 28.223Z" fill="#494949"/>
                <path d="M112.612 27.9723C113.335 28.1663 113.764 28.9092 113.57 29.6329C113.377 30.3567 112.634 30.7856 111.91 30.5916C111.186 30.3977 110.757 29.6548 110.951 28.9311C111.145 28.2073 111.888 27.7784 112.612 27.9723Z" fill="#494949"/>
                <path d="M112.612 27.9723C113.335 28.1663 113.764 28.9092 113.57 29.6329C113.377 30.3567 112.634 30.7856 111.91 30.5916C111.186 30.3977 110.757 29.6548 110.951 28.9311C111.145 28.2073 111.888 27.7784 112.612 27.9723Z" fill="#494949"/>
                <path d="M112.494 30.6184C110.044 31.0424 107.697 32.0112 105.597 33.3693C104.95 33.7869 104.094 33.6037 103.665 32.9625C103.248 32.315 103.431 31.4591 104.072 31.0307C106.469 29.4936 109.19 28.4034 112.022 27.9339C112.766 27.8101 113.461 28.3195 113.578 29.0528C113.696 29.7861 113.195 30.4831 112.468 30.6115L112.494 30.6184Z" fill="#494949"/>
                <path d="M105.198 30.8614C105.94 31.0603 106.381 31.8233 106.182 32.5656C105.983 33.308 105.22 33.7485 104.477 33.5496C103.735 33.3507 103.295 32.5877 103.493 31.8453C103.692 31.103 104.455 30.6625 105.198 30.8614Z" fill="#494949"/>
                <path d="M105.198 30.8614C105.94 31.0603 106.381 31.8233 106.182 32.5656C105.983 33.308 105.22 33.7485 104.477 33.5496C103.735 33.3507 103.295 32.5877 103.493 31.8453C103.692 31.103 104.455 30.6625 105.198 30.8614Z" fill="#494949"/>
                <path d="M99.3558 36.2592C100.114 36.4624 100.568 37.249 100.365 38.0072C100.162 38.7654 99.3752 39.2195 98.617 39.0164C97.8588 38.8132 97.4046 38.0266 97.6078 37.2684C97.811 36.5102 98.5975 36.0561 99.3558 36.2592Z" fill="#494949"/>
                <path d="M99.3558 36.2592C100.114 36.4624 100.568 37.249 100.365 38.0072C100.162 38.7654 99.3752 39.2195 98.617 39.0164C97.8588 38.8132 97.4046 38.0266 97.6078 37.2684C97.811 36.5102 98.5975 36.0561 99.3558 36.2592Z" fill="#494949"/>
                <path d="M100.118 38.5134C98.5894 40.5324 97.3743 42.7925 96.4797 45.1662C96.192 45.9295 95.3418 46.3112 94.5785 46.0235C93.8152 45.7359 93.4335 44.8856 93.7212 44.1223L93.7258 44.1051C94.7499 41.4891 96.1182 39.0021 97.8435 36.7679C98.3257 36.1399 99.2233 36.0295 99.8514 36.5117C100.471 36.9917 100.583 37.8807 100.112 38.5024L100.118 38.5134Z" fill="#494949"/>
                <path d="M95.4867 43.2198C96.2719 43.4301 96.7378 44.2372 96.5275 45.0223C96.3171 45.8075 95.51 46.2734 94.7249 46.0631C93.9397 45.8527 93.4738 45.0456 93.6842 44.2605C93.8945 43.4753 94.7016 43.0094 95.4867 43.2198Z" fill="#494949"/>
                <path d="M95.4867 43.2198C96.2719 43.4301 96.7378 44.2372 96.5275 45.0223C96.3171 45.8075 95.51 46.2734 94.7249 46.0631C93.9397 45.8527 93.4738 45.0456 93.6842 44.2605C93.8945 43.4753 94.7016 43.0094 95.4867 43.2198Z" fill="#494949"/>
                <path d="M93.6232 50.9669C94.4322 51.1837 94.9122 52.0152 94.6955 52.8241C94.4787 53.6331 93.6472 54.1131 92.8383 53.8964C92.0293 53.6796 91.5493 52.8481 91.766 52.0392C91.9828 51.2302 92.8143 50.7502 93.6232 50.9669Z" fill="#494949"/>
                <path d="M93.6232 50.9669C94.4322 51.1837 94.9122 52.0152 94.6955 52.8241C94.4787 53.6331 93.6472 54.1131 92.8383 53.8964C92.0293 53.6796 91.5493 52.8481 91.766 52.0392C91.9828 51.2302 92.8143 50.7502 93.6232 50.9669Z" fill="#494949"/>
                <path d="M94.7383 52.5953C94.611 53.8633 94.5572 55.1325 94.5941 56.4076C94.6095 57.0396 94.6506 57.6786 94.7137 58.305C94.7681 58.9291 94.8742 59.567 94.9521 60.2066L94.9635 60.302C95.0659 61.1606 94.4599 61.9401 93.6014 62.0425C92.7428 62.1449 91.9633 61.539 91.8609 60.6804C91.7818 59.9759 91.6786 59.2926 91.6192 58.584C91.5574 57.8841 91.5237 57.1825 91.5096 56.4769C91.4901 55.0679 91.5527 53.6626 91.7123 52.274C91.8064 51.4404 92.5587 50.8385 93.3923 50.9326C94.2173 51.0244 94.8192 51.7766 94.736 52.604L94.7383 52.5953Z" fill="#494949"/>
                <path d="M93.8183 58.924C94.6541 59.148 95.1437 60.0087 94.9221 60.8359C94.7005 61.663 93.8374 62.1613 93.0103 61.9397C92.1831 61.718 91.6848 60.855 91.9065 60.0278C92.1281 59.2007 92.9911 58.7024 93.8183 58.924Z" fill="#494949"/>
                <path d="M93.8183 58.924C94.6541 59.148 95.1437 60.0087 94.9221 60.8359C94.7005 61.663 93.8374 62.1613 93.0103 61.9397C92.1831 61.718 91.6848 60.855 91.9065 60.0278C92.1281 59.2007 92.9911 58.7024 93.8183 58.924Z" fill="#494949"/>
                <path d="M95.8633 66.6289C96.7246 66.8597 97.2357 67.745 97.0049 68.6063C96.7741 69.4676 95.8888 69.9787 95.0275 69.7479C94.1662 69.5172 93.6551 68.6319 93.8859 67.7706C94.1167 66.9093 95.002 66.3981 95.8633 66.6289Z" fill="#494949"/>
                <path d="M95.8633 66.6289C96.7246 66.8597 97.2357 67.745 97.0049 68.6063C96.7741 69.4676 95.8888 69.9787 95.0275 69.7479C94.1662 69.5172 93.6551 68.6319 93.8859 67.7706C94.1167 66.9093 95.002 66.3981 95.8633 66.6289Z" fill="#494949"/>
                <path d="M96.9543 67.6229C97.8702 70.0293 99.0189 72.3595 100.334 74.5864C100.797 75.3753 100.535 76.3857 99.7548 76.8508C98.9659 77.3135 97.9555 77.052 97.4904 76.2717C97.4904 76.2717 97.4841 76.2608 97.4778 76.2499C96.1051 73.8597 94.9032 71.349 93.9387 68.7449C93.6346 67.9154 94.0592 66.9857 94.8996 66.6753C95.7291 66.3712 96.6502 66.7934 96.9567 67.6143L96.9543 67.6229Z" fill="#494949"/>
                <path d="M99.3297 73.819C100.215 74.0561 100.74 74.9659 100.503 75.851C100.266 76.736 99.356 77.2613 98.4709 77.0241C97.5858 76.787 97.0605 75.8772 97.2977 74.9921C97.5349 74.107 98.4446 73.5818 99.3297 73.819Z" fill="#494949"/>
                <path d="M99.3297 73.819C100.215 74.0561 100.74 74.9659 100.503 75.851C100.266 76.736 99.356 77.2613 98.4709 77.0241C97.5858 76.787 97.0605 75.8772 97.2977 74.9921C97.5349 74.107 98.4446 73.5818 99.3297 73.819Z" fill="#494949"/>
                <path d="M103.851 80.4143C104.756 80.6567 105.301 81.5876 105.056 82.5009C104.811 83.4142 103.883 83.9503 102.969 83.7056C102.056 83.4609 101.52 82.5323 101.765 81.619C102.009 80.7057 102.938 80.1695 103.851 80.4143Z" fill="#494949"/>
                <path d="M103.851 80.4143C104.756 80.6567 105.301 81.5876 105.056 82.5009C104.811 83.4142 103.883 83.9503 102.969 83.7056C102.056 83.4609 101.52 82.5323 101.765 81.619C102.009 80.7057 102.938 80.1695 103.851 80.4143Z" fill="#494949"/>
                <path d="M104.754 81.0073C106.375 83.0576 108.114 85.0104 109.95 86.8783C110.625 87.5669 110.615 88.6724 109.926 89.3467C109.238 90.0211 108.132 90.0111 107.458 89.3225C107.458 89.3225 107.44 89.3179 107.443 89.3093C105.552 87.3345 103.75 85.2724 102.071 83.0956C101.495 82.3502 101.634 81.2793 102.377 80.7119C103.112 80.1423 104.174 80.2791 104.754 81.0073Z" fill="#494949"/>
                <path d="M109.151 86.4148C110.082 86.6642 110.637 87.6256 110.387 88.5561C110.138 89.4866 109.177 90.0417 108.246 89.7923C107.316 89.543 106.761 88.5816 107.01 87.6511C107.259 86.7206 108.221 86.1655 109.151 86.4148Z" fill="#494949"/>
                <path d="M109.151 86.4148C110.082 86.6642 110.637 87.6256 110.387 88.5561C110.138 89.4866 109.177 90.0417 108.246 89.7923C107.316 89.543 106.761 88.5816 107.01 87.6511C107.259 86.7206 108.221 86.1655 109.151 86.4148Z" fill="#494949"/>
                <path d="M114.998 91.8879C115.946 92.1418 116.516 93.1164 116.26 94.0728C116.004 95.0292 115.031 95.5906 114.075 95.3343C113.119 95.078 112.557 94.1057 112.814 93.1493C113.07 92.193 114.042 91.6316 114.998 91.8879Z" fill="#494949"/>
                <path d="M114.998 91.8879C115.946 92.1418 116.516 93.1164 116.26 94.0728C116.004 95.0292 115.031 95.5906 114.075 95.3343C113.119 95.078 112.557 94.1057 112.814 93.1493C113.07 92.193 114.042 91.6316 114.998 91.8879Z" fill="#494949"/>
                <path d="M115.702 92.2613C117.69 93.9759 119.757 95.601 121.882 97.1492C122.694 97.7363 122.872 98.8644 122.285 99.6768C121.698 100.489 120.57 100.667 119.757 100.08L119.749 100.078C117.56 98.457 115.421 96.7571 113.372 94.9613C112.629 94.3192 112.553 93.1906 113.195 92.4484C113.837 91.7063 114.957 91.6277 115.7 92.2699L115.702 92.2613Z" fill="#494949"/>
                <path d="M121.293 96.8621C122.258 97.1207 122.832 98.1149 122.574 99.0799C122.315 100.045 121.321 100.619 120.356 100.36C119.391 100.102 118.817 99.1075 119.076 98.1425C119.334 97.1775 120.328 96.6036 121.293 96.8621Z" fill="#494949"/>
                <path d="M121.293 96.8621C122.258 97.1207 122.832 98.1149 122.574 99.0799C122.315 100.045 121.321 100.619 120.356 100.36C119.391 100.102 118.817 99.1075 119.076 98.1425C119.334 97.1775 120.328 96.6036 121.293 96.8621Z" fill="#494949"/>
                <path d="M127.961 101.308C128.943 101.571 129.523 102.576 129.26 103.559C128.997 104.541 127.992 105.121 127.01 104.858C126.027 104.595 125.447 103.59 125.71 102.607C125.974 101.625 126.979 101.045 127.961 101.308Z" fill="#494949"/>
                <path d="M127.961 101.308C128.943 101.571 129.523 102.576 129.26 103.559C128.997 104.541 127.992 105.121 127.01 104.858C126.027 104.595 125.447 103.59 125.71 102.607C125.974 101.625 126.979 101.045 127.961 101.308Z" fill="#494949"/>
                <path d="M128.448 101.522L135.325 105.396L135.445 105.465C136.334 105.971 136.65 107.1 136.153 107.991C135.655 108.883 134.518 109.197 133.627 108.7L133.618 108.697L126.531 104.647C125.65 104.143 125.349 103.028 125.852 102.147C126.354 101.275 127.463 100.962 128.346 101.457L128.466 101.527L128.448 101.522Z" fill="#494949"/>
                <path d="M134.948 105.249C135.939 105.514 136.526 106.53 136.26 107.521C135.995 108.512 134.979 109.099 133.988 108.833C132.997 108.568 132.411 107.552 132.676 106.561C132.942 105.57 133.958 104.983 134.948 105.249Z" fill="#494949"/>
                <path d="M134.948 105.249C135.939 105.514 136.526 106.53 136.26 107.521C135.995 108.512 134.979 109.099 133.988 108.833C132.997 108.568 132.411 107.552 132.676 106.561C132.942 105.57 133.958 104.983 134.948 105.249Z" fill="#494949"/>
                <path d="M142.188 108.731C143.168 108.994 143.75 110.002 143.487 110.982C143.225 111.962 142.217 112.544 141.237 112.281C140.257 112.019 139.675 111.011 139.938 110.031C140.2 109.05 141.208 108.469 142.188 108.731Z" fill="#494949"/>
                <path d="M142.188 108.731C143.168 108.994 143.75 110.002 143.487 110.982C143.225 111.962 142.217 112.544 141.237 112.281C140.257 112.019 139.675 111.011 139.938 110.031C140.2 109.05 141.208 108.469 142.188 108.731Z" fill="#494949"/>
                <path d="M142.442 108.818L149.779 111.763L149.887 111.81C150.822 112.181 151.275 113.244 150.896 114.177C150.526 115.112 149.471 115.568 148.538 115.189L140.966 112.19C140.022 111.817 139.56 110.751 139.933 109.808C140.305 108.864 141.371 108.402 142.315 108.775L142.422 108.822L142.442 108.818Z" fill="#494949"/>
                <path d="M149.635 111.715C150.606 111.975 151.182 112.973 150.922 113.944C150.662 114.914 149.664 115.49 148.693 115.23C147.722 114.97 147.146 113.972 147.407 113.002C147.667 112.031 148.664 111.455 149.635 111.715Z" fill="#494949"/>
                <path d="M149.635 111.715C150.606 111.975 151.182 112.973 150.922 113.944C150.662 114.914 149.664 115.49 148.693 115.23C147.722 114.97 147.146 113.972 147.407 113.002C147.667 112.031 148.664 111.455 149.635 111.715Z" fill="#494949"/>
                <path d="M157.261 114.238C158.222 114.496 158.793 115.484 158.535 116.445C158.278 117.406 157.29 117.977 156.328 117.719C155.367 117.462 154.797 116.474 155.054 115.512C155.312 114.551 156.3 113.981 157.261 114.238Z" fill="#494949"/>
                <path d="M157.261 114.238C158.222 114.496 158.793 115.484 158.535 116.445C158.278 117.406 157.29 117.977 156.328 117.719C155.367 117.462 154.797 116.474 155.054 115.512C155.312 114.551 156.3 113.981 157.261 114.238Z" fill="#494949"/>
                <path d="M157.304 114.25L164.964 116.302L165.059 116.327C166.006 116.581 166.568 117.554 166.314 118.501C166.06 119.449 165.088 120.011 164.148 119.759L156.285 117.707C155.329 117.451 154.75 116.474 155 115.507C155.25 114.54 156.233 113.972 157.201 114.222L157.209 114.224L157.304 114.25Z" fill="#494949"/>
                <path d="M165.018 116.307C165.969 116.562 166.534 117.541 166.279 118.492C166.024 119.444 165.046 120.009 164.094 119.754C163.143 119.499 162.578 118.52 162.833 117.569C163.088 116.617 164.066 116.052 165.018 116.307Z" fill="#494949"/>
                <path d="M165.018 116.307C165.969 116.562 166.534 117.541 166.279 118.492C166.024 119.444 165.046 120.009 164.094 119.754C163.143 119.499 162.578 118.52 162.833 117.569C163.088 116.617 164.066 116.052 165.018 116.307Z" fill="#494949"/>
                <path d="M172.857 118.001C173.77 118.246 174.312 119.186 174.068 120.099C173.823 121.012 172.883 121.555 171.97 121.31C171.057 121.065 170.514 120.126 170.759 119.212C171.004 118.299 171.943 117.757 172.857 118.001Z" fill="#494949"/>
                <path d="M172.857 118.001C173.77 118.246 174.312 119.186 174.068 120.099C173.823 121.012 172.883 121.555 171.97 121.31C171.057 121.065 170.514 120.126 170.759 119.212C171.004 118.299 171.943 117.757 172.857 118.001Z" fill="#494949"/>
                <path d="M172.716 117.973L180.558 119.243L180.636 119.264C181.54 119.405 182.144 120.25 182.006 121.146C181.861 122.031 181.033 122.64 180.144 122.512L172.117 121.35C171.184 121.211 170.53 120.343 170.669 119.41C170.808 118.478 171.676 117.824 172.608 117.963L172.626 117.968L172.723 117.984L172.716 117.973Z" fill="#494949"/>
                <path d="M180.778 119.284C181.658 119.519 182.181 120.424 181.945 121.305C181.709 122.185 180.804 122.707 179.924 122.472C179.044 122.236 178.521 121.331 178.757 120.45C178.993 119.57 179.898 119.048 180.778 119.284Z" fill="#494949"/>
                <path d="M180.778 119.284C181.658 119.519 182.181 120.424 181.945 121.305C181.709 122.185 180.804 122.707 179.924 122.472C179.044 122.236 178.521 121.331 178.757 120.45C178.993 119.57 179.898 119.048 180.778 119.284Z" fill="#494949"/>
                <path d="M188.749 120.173C189.594 120.399 190.101 121.265 189.872 122.118C189.644 122.971 188.781 123.469 187.928 123.241C187.075 123.012 186.576 122.149 186.805 121.296C187.033 120.443 187.896 119.945 188.749 120.173Z" fill="#494949"/>
                <path d="M188.749 120.173C189.594 120.399 190.101 121.265 189.872 122.118C189.644 122.971 188.781 123.469 187.928 123.241C187.075 123.012 186.576 122.149 186.805 121.296C187.033 120.443 187.896 119.945 188.749 120.173Z" fill="#494949"/>
                <path d="M188.458 120.122L192.424 120.419L196.395 120.624L196.429 120.633C197.268 120.673 197.91 121.381 197.87 122.219C197.823 123.047 197.127 123.683 196.303 123.656L192.259 123.514L188.213 123.28C187.339 123.23 186.672 122.479 186.722 121.606C186.771 120.732 187.522 120.066 188.396 120.115C188.396 120.115 188.404 120.117 188.413 120.12L188.456 120.131L188.458 120.122Z" fill="#494949"/>
                <path d="M196.743 120.671C197.552 120.888 198.032 121.719 197.815 122.528C197.598 123.337 196.767 123.817 195.958 123.6C195.149 123.384 194.669 122.552 194.885 121.743C195.102 120.934 195.934 120.454 196.743 120.671Z" fill="#494949"/>
                <path d="M196.743 120.671C197.552 120.888 198.032 121.719 197.815 122.528C197.598 123.337 196.767 123.817 195.958 123.6C195.149 123.384 194.669 122.552 194.885 121.743C195.102 120.934 195.934 120.454 196.743 120.671Z" fill="#494949"/>
                <path d="M204.758 120.842C205.534 121.05 205.994 121.847 205.787 122.623C205.579 123.399 204.782 123.859 204.006 123.651C203.23 123.443 202.77 122.646 202.978 121.87C203.186 121.095 203.983 120.635 204.758 120.842Z" fill="#494949"/>
                <path d="M204.758 120.842C205.534 121.05 205.994 121.847 205.787 122.623C205.579 123.399 204.782 123.859 204.006 123.651C203.23 123.443 202.77 122.646 202.978 121.87C203.186 121.095 203.983 120.635 204.758 120.842Z" fill="#494949"/>
                <path d="M204.366 120.793L212.341 120.667L212.384 120.679C213.157 120.655 213.787 121.267 213.796 122.027C213.805 122.786 213.212 123.412 212.459 123.432L204.382 123.697C203.581 123.722 202.91 123.09 202.884 122.289C202.859 121.488 203.491 120.817 204.292 120.791C204.292 120.791 204.301 120.794 204.309 120.796L204.366 120.793Z" fill="#494949"/>
                <path d="M212.764 120.707C213.505 120.906 213.938 121.668 213.74 122.409C213.541 123.15 212.779 123.583 212.046 123.387C211.314 123.19 210.873 122.426 211.069 121.693C211.265 120.961 212.032 120.511 212.764 120.707Z" fill="#494949"/>
                <path d="M212.764 120.707C213.505 120.906 213.938 121.668 213.74 122.409C213.541 123.15 212.779 123.583 212.046 123.387C211.314 123.19 210.873 122.426 211.069 121.693C211.265 120.961 212.032 120.511 212.764 120.707Z" fill="#494949"/>
                <path d="M220.751 120.335C221.44 120.52 221.85 121.229 221.665 121.919C221.48 122.609 220.771 123.018 220.081 122.833C219.391 122.648 218.982 121.939 219.166 121.249C219.351 120.559 220.061 120.15 220.751 120.335Z" fill="#494949"/>
                <path d="M220.751 120.335C221.44 120.52 221.85 121.229 221.665 121.919C221.48 122.609 220.771 123.018 220.081 122.833C219.391 122.648 218.982 121.939 219.166 121.249C219.351 120.559 220.061 120.15 220.751 120.335Z" fill="#494949"/>
                <path d="M220.327 120.295L228.297 119.707L228.323 119.714C228.975 119.658 229.539 120.141 229.586 120.791C229.636 121.432 229.163 121.989 228.528 122.05L220.504 122.873C219.794 122.941 219.164 122.431 219.087 121.718C219.01 121.005 219.529 120.377 220.243 120.301C220.251 120.303 220.26 120.305 220.271 120.299L220.318 120.293L220.327 120.295Z" fill="#494949"/>
                <path d="M228.721 119.746C229.35 119.915 229.722 120.56 229.553 121.188C229.385 121.817 228.74 122.19 228.111 122.021C227.482 121.853 227.11 121.208 227.279 120.579C227.447 119.95 228.092 119.578 228.721 119.746Z" fill="#494949"/>
                <path d="M228.721 119.746C229.35 119.915 229.722 120.56 229.553 121.188C229.385 121.817 228.74 122.19 228.111 122.021C227.482 121.853 227.11 121.208 227.279 120.579C227.447 119.95 228.092 119.578 228.721 119.746Z" fill="#494949"/>
                <path d="M236.667 118.938C237.235 119.091 237.57 119.67 237.417 120.239C237.265 120.807 236.686 121.141 236.117 120.989C235.548 120.837 235.214 120.258 235.367 119.689C235.519 119.12 236.098 118.786 236.667 118.938Z" fill="#494949"/>
                <path d="M236.667 118.938C237.235 119.091 237.57 119.67 237.417 120.239C237.265 120.807 236.686 121.141 236.117 120.989C235.548 120.837 235.214 120.258 235.367 119.689C235.519 119.12 236.098 118.786 236.667 118.938Z" fill="#494949"/>
                <path d="M236.258 118.913L244.211 117.941L244.228 117.945C244.755 117.874 245.227 118.25 245.292 118.766C245.357 119.282 244.992 119.748 244.491 119.826L236.532 121.027C235.955 121.112 235.406 120.716 235.322 120.13C235.237 119.553 235.633 119.004 236.219 118.921C236.219 118.921 236.236 118.925 236.247 118.919L236.273 118.926L236.258 118.913Z" fill="#494949"/>
                <path d="M244.594 117.96C245.102 118.096 245.405 118.62 245.269 119.129C245.132 119.637 244.608 119.94 244.1 119.804C243.591 119.667 243.289 119.143 243.425 118.635C243.561 118.126 244.085 117.824 244.594 117.96Z" fill="#494949"/>
                <path d="M244.594 117.96C245.102 118.096 245.405 118.62 245.269 119.129C245.132 119.637 244.608 119.94 244.1 119.804C243.591 119.667 243.289 119.143 243.425 118.635C243.561 118.126 244.085 117.824 244.594 117.96Z" fill="#494949"/>
                <path d="M252.495 116.835C252.943 116.956 253.208 117.414 253.088 117.862C252.968 118.31 252.509 118.575 252.061 118.455C251.613 118.335 251.348 117.876 251.468 117.428C251.588 116.98 252.047 116.715 252.495 116.835Z" fill="#494949"/>
                <path d="M252.495 116.835C252.943 116.956 253.208 117.414 253.088 117.862C252.968 118.31 252.509 118.575 252.061 118.455C251.613 118.335 251.348 117.876 251.468 117.428C251.588 116.98 252.047 116.715 252.495 116.835Z" fill="#494949"/>
                <path d="M252.138 116.823L260.069 115.624C260.444 115.567 260.793 115.818 260.849 116.193C260.899 116.557 260.66 116.899 260.302 116.96L252.409 118.466C251.951 118.555 251.515 118.254 251.434 117.798C251.344 117.34 251.646 116.904 252.101 116.822C252.101 116.822 252.119 116.827 252.13 116.821L252.147 116.825L252.138 116.823Z" fill="#494949"/>
                <path d="M260.361 115.637C260.723 115.734 260.937 116.106 260.84 116.468C260.743 116.829 260.372 117.044 260.01 116.947C259.648 116.85 259.434 116.479 259.531 116.117C259.628 115.755 259.999 115.54 260.361 115.637Z" fill="#494949"/>
                <path d="M260.361 115.637C260.723 115.734 260.937 116.106 260.84 116.468C260.743 116.829 260.372 117.044 260.01 116.947C259.648 116.85 259.434 116.479 259.531 116.117C259.628 115.755 259.999 115.54 260.361 115.637Z" fill="#494949"/>
                <path d="M268.222 114.354C268.497 114.428 268.67 114.715 268.594 114.999C268.518 115.283 268.234 115.447 267.949 115.371C267.665 115.295 267.501 115.011 267.577 114.727C267.653 114.442 267.937 114.278 268.222 114.354Z" fill="#494949"/>
                <path d="M268.222 114.354C268.497 114.428 268.67 114.715 268.594 114.999C268.518 115.283 268.234 115.447 267.949 115.371C267.665 115.295 267.501 115.011 267.577 114.727C267.653 114.442 267.937 114.278 268.222 114.354Z" fill="#494949"/>
                <path d="M267.992 114.348L271.909 113.689C272.15 113.652 272.383 113.816 272.422 114.049C272.462 114.281 272.3 114.505 272.074 114.556L268.181 115.369C267.896 115.431 267.622 115.247 267.56 114.962C267.498 114.678 267.682 114.404 267.967 114.341C267.967 114.341 267.984 114.346 267.986 114.337L267.992 114.348Z" fill="#494949"/>
                <path d="M283.202 111.969C278.293 114.92 272.376 119.494 268.978 123.801L270.511 114.358L265.653 106.122C270.378 108.893 277.56 111.002 283.202 111.969Z" fill="#494949"/>
            </g>
            <defs>
                <clipPath id="clip0_2872_5484">
                    <rect width="282.676" height="77.9879" fill="white" transform="matrix(0.965926 0.258819 0.258819 -0.965926 0.221924 75.8906)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArrowImg;