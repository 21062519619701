import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import { BaseURL } from "../Helper/Axios";

gsap.registerPlugin(ScrollTrigger);

const PortfolioDetail = () => {
  const { slug } = useParams();
  const [portfolioDetail, setportfolioDetail] = useState([]);
  const [portfolioDetailCategory, setPortfolioDetailCategory] = useState([]);
  const [recent, setRecent] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [date2Data, setDate2Data] = useState([]);
  const imgBoxRef = useRef(null);
  // const [isIntersecting, setIsIntersecting] = useState(false);

  // Last Month Date Get
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const lastMonthMonthName = monthNames[lastMonth.getMonth()];
  const lastMonthYear = lastMonth.getFullYear();
  const month = lastMonth.getMonth() + 1;
  const lastToLastMonth = new Date(today);

  // Last to Last Month Date Get
  lastToLastMonth.setMonth(lastToLastMonth.getMonth() - 2);
  const lastToLastMonthName = monthNames[lastToLastMonth.getMonth()];
  const lastToLastMonthYear = lastToLastMonth.getFullYear();
  const month2 = lastToLastMonth.getMonth() + 1;

  // ProjectDetail Get
  useEffect(() => {
    axios
      .get(`/api/projects/${slug}`)
      .then((res) => setportfolioDetail(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, [slug]);

  // Get ProjectDetail Category Data
  useEffect(() => {
    axios
      .get(
        "/api/project_categories"
      )
      .then((res) => setPortfolioDetailCategory(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Recent Data
  useEffect(() => {
    axios
      .get(`/api/projects`)
      .then((res) => setRecent(res.data.recent))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Date Data
  useEffect(() => {
    axios
      .get(
        `/api/projects?date=${lastMonthYear}-${month}`
      )
      .then((res) => setDateData(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get Date2 Data
  useEffect(() => {
    axios
      .get(
        `/api/projects?date=${lastToLastMonthYear}-${month2}`
      )
      .then((res) => setDate2Data(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Line Animation
  const strokeRef = useRef(null);

  useEffect(() => {
    gsap.from(strokeRef.current, {
      "--dashOffset": -2210,
      delay: 1,
      scrollTrigger: {
        trigger: "#portfolio_detail_section_animation",
        start: "10% 10%",
        end: "-100% -100%",
        scrub: 2.5,
        invalidateOnRefresh: true,
      },
    });
  }, []);

  // Handle main page scroll event [Auto Scroll in mac screen]
  // const handleScroll = () => {
  //   if (imgBoxRef.current && isIntersecting) {
  //     const scrollY = window.scrollY;
  //     imgBoxRef.current.scrollTop = scrollY; //  Scroll in mac screen as main page -
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isIntersecting]);

  // // Auto-scroll animation
  // useEffect(() => {
  //   const scrollElement = imgBoxRef.current;
  //   const scrollSpeed = 0.4; // Adjust the speed of scrolling
  //   let scrollPosition = 0;

  //   const autoScroll = () => {
  //     if (scrollElement && isIntersecting) {
  //       scrollPosition += scrollSpeed;
  //       scrollElement.scrollTop = scrollPosition;

  //       if (
  //         scrollPosition >=
  //         scrollElement.scrollHeight - scrollElement.clientHeight
  //       ) {
  //         scrollPosition = 0; // Reset scroll position to the top
  //       }

  //       requestAnimationFrame(autoScroll);
  //     }
  //   };

  //   if (scrollElement && isIntersecting) {
  //     autoScroll();
  //   }

  //   return () => {
  //     scrollPosition = 0; // Reset scroll position on unmount
  //   };
  // }, [imgBoxRef.current]);
  // , isIntersecting

  // Intersection observer callback
  // const intersectionObserverCallback = (entries) => {
  //   entries.forEach((entry) => {
  //     setIsIntersecting(entry.isIntersecting);
  //   });
  // };

  // // Intersection observer instance
  // useEffect(() => {
  //   const options = {
  //     root: null,
  //     rootMargin: "0px",
  //     threshold: 1.0,
  //   };

  //   const observer = new IntersectionObserver(
  //     intersectionObserverCallback,
  //     options
  //   );

  //   const target = document.querySelector("#extra-div");
  //   if (target) {
  //     observer.observe(target);
  //   }

  //   return () => {
  //     if (target) {
  //       observer.unobserve(target);
  //     }
  //   };
  // }, []);

  // Handle main page scroll event [Auto Scroll in mac screen]
  const handleScroll = () => {
    if (imgBoxRef.current) {
      const scrollY = window.scrollY;
      imgBoxRef.current.scrollTop = scrollY;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Auto-scroll animation
  useEffect(() => {
    const scrollElement = imgBoxRef.current;
    const scrollSpeed = 0.4; // Adjust the speed of scrolling
    let scrollPosition = 0;

    const autoScroll = () => {
      if (scrollElement) {
        scrollPosition += scrollSpeed;
        scrollElement.scrollTop = scrollPosition;

        if (
          scrollPosition >=
          scrollElement.scrollHeight - scrollElement.clientHeight
        ) {
          scrollPosition = 0; // Reset scroll position to the top
        }

        requestAnimationFrame(autoScroll);
      }
    };

    // Delay by 0.5 seconds
    let scrollTimeout;

    if (scrollElement) {
      scrollTimeout = setTimeout(() => {
        autoScroll();
      }, 500);
    }

    return () => {
      clearTimeout(scrollTimeout);
      scrollPosition = 0; // Reset scroll position on unmount
    };
  }, [imgBoxRef.current]);

  const portfolioName = portfolioDetail.length ? portfolioDetail[0].title : "";

  return (
    <>
      {/* ======= Breadcrumb Hero section ======= */}
      <section
        className="breadcrumb_hero_section"
        id="portfolio_detail_section_animation"
      >
        <div className="container-fluid">
          <div className="text_area text-center">
            <h1>{portfolioName}</h1>
            <div className="breadcumb_area">
              <ul className="d-flex align-items-center justify-content-center">
                <li>
                  <Link to={"/"} className="me-2">
                    Home &gt;{" "}
                  </Link>
                </li>
                <li>
                  <Link to={"/portfolio"} className="me-2">
                    Portfolio
                  </Link>
                </li>
                <li>
                  <h5> &gt; {portfolioName}</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* ======= Portfolio Detail section ======= */}
      <section className="portfolio_detail_section">
        <div className="main_img text-center">
          <div className="top_img">
            {portfolioDetail.map((v, i) => {
              return (
                <div key={i}>
                  <img
                    src="/assets/img/extra/desktop.png"
                    alt="portfolio-details"
                    className="img-fluid"
                    draggable="false"
                    key={i}
                  />
                  <div className="imgBox" ref={imgBoxRef}>
                    <img src={v.portrait_image} alt="" className="img-fluid" />
                  </div>
                </div>
              );
            })}
          </div>
          <div id="extra-div"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-12">
              {portfolioDetail.map((v, i) => {
                return (
                  <div key={i}>
                    <div className="left_side_area">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(v.description),
                        }}
                      />
                    </div>
                    <div className="tags_area">
                      {/* Individual link setup */} 
                      {Object.entries(v.link || {}).map(([name, url]) => {
                        return (
                          <Link
                            to={url ? url : "#"}
                            className="tag_btn me-3"
                            key={url}
                            target={"_blank"}
                          >
                            {name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-lg-3 col-12">
              <div className="right_side_area">
                {recent.length > 0 && (
                  <div className="recent_post_area">
                    <h4>Recent Post</h4>
                    {recent.map((v, i) => {
                      return (
                        <Link
                          to={`/portfolio-detail/${v.slug}`}
                          className="first"
                          key={i}
                        >
                          {v.title}
                        </Link>
                      );
                    })}
                  </div>
                )}
                {(dateData.length > 0 || date2Data.length > 0) && (
                  <div className="archive_area">
                    <h4>Archive</h4>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      {dateData.length === 0 ? (
                        ""
                      ) : (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              {lastToLastMonthName} {lastToLastMonthYear}
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {dateData.map((item) => (
                                <Link
                                  key={item.id}
                                  to={`/portfolio-detail/${item.slug}`}
                                >
                                  {item.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {date2Data.length === 0 ? (
                        ""
                      ) : (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              {lastMonthMonthName} {lastMonthYear}
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {date2Data.map((item) => (
                                <Link key={item} to={`/portfolio-detail/${item.slug}`}>
                                  {item.title}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {portfolioDetailCategory.length > 0 && (
                  <div className="category_area">
                    <h4>Category</h4>
                    <ul>
                      {portfolioDetailCategory.map((v, i) => {
                        return (
                          <li key={i}>
                            <div className="d-flex align-items-center">
                              <div className="icon">
                                <img
                                  src={v.logo}
                                  alt={v.name}
                                  className="img-fluid"
                                  draggable="false"
                                />
                              </div>
                              <div className="text">
                                <p>{v.name}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio_detail_line_animation">
          <svg
            width="1500"
            height="303"
            viewBox="0 0 1500 303"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stroke2"
              ref={strokeRef}
              d="M1921.63 302L51.6333 302C24.0191 302 1.63328 279.66 1.63327 252.046C1.63327 194.541 1.63325 95.6702 1.63324 9.90848e-05"
              stroke="#178582"
            />
          </svg>
        </div>
      </section>
    </>
  );
};

export default PortfolioDetail;
