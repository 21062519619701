import React from 'react'

const Maintenance = () => {
    return (
        <>
            <section className="maintenance_section">
                <div className="container">
                    <div className="image text-center">
                        <img src="assets/img/extra/maintenance.png" alt="maintenance" className="img-fluid" draggable="false" />
                    </div>
                    <div className="text text-center">
                        <h5>In Maintenance </h5>
                        <p>Website is under maintenance</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Maintenance