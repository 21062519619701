import React from 'react'

const Ur01 = () => {
    return (
        <svg 
            width="38" 
            height="56" 
            viewBox="0 0 38 56" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="img-fluid" 
        >
            <g clipPath="url(#clip0_2872_4690)">
                <path d="M9.33333 56.0002C14.4853 56.0002 18.6667 51.8188 18.6667 46.6668V37.3335H9.33333C4.18133 37.3335 0 41.5148 0 46.6668C0 51.8188 4.18133 56.0002 9.33333 56.0002Z" fill="#0ACF83"/>
                <path d="M0 27.9998C0 22.8478 4.18133 18.6665 9.33333 18.6665H18.6667V37.3332H9.33333C4.18133 37.3332 0 33.1518 0 27.9998Z" fill="#A259FF"/>
                <path d="M0 9.33333C0 4.18133 4.18133 0 9.33333 0H18.6667V18.6667H9.33333C4.18133 18.6667 0 14.4853 0 9.33333Z" fill="#F24E1E"/>
                <path d="M18.6667 0H28.0001C33.1521 0 37.3334 4.18133 37.3334 9.33333C37.3334 14.4853 33.1521 18.6667 28.0001 18.6667H18.6667V0Z" fill="#FF7262"/>
                <path d="M37.3334 27.9998C37.3334 33.1518 33.1521 37.3332 28.0001 37.3332C22.8481 37.3332 18.6667 33.1518 18.6667 27.9998C18.6667 22.8478 22.8481 18.6665 28.0001 18.6665C33.1521 18.6665 37.3334 22.8478 37.3334 27.9998Z" fill="#1ABCFE"/>
            </g>
            <defs>
                <clipPath id="clip0_2872_4690">
                    <rect width="37.3333" height="56" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Ur01;